.applicantsinglseItemLeft {
  display: flex;
  align-items: center;
  gap: 0.9375rem;
}

.applicantsinglseItem {
  background: var(--bg-primary);
  padding: 0.625rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0;
}

.applicantsinglseItemRight {
  justify-content: space-between;
  align-items: flex-end;
  height: inherit;
  height: 100%;
  gap: 1.5625rem;
}

.applicantListActionBtn button,
.applicantsinglseItemIcons a {
  border: none;
  background-color: var(--bg-secondary);
  padding: 0.375rem 0.75rem;
  color: var(--text-primary);
  border-radius: 5px;
  transition: 0.3s;
  font-size: var(--font-sm);
}

.applicantListActionBtn button:hover,
.applicantsinglseItemIcons a:hover {
  color: var(--text-success);
}
