.food-card {
  min-width: 13.5rem;
  max-width: 18rem;
  background-color: var(--product-bg);
  position: relative;
  color: var(--text-primary) ;
}

@media (max-width: 576px) {
  .food-card {
    min-width: 100%;
  }
}

@media (max-width: 768px) and (min-width: 577px) {
  .food-card {
    min-width: 11rem;
    max-width: 14rem;
  }
}

.food__card__img-box {
  height: 12rem;
  overflow: hidden;
}

.food__card__img-box img {
  max-height: 16rem;
  max-width: 100%;
  transition: all 0.3s;
}

.food__card__heart-icon {
  color: var(--text-success);
  font-size: 1.2rem;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.food-card:hover .food__card__img-box img {
  transform: scale(1.1);
}

.food__card__plus-btn {
  background-color: var(--bg-success) !important;
  color: var(--clr-white);
  border-radius: 0.2rem;
  z-index: 2;
}

@media (min-width: 768px) {
  .food__card__add-modal .modal-dialog {
    max-width: 768px;
    margin: 1.75rem auto;
  }
}
.food__card__add-modal .swiper-pagination-bullet-active {
  background-color: var(--bg-success) !important;
}

.food__card__add-modal {
  color: var(--text-secondary);
}
.food__card__modal__slider-box {
  height: 18rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--eco-product-bg);
  padding: 1rem;
}

.food__card__modal__slider-box img {
  height: 100%;
  aspect-ratio: 1/1;
}

@media (max-width: 768px) {
  .food__card__modal__slider-box {
    height: 14rem;
  }
  /* .food__additional-item {
    height: 3.5rem;
  } */
}

.food__card__add-modal .modal-body {
  padding: 0;
  background-color: var(--bg-secondary);
}

.food__card__add-modal .modal-header {
  background-color: var(--eco-product-bg);
  border: none;
}

.food__additional-item {
  border: 1px solid var(--border-success);
  /* height: 5rem; */
  padding: 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  z-index: -1;
  /* color: #688fb4e3; */
}

.food__additional-item p {
  margin: 0;
}

.food__additional-item.checked {
  background-color: var(--bg-success);
  color: var(--clr-dark);
}

.food__additional-item:has(.checked) {
  background-color: var(--bg-success);
  color: var(--text-primary);
}

.food__card__add-modal textarea {
  box-shadow: none;
  color: var(--clr-dark);
  font-size: var(--font-sm);
}
.food__card__add-modal .form-floating label{
  color: var(--clr-dark);
}


.food__card__add-modal textarea:focus {
  box-shadow: var(--hover-box-shadow);
  border-color: var(--border-success);
}

.food__addModal__plus-minus button {
  border: none;
  background-color: var(--bg-success);
  color: var(--clr-white);
  border-radius: 0.2rem;
  margin: 0 0.5rem;
  font-size: var(--font-sm);
}
