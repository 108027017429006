/* csc === call__screen__control */

.call__screen__control {
  display: flex;
  gap: 1.25rem;
  justify-content: center;
  align-items: center;
}

.csc-look-me {
  fill: var(--text-success);
}

.call__screen__control-btn {
  border: none;
  background: transparent;
  color: var(--text-success);
  font-size: 1.25rem;
}

.call__screen__control-btn.csc-phone {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 100%;
  background: var(--bg-danger);
  color: var(--text-primary);
}

.call__screen__control-btn.csc-video,
.csc-volume {
  font-size: 1.5625rem;
}

.csc__img-container {
  height: 60vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #151b22;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-blend-mode: overlay;
  filter: blur(80%);
  position: relative;
  border-radius: 10px;
}

.csc-img {
  height: 100%;
  width: fit-content;
}
.csc__call-text {
  position: absolute;
  bottom: 1.25rem;
}

.csc__other-video {
  position: absolute;
  top:0.625rem ;
  right: 0.625rem;
  height: 12.5rem;
  width: 8.75rem;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px;
}

.csc__other-video img {
  object-fit: cover;
}

.csc-hangup,
.csc-receive,
.csc-message {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 100%;
  color: var(--text-primary);
}

.csc-hangup {
  background: var(--bg-danger);
  transform: rotate(225deg);
}
.csc-receive {
  background: #129b7f;
}

.csc-message {
  background: var(--clr-white);
  color: var(--text-success);
}
