.rating-modal {
    color: var(--text-secondary);
}

.rating-modal .modal-content{
    background-color: #ffffffe1;
}
[theme="dark"] .rating-modal .modal-content {
    background-color: #1d232be5;
}
.rating-modal .modal-content textarea{
    box-shadow: none;
    color: var(--text-secondary);
    border: 1px solid transparent;
}

.rating-modal .modal-content textarea:focus{
    box-shadow: var(--hover-box-shadow);
    border-color: var(--border-success);
}