.productForm__dimension {
  display: flex;
  gap: 1rem;
}
.productForm__dimension > input {
  border-radius: 0.3rem;
  padding: 0.3rem 0.5rem;
  width: 100%;
  max-width: 5rem;
  border: 0;
}

.productForm__dimension > input::placeholder {
  text-align: center;
  font-weight: 400;
  font-size: 90%;
  color: #5c5c5c;
}

/* 768P */
@media (max-width: 48em) {
  .productForm__dimension {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(3.5rem, 1fr));
  }
}

/* 576P */
@media (max-width: 36em) {
  
}
