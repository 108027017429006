.freelanceTopFreelancer {
}
.freelanceTopFreelancer__title {
  font-size: 1.5rem;
}

.freelanceTopFreelancer__list {
  list-style: none;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

.freelancer__card {
  border: 1px solid #adbacb;
  border-radius: 0.5rem;
  overflow: hidden;
  cursor: pointer;
}

.freelancer__card__img {
  width: 100%;
}

.freelancer__card__details {
  padding: 0.5rem;
}

.freelancer__card__name {
  font-size: 1.12rem;
  font-weight: 500;
  margin: 0;
}
.freelancer__card__title {
  font-size: 0.8rem;
  margin: 0;
  font-weight: 500;
}
.freelancer__card__user {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.freelancer__card__persona {
  font-size: 0.8rem;
  opacity: 0.9;
  margin: 0;
  margin-top: 0.5rem;
}

.freelancer__card__persona > a {
  font-weight: 600;
  opacity: 1;
}
/* 1200px */
@media (max-width: 75em) {
  .freelanceTopFreelancer__list {
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
  }
}

/* 992P */
@media only screen and (max-width: 62em) {
  .freelanceTopFreelancer__list {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
  }
}
/* 576px */
@media (max-width: 36em) {
  .freelanceTopFreelancer__list {
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  }
}

/* 400P */
@media only screen and (max-width: 25em) {
  .freelanceTopFreelancer__title {
    font-size: 1.3rem;
  }
  .freelanceTopFreelancer__list {
    margin-top: 1rem;
  }
}
