.double-file-input-box .file-and-text-input-field {
    height: 2.1rem ;
}

.double-file-input-box .profileVideoSearchLabel{
    height: 2.1rem;
}
.double-file-input-box .file-and-text-input-group{
    transition: 200ms ease-in-out;
}

.double-file-input-box .file-and-text-input-group:focus-within{
    border-color: var(--border-success);
  box-shadow: var(--hover-box-shadow);
}