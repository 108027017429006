.companyProfileUpdateBtn {
  border: none;
  background: var(--bg-primary);
  padding: 0.3125rem 0.75rem;
  color: var(--text-primary);
  border-radius: 4px;
  font-size: var(--font-sm);
  transition: 0.3s ease;
}

.socialMediaPlusBtn {
  display: block;
  width: 100%;
  border: none;
  background-color: var(--bg-primary);
  border-radius: 5px;
  padding: 0.625rem;
  color: var(--text-primary);
  font-size: var(--font-sm);
}

.social-action-btn button {
  transition: 200ms ease-in-out;
}
.social-action-btn button:hover {
  color: var(--text-success);
}
