.orders__links {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 1.5rem;
  margin-bottom: 1rem;
}

.orders__links > a {
  text-align: center;
  border: 1px solid #adbacb;
  padding-block: 0.4rem;
  border-radius: 0.3rem;
  color: var(--text-info);
  transition: 0.3s;
}

.orders__links > a:is(:hover, .active) {
  background-color: var(--text-info);
  color: #fff;
}

/* 400PX */
@media (max-width: 25em) {
  .orders__links {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 0.5rem;
    column-gap: 0.5rem;
  }
}
