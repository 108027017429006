.corporate__navbar {
  background: var(--nav-bg-opacity);
  color: var(--text-primary);
  box-shadow: none;
}
.corporate__navbar__brand-text:hover {
  color: var(--text-primary);
}
.corporate__nav-scroll-color {
  background: var(--nav-bg-opacity);
}

.corporate__mega-menu {
  background: transparent !important;
}

.corporate__navbar__searchBox {
  max-width: 35rem;
  opacity: 0.8;
}
[theme="dark"] .corporate__navbar__searchBox .corporate__navbar__input-text,
[theme="dark"] .corporate__navbar__searchBox .corporate__navbar__dropdown,
[theme="dark"] .corporate__navbar__searchBox .corporate__navbar-input {
  border-color: rgb(75, 75, 75);
  background-color: rgb(75, 75, 75);
}

@media screen and (max-width: 992px) {
  .corporate__navbar__searchBox {
    max-width: 25rem;
  }
}
@media screen and (max-width: 768px) {
  .corporate__navbar__searchBox {
    max-width: 100%;
  }
  .corporate__navbar__dropdown {
    font-size: 0.7rem !important;
  }
}

.corporate__navbar-input {
  background: var(--bg-secondary);
  border-right: none;
  border-top: none;
  border-bottom: none;
  color: var(--text-primary) !important;
  padding: 0px 0.625rem;
  border-color: var(--border-secondary);
}

.corporate__navbar-input:focus {
  background: var(--bg-secondary);
  box-shadow: none;
  outline: none;
  border-color: var(--border-secondary);
}

.corporate__navbar__input-text {
  background: var(--bg-secondary);
  color: var(--text-primary);
}
.corporate__navbar__search-btn {
  background: transparent;
  color: var(--text-primary);
  border: none;
  font-size: var(--font-lg);
  transition: 0.3s;
}
.corporate__navbar__search-btn:hover {
  color: var(--text-success);
}

.corporate__navbar__input-text {
  border-color: var(--bg-secondary);
}
.corporate__navbar__input-text:last-child {
  border-left: none;
  cursor: pointer;
  transition: 0.3s;
}
.corporate__navbar__input-text:last-child:hover {
  color: var(--text-success);
}

.corporate__navbar-icons > a:nth-child(2) {
  color: var(--text-success);
}

.corporate__navbar__dropdown {
  background: var(--bg-secondary);
  color: var(--text-primary);
  font-size: var(--font-sm);
}

.corporate__navbar__dropdown:focus {
  box-shadow: none;
  outline: none;
  border-color: var(--border-primary);
}

.corporate__navbar__icon-down {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  color: var(--text-primary);
}

.corporate__cart-badge {
  color: var(--text-primary);
  background: var(--bg-success) !important;
  border-radius: 100%;
  height: 1rem;
  width: 1rem;
  display: flex;
  font-size: 0.775rem;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -0.4rem;
  right: -0.4rem;
}

.corporate__nav-subItem {
  margin: 0.4rem 0.7rem;
  font-size: var(--font-sm);
  transition: 200ms;
}
/* 
.corporate__nav-subItem:hover {
   color: var(--text-success) ; 
} */

.corporate__navbar__cart-icon:hover {
  color: var(--text-success);
}

.category-btn-icon {
  transition: all 00ms ease-in-out;
  font-weight: 400;
}

.category-btn:hover .category-btn-icon {
  transform: rotateX(180deg);
}
.category-btn {
  z-index: 1220 !important;
}

.category-btn:hover > .mega-menu-box {
  display: block;
}

.corporate__nav__subItem-active {
  color: var(--text-success);
}
/* ############################################## mega menu style start here ############################################################# */

.category-btn:active .category-menu > ul {
  display: none;
}

.category-menu > ul {
  position: absolute;
  border-right: 1px solid var(--border-secondary);
  z-index: 1000;
}

.category-menu ul {
  margin: 0;
  padding: 0;
}
.category-menu li {
  white-space: nowrap;
  cursor: pointer;
  list-style: none;
  padding: 5px 0.625rem;
  transition: 100ms ease-in-out;
  width: 100%;
  /* position: relative; */
}

.category-menu li:hover {
  background: var(--bg-secondary);
  /* text-shadow: 0 10px 10px #222; */
}
.category-menu li:hover > span > a {
  color: var(--text-success);
}

.submenu-item-icon {
  opacity: 0;
  visibility: hidden;
  transition: 100ms ease-in-out;
}

.category-menu li:hover > span .submenu-item-icon {
  opacity: 1;
  visibility: visible;
}

.category-menu ul {
  display: none;
  padding: 0.357rem 0;
  text-align: start;
  position: absolute;
  width: max-content;
  background: var(--sidebar-bg);
  box-sizing: border-box;
  box-shadow: 0 0 4px 0 var(--shadow-clr);
}

.category-menu:hover > ul {
  display: table;
}

.category-menu li:hover > ul {
  display: table;
}

.category-menu ul.submenu {
  /* top: 0.5rem; */
  top: 0rem;
  left: 100%;
  height: 100% !important;
}
.corporate__navbar-icons {
  gap: 0.9rem;
}

.nav-logo-text-box {
  border-left: 0.15rem solid var(--border-secondary);
  color: var(--text-secondary);
  padding-left: 0.625rem;
}

.nav-logo-text-box h5 {
  font-weight: bold;
  font-size: 1.5rem;
}

/* 768px */
@media (max-width: 48em) {
  .corporate__navbar__brand-text .nav-logo-text-box h5 {
    font-size: 1.25rem;
  }
  .corporate__navbar__brand-text .nav-logo-text-box h6 {
    font-size: 0.8rem;
  }
}

/* 400px */
@media (max-width: 25em) {
  .corporate__nav-subItem {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }
  .corporate__navbar-icons {
    gap: 0.5rem;
  }

  .corporate__navbar__brand-text .nav-logo-text-box h5 {
    font-size: 1.1rem;
  }
  .corporate__navbar__brand-text .nav-logo-text-box h6 {
    font-size: 0.6rem;
  }
  .corporate__mega-menu {
    overflow-x: auto;
  }
}
/* 310px */
@media (max-width: 19.37em) {
  .corporate__navbar__brand-text .nav-logo-text-box {
    border: 0;
    padding-left: 0;
  }
  .corporate__navbar h5 svg {
    display: none;
  }
}
