.View {
  /* background-color: white; */
  font-size: 0.9rem;
  color: black;
}
.cardInner {
  background-color: var(--View-bg);
  border-radius: 2px;
  /* height: auto; */
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
}

/* left col ----- */
.LookMeWhiteIcon {
  cursor: pointer;
  background-color: var(--View-bg);
  display: flex;
  justify-content: center;
  padding: 5px 4px;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
  transition: all 0.6s;
  min-height: 3.5rem;
  align-items: center;
}
.view-bg {
  background-color: var(--View-bg);
}
.view-radius {
  border-radius: 5px;
}
.RightCol .inner {
  margin-left: auto;
}

/* topnavbar  modal*/
.fullScreen .modal-content {
  /* width: 80%; */
  margin-left: auto;
  margin-right: auto;
}
.fullScreen .modal-header {
  border-bottom: unset;
}
.fullScreen hr {
  color: rgb(105 132 165);
  height: 3px;
  width: 96%;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
}
.fullScreen .addVideo {
  height: 8rem;
  background-color: var(--bg-primary);
  border-radius: 10px;
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
  overflow: hidden;
}
.fullScreen .thumbnail {
  height: 8rem;
  background-color: var(--bg-primary);
  border-radius: 10px;
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
  overflow: hidden;
}
.fullScreen .fileModalButton {
  background-color: var(--view-yellow) !important;
}

.SingleCard {
  padding: 2% 1%;
  cursor: pointer;
}
.cardsContainer .SingleCard {
  font-size: 13px !important;
}
.padding-unset {
  padding: unset;
}

.SingleCard .inner {
  background-color: var(--View-bg);
  border-radius: 5px;
  height: auto;
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
  padding: 4% 5%;
}

/* .createChannel .form-label {
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
  background-color: var(--View-bg);
  height: fit-content;
  color: black;
} */
/* .createChannel input,
.createChannel .form-select,
.createChannel textarea {  
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
  color: gray !important;
} */

.view .topNavItem {
  background: black !important;
  height: 2.5rem;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.view-profile-offcanvas{
  width: 260px;
  background-color: var(--bg-primary);
}

[theme="dark"] .followbtn{
  color: #fff;
}

@media only screen and (max-width: 500px) {
  /* mobile  */
  .cover {
    padding-left: 0;
    padding-right: 0;
  }
  .SingleCard .inner {
    padding: 3% 3%;
  }
  .LookMeWhiteIcon {
    min-height: 1rem;
  }
  .mx-little {
    margin-right: 0.5%;
    margin-left: 0.5%;
  }
  .View .type-writer-text-box h5{
    font-size: 1rem;
  }
  .View .Typewriter__wrapper{
    font-size: 95%;
  }
}
@media only screen and (max-width: 768px) {
  /* tablet  + mobile */
}
