.personal-info .InputWithLabelInput:disabled {
  background-color: var(--bg-secondary);
  cursor: not-allowed;
}
.ps-profile-edit-btn:hover {
  color: var(--text-success);
}

.personal-input {
  color: var(--text-secondary);
}

.personal-input input {
  background-color: transparent;
  color: var(--text-secondary) !important;
}

.personal-input input:focus {
  background-color: var(--bg-secondary);
}

.personal-input .PhoneInput {
  background-color: var(--bg-secondary) !important;
}

.personal-input .PhoneInputInput {
  background-color: var(--bg-secondary) !important;
}

.personal-input .PhoneInput:focus-within {
  border-color: var(--border-success) !important;
  box-shadow: var(--hover-box-shadow);
}

.personal-input .InputWithLabelInput {
  background-color: var(--bg-secondary);
  color: var(--text-secondary) !important;
}

.personal-input .InputWithLabelInput:disabled {
  background-color: var(--bg-secondary);
  cursor: not-allowed;
}

.personal-input .PhoneInputCountrySelect {
  background-color: var(--bg-secondary) !important;
  color: var(--text-secondary);
}
