.balanceLayout {
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #adbacb;
}

.balanceLayout__title {
  background-color: var(--text-info);
  color: #fff;
  padding: 0.5rem;
  font-size: 1.1rem;
  text-align: center;
  text-transform: uppercase;
}

.balanceLayout__filed {
  padding: 1rem;
}

/* 300p */
@media (max-width: 18.75em) {
  .balanceLayout__filed {
    padding: 0.5rem;
  }
}
