.update-company-profile {
  margin: 0.9375rem;
}

.updateCompanyDetail {
  background-color: var(--bg-secondary);
  padding: 0.625rem;
  border-radius: 5px;
}

.update-company-profile p {
  margin: 0;
  padding: 0.5rem 0.625rem;
  font-size: var(--font-sm);
}

.update-company-profile button {
  border: none;
  background: transparent;
  margin: auto;
  color: var(--text-primary);
  cursor: pointer;
  transition: 0.3s;
}

.profile-info-link:hover {
  color: var(--text-success);
}

.update-company-profile button:hover {
  color: var(--text-success);
}

.bordaredInput {
  border: 1px solid var(--border-primary) !important;
}
.bordaredInput:focus {
  /* box-shadow: var(--hover-box-shadow) !important; */
  border-color: var(--border-success) !important;
}

.pdfPreviewBtn {
  font-size: 1rem;
  transition: 0.3s;
}

.pdfPreviewBtn:hover {
  color: var(--text-success);
}
.lifeTextHoverColor a:hover {
  color: var(--text-success);
}
.allunset {
  box-shadow: unset;
  background-color: unset;
  font-size: initial;
}
.lifeNav {
  box-shadow: unset;
  background-color: unset;
  font-size: initial;
}
.lifeNav .navbar-brand,
.lifeNav .nav-link {
  color: var(--text-primary) !important;
}
.lifeNav .navbar-brand:hover,
.lifeNav .nav-link:hover {
  color: var(--text-success) !important;
}

.job-category-select:focus {
  box-shadow: none !important;
  border-color: var(--border-gra) !important;
}

.job-category-select {
  border: 1px solid transparent !important;
}

.input-textarea-box textarea {
  box-shadow: none !important;
}
.nav__button{
  background-color:var(--bg-secondary);
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px 4px;
  border-radius: 5px;
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
  transition: all 0.6s;
  min-height: 3.5rem;
  align-items: center;
}
.nav__button:hover{
  color: var(--text-primary);
  
}

.nav-button-active:hover{
  color: var(--clr-dark);
}
.nav-button-active{
  background-color: var(--text-success);
  color: var(--clr-dark);
  font-weight: 500;
}

.job__right-ads{
  width: 100%;
  aspect-ratio: 16/8;
  overflow: hidden;
}

.job__right-ads img{
  width: 100%;
  height: 100%;
}

.job-right-column{
  max-height: calc(100vh - 5.5rem) !important;
  overflow-y: scroll;
}

