.partner__registration {
  padding: 3rem;
  background-color: var(--light-dark-to-grey);
  border-radius: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.partner__registration .form-control {
  background-color: transparent;
}

[theme="dark"] .partner__registration .btn-outline-dark {
  border-color: var(--clr-white);
  color: var(--clr-white);
}

@media only screen and (max-width: 36em) {
  .partner__registration {
    padding: 2rem;
    margin-bottom: 3rem;
  }
}
