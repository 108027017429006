.expressHeroArea {
  height: 75vh;
}

@media (min-width: 130em)  {
  .expressHeroArea {
    height: 40rem;
  }
}
/* css don't support height and width at a time without specifying the min-max value on both */
@media (min-height: 67.5rem)  {
  .expressHeroArea {
    height: 40rem;
  }
}


.heroArea__express {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.express__request {
  padding-bottom: 2rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}
.express__request > .package__details {
  background-color: #f1a350;
  border-radius: 0.5rem 0.5rem 0.5rem 0;
}
.express__request__container {
  display: inline-flex;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: var(--nav-bg-opacity);
  border-radius: 1rem;
}
.express__request__location {
  display: flex;
  gap: 1rem;
}
.express__request__location:first-child {
  padding-right: 0.7rem;
  border-right: 1.5px solid var(--border-secondary);
  margin-right: 0.7rem;
}

.express__request__text > p:first-child {
  font-weight: 500;
  font-size: 1.2em;
  line-height: 1.1;
}
.express__request__text > input {
  background-color: transparent;
  border: 0;
}
.express__request__text > input:is(:focus, :active) {
  border: 0;
  outline: 0;
}
.express__request__text > input::placeholder {
  color: inherit;
}
.express__request__text > p {
  margin: 0;
}
.request__express > a {
  margin-left: 2rem;
  background-color: #f1a350;
  padding: 1rem;
  border-radius: 0.5rem;
  font-weight: 500;
  display: inline-block;
}
/* 1200px */
@media (max-width: 75em) {
  .expressHeroArea {
    height: 25rem;
  }
}

/* 768px */
@media (max-width: 48em) {
  .express__request {
    max-width: 35rem;
  }
  .express__request__container {
    flex-direction: column;
    gap: 1.5rem;
  }
  .express__request__container > * {
    width: 100%;
  }
  .express__request__location:first-child {
    margin: 0;
    padding: 0;
    border: 0;
  }
  .request__express > a {
    margin: 0;
    width: 100%;
    text-align: center;
  }
}

/* 576px */
@media (max-width: 36em) {
  .expressHeroArea {
    height: 22rem;
  }
}
