.receivedProject {
}
.projectPreview {
  border-radius: 0.3rem;
  border: 1px solid #adbacb;
  padding: 1rem;
  margin-bottom: 1.5rem;
}
.projectPreview:last-child {
  margin-bottom: 0;
}
.projectPreview__top {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  gap: 1.5rem;
}
.projectPreview__user__img {
  --size: 4.5rem;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  object-fit: cover;
}
.projectPreview__user {
}
.projectPreview__user__name {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.1rem;
}
.projectPreview__user__location {
  margin-bottom: 0;
}
.projectPreview__user__rating {
  font-weight: 500;
  margin-bottom: 0;
}
.projectPreview__user__rating svg {
  margin-right: 0.5rem;
  color: var(--text-success);
}
.projectPreview__user__rating span {
  opacity: 0.85;
  font-size: 0.95rem;
}

.projectPreview__buttons {
  margin-top: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1.1fr 1.1fr 1fr;
}

.projectPreview__button {
  display: inline-block;
  font-weight: 500;
  border: 1px solid #adbacb;
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  text-align: center;
  transition: 0.3s;
  white-space: nowrap;
}
.projectPreview__button > img {
  margin-inline: 1rem;
}
[theme="dark"] .projectPreview__button > img{
  filter: invert(1);
}
.projectPreview__button:hover {
  background-color: #adbacb;
}

/* 768px */
@media (max-width: 48em) {
  .projectPreview__top {
    grid-template-columns: max-content 1fr;
  }
  .projectPreview__top > div:nth-child(3) {
    grid-column: 1 / -1;
  }
  .projectPreview__buttons {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
}
