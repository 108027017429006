.trackingHistory {
  padding: 2.5rem 2rem;
  background-color: var(--nav-bg-opacity);
  border-radius: 0.5rem;
}
.trackingHistory__cards {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 2rem;
}
.trackingHistory__card {
  background-color: var(--white-to-grey);
  padding: 0.5rem;
  font-size: 0.87rem;
  border: 1px solid;
  border-radius: 0.2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
}
.trackingHistory__card.with-location {
  flex-grow: 1;
}
.trackingHistory__card__location {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  gap: 2rem;
  align-items: center;
}
.trackingHistory__card__location img {
  width: 1.5rem;
}

.trackingHistory__down {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 2rem;
}

.tracking__timeline {
  list-style: none;
  position: relative;

  /* Testing */
  display: flex;
  flex-direction: column-reverse;
}
.tracking__timeline::before {
  content: "";
  position: absolute;
  width: 1px;
  background-color: #000;
  top: 35px;
  bottom: 35px;
  left: 4.5%;
  margin-left: -3px;
}
.timeline__event {
  font-size: 90%;
  line-height: 1.3;
  display: flex;
  gap: 1rem;
  align-items: center;
  background-color: var(--white-to-grey);
  border-radius: 0.3rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.8rem;
  padding-right: 3rem;
  margin-left: 5rem;
  position: relative;
}

.timeline__event::before {
  --arrow-size: 2.56rem;
  content: "";
  position: absolute;
  right: 100%;
  top: 0;
  width: 0;
  height: 0;
  border-top: var(--arrow-size) solid transparent;
  border-bottom: var(--arrow-size) solid transparent;
  border-right: var(--arrow-size) solid var(--white-to-grey);;
}

.timeline__event:not(:last-child) {
  /* margin-bottom: 1.5rem; */
  /* testing */
  margin-top: 1.5rem;
}

.timeline__event img {
  height: 3rem;
  width: 3rem;
}

.timeline__event::after {
  box-sizing: content-box;
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  border: 1px solid;
  border-radius: 50%;
  background-color: #fff;
  left: -26.5%;
}

/* 1200px */
@media (max-width: 75em) {
  .trackingHistory__cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  .trackingHistory__card.with-location {
    grid-column: 1 / -1;
  }
}

/* 	992px */
@media (max-width: 62em) {
  .trackingHistory__down {
    grid-template-columns: 1fr;
  }
  .timeline__event::after {
    left: -13.5%;
  }
  .tracking__timeline::before {
    left: 2%;
  }
}

/* 768px */
@media (max-width: 48em) {
  .timeline__event::after {
    left: -21%;
  }
}

/* 576px */
@media (max-width: 36em) {
  .trackingHistory {
    padding: 1rem;
  }
  .trackingHistory__cards {
    grid-template-columns: 1fr;
    gap: 0.7rem;
  }

  .timeline__event {
    /* padding: 0.5rem; */

    /*  */
    padding: 0.5rem 1.5rem;
    border-radius: 0.5rem;
    margin-left: 0;
  }
  .timeline__event:not(:last-child) {
    /*  */
    margin-top: 0.7rem;
  }
  .timeline__event::before,
  .timeline__event::after,
  .tracking__timeline::before {
    /* --arrow-size: 36px; */
    /* Arrow */
    display: none;
  }
}

/* 500px */
@media (max-width: 31.25em) {
  .trackingHistory__card__location {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  .trackingHistory__card__location > *:nth-child(2) {
    display: none;
  }

  .timeline__event {
    /*  */
    padding: 0.5rem;
  }
  .trackingHistory__down {
    margin-top: 1.5rem;
  }
}
