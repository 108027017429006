

.swl-alert-delete-icon.swal2-icon.swal2-warning{
    color: #ef5350;
    border-color: #ef5350c5;
}

.swl-custom-popup.swal2-popup{
    background-color: var(--bg-primary);
}

 .swal-uv-text{
color: var(--text-primary);
}

.swal-uv-text  ~ .swal2-html-container{
    color: var(--text-secondary);
}


.swl-uv-confirm-btn.swal2-styled.swal2-confirm{
    background-color: #ef5350;
}

/* .swl-uv-cancel-btn.swal2-styled.swal2-cancel{
    background: var(--text-success);
    
} */


.swal2-popup.swal2-toast{
    background: var(--bg-primary);
    color: var(--text-primary);
}

.swal2-timer-progress-bar{
    background-color: var(--text-success);
}

.swal2-icon.swal2-error {
    border-color: #ef5350;
    color: #ef5350;
}



