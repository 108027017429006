.bestTimeSeeAllBtn {
  border: none;
  background-color: var(--bg-secondary);
  padding: 0.375rem 0.9375rem;
  color: var(--text-primary);
  border-radius: 4px;
  transition: 0.3s;
  font-size: var(--font-sm);
}

.bestTimeSeeAllBtn:hover {
  color: var(--text-success);
}
