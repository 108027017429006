.cart-modal-show-btn {
  position: fixed;
  bottom: 1%;
  right: 2%;
  background: transparent;
  border: none;
  color: var(--text-primary);
  font-weight: bold;
  font-size: var(--font-sm);
  padding: 0rem;
  z-index: 3;
  height: fit-content;
  width: fit-content;
  border-radius: 100%;
}
.cartModalMove {
  bottom: 16%;
}
.cart-modal-show-btn-text {
  position: absolute;
  inset: 0;
  background: transparent;
  border-radius: 100%;
  font-size: var(--font-lg);
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-modal-show-btn span {
  color: var(--text-success) ;

  font-weight: bold;
}

.cart-modal-show-btn .added-item-count {
  border: 1px solid var(--border-primary);
  padding: 0.1rem 0.2rem;
  border-radius: 0.2rem;
}
.cart-icon {
  fill: var(--text-success);
}

.cart-modal-item .cart-item-img-box {
  width: 4rem !important;
  min-width: 4rem !important;
  max-height: 4rem;
}

.cart-modal-remove-btn {
  border: none;
  padding: 0;
  background: transparent;
  color: var(--text-success) ;
}

/* .cart-modal .sidebar-body {
  overflow: hidden;
} */

.cart-modal {
  z-index: 2222;
}

.cart-modal-footer {
  /* position: absolute;
  bottom: 0; */
  background: var(--bg-secondary);
}

.cart-modal-total-box {
  border: 1px solid var(--border-success);
  margin-bottom: 1ram;
  padding: 0.4rem;
  border-radius: 0.22rem;
}

.cart-modal-total-box p {
  margin: 0;
}

.cart-items-box {
  min-height: calc(100vh - 13.8rem);

}

.cart-modal .sidebar-body::-webkit-scrollbar {
  width: 7px !important;
}
/* Track */
.cart-items-box::-webkit-scrollbar-track {
  border-radius: 0 !important;
}

.cart-modal-footer a:hover {
  color: var(--text-success) ;
}
