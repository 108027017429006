

.personal-info .InputWithLabelInput:disabled{
    background-color: var(--bg-secondary);
    cursor: not-allowed;
}
.ps-profile-edit-btn:hover{
    color: var(--text-success);
}

/* Fi = Form input */
.food-FI-box {
    color: var(--text-secondary);
}

.food-FI-box label{
    background-color: var(--bg-secondary);
    font-weight: 500;
}

.food-FI-box input{
    background-color: transparent;
    color: var(--text-secondary) !important;
}

.food-FI-box input:focus{
    background-color: var(--bg-secondary);
}

.food-FI-box .PhoneInput{
    background-color: var(--bg-secondary) !important;
}

.food-FI-box .PhoneInputInput{
    background-color: var(--bg-secondary) !important;
}

.food-FI-box .PhoneInput:focus-within{
    border-color: var(--border-success) !important;
    box-shadow: var(--hover-box-shadow);
 }

 .food-FI-box .InputWithLabelInput {
    background-color: var(--bg-secondary);
    color: var(--text-secondary) !important;
    font-weight: 500;
 }

 .food-FI-box .InputWithLabelInput:disabled {
    background-color: var(--bg-secondary);
    cursor: not-allowed;
}

.food-FI-box .PhoneInputCountrySelect {
    background-color: var(--bg-secondary) !important;
    color: var(--text-secondary);
}