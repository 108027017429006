.MessageInput {
  margin: 0.5rem auto;
  border-radius: 0.3rem;
  /* box-shadow: 1px 1px 2px 2px inset var(--shadow-clr); */
  padding: 0.5rem;
  display: flex;
  /* align-items: center; */
  overflow: inherit;
}
.MessageInput input {
  display: block;
  border: none;
  width: 100%;
  background: transparent;
}

.MessageInput input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.message__side__bar {
  height: calc(100vh - 6rem);
  /* overflow-y: scroll; */
  overflow-x: hidden !important;
}

.message__side__bar::-webkit-scrollbar {
  width: 0.625rem !important;
}
.message__side__bar::-webkit-scrollbar-track {
  background: gray !important;
  border-radius: 2px !important;
}
.message__side__bar::-webkit-scrollbar-thumb {
  background: var(--shadow-clr) !important;
  border-radius: 2px !important;
}
.message__side__bar::-webkit-scrollbar-thumb:hover {
  background: var(--border-secondary) !important;
}

.chat__person__recent-message {
  max-width: 20ch !important;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

.message_sellAll-btn:hover {
  color: var(--text-success);
  text-decoration: underline;
}
