.product__details-img {
  background: var(--clr-white);
  width: 100%;
  aspect-ratio: auto;
}

.product__details-img img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: var(--clr-white);
}

.product__image-indicator {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.image__indicator-img-box {
  background: var(--clr-white);
  display: grid;
  place-items: center;
  outline: 2px solid var(--clr-white);
  cursor: pointer;
  box-sizing: border-box;
  max-height: 5rem;
  width: 100%;
}

.image__indicator-img-box img {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  object-fit: cover;
}

.image-viewed {
  outline-color: var(--text-success);
}

.product__details-name {
  max-width: 24rem;
}

.review-star {
  color: var(--text-success);
}

.product__details-text p {
  font-size: 1rem;
}
.color-dot-box {
  display: inline-flex;
  gap: 1.2rem;
}

.color-dot {
  width: 0.85rem;
  height: 0.85rem;
  border-radius: 100%;
  display: inline-block;
  outline: 2px solid transparent;
  outline-offset: 0.22rem;
  cursor: pointer;
}

.selected-color-dot {
  outline-color: var(--text-primary);
}

.quantity-box div {
  display: flex;
  align-items: center;
}

.quantity-box span {
  width: 2rem;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.quantity-box button {
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.quantity-box button:disabled {
  cursor: not-allowed;
}

.buy-now-btn-box {
  display: flex;
  gap: 2rem;
  margin: 0.675rem 0;
}

.buy-now-btn {
  width: 10rem;
  height: 3rem;
  /* line-height: 3rem; */
  border-radius: 0.25rem;
  border:0;
  background: var(--bg-success);
  transition: .3s;
}
.buy-now-btn:hover{
  filter: brightness(110%);
}

.more-info-btn {
  width: 15rem;
  border: 1px solid var(--border-primary);
  height: 3rem;
  line-height: 3rem;
  border-radius: 0.25rem;
  transition: 200ms ease-in-out;
}

.more-info-btn:hover {
  color: var(--text-success);
  border-color: var(--border-success);
}

.product-description p {
  font-size: var(--font-sm);
  margin-bottom: 0.44rem;
}

/* .product-zoom-img>div>img{
   
    max-width: 100% !important;
} */

.product-magnify-image {
  aspect-ratio: 1/1;
  width: 100%;
}
