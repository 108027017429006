.food-cart-modal {
  background-color: var(--sidebar-bg);
  color: var(--text-secondary);
}

.modal-close-btn {
  fill: var(--text-secondary);
}

.food__cart__modal-footer {
  margin-top: 1.5rem;
}

.food__cart__modal-footer div p {
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.food__cart__modal-footer div.last p {
  color: var(--text-primary);
  font-size: 1.1rem;
  font-weight: 500;
}

/* .food-cart-items-box{

} */

.food__modal_card__plus-minus {
  position: relative;
  right: 1.5rem;
  top: 0.5rem;
}

.food__modal_card__plus-minus button {
  margin: 0 0.3rem;
  font-weight: bold;
  color: var(--text-success);
}

.food__order-method {
  font-size: var(--font-sm);
}

.food__order-method .form-check-input:checked {
  background-color: var(--bg-success);
  border-color: var(--border-success);
}

.food__order-method .form-check-input:focus {
  box-shadow: var(--hover-box-shadow);
}
