
.brandcard{
    height: 5rem;
    width: 89%;
    background: var(--clr-white);
    padding: 1%;
    box-shadow: var(--box-shadow);
    border-radius: 6px;
    overflow: hidden; 
    cursor: pointer;

}
.brandcard .swiper-slide {
    height: fit-content !important;
}
.brandcard img{
    border-radius: 16px;

}
.brand_logo{
    width: 4rem;
    height: 4rem;
    border-radius: 14px;
    /* box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 20%); */
    border: 1px solid rgb(0 0 0 / 20%);
}
.Brand .category__slider__item-container {
    max-width: fit-content;
}
.Brand .single-category{
    background-color: var(--text-primary);
    padding-bottom: unset;
    width: 4rem;
    height: 3rem;
    border-radius: 4px;
    /* box-shadow: var(--Viewbox-shadow); */
    box-shadow: 0 .5rem .5rem rgba(0,0,0,.2);
    padding: 3%;
}
.BrandName{
    width: 100%;
    margin-top: auto;
    background: var(--bg-success);
    text-align: center;
}

@media (max-width:576px){
    .brandcard{
        /* min-width: 10rem;
        max-width: 14rem; */
    }
}

@media (max-width:768px) and (min-width:577px) {
    .brandcard{
        /* min-width: 11rem;
        max-width: 14rem; */
    }
    
}



.brandcard:hover .product__card__img-box img{
    transform: scale(1.2);
}

.brandcard:hover .product__card__icons-box{
    bottom: 0;
}