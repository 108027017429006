.rideHeroArea {
  height: 60vh;
}
.heroArea__bg {
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
}
.hero__links {
  list-style: none;
  display: flex;
  gap: 1.25rem;
  justify-content: center;
  padding: 1rem 0;
  overflow-x: auto;
  scrollbar-width: none;

  position: absolute;
  width: 100%;
  bottom: 2%;
}
.hero__links::-webkit-scrollbar {
  display: none;
}

.hero__link {
  text-align: center;
  display: inline-block;
  list-style: none;
  color: inherit;
  font-weight: 500;
  text-decoration: none;
  background-color: var(--white-to-grey);
  padding: 0.5rem;
  padding-top: 1.7rem;
  padding-bottom: 1.7rem;
  width: 10rem;
  border-bottom-right-radius: 3rem;
  transition: 0.3s;
  white-space: nowrap;
  opacity: 0.7;
}
[theme="dark"] .hero__link {
  opacity: 0.85;
}
.hero__link:hover {
  opacity: 1;
  color: var(--clr-white);
  background-color: var(--view-yellow);
}

.heroArea__video {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}

/* 1200px */
@media (max-width: 75em) {
  .rideHeroArea {
    height: 22rem;
  }
  .hero__links {
    bottom: 0;
  }
}
/* 992px */
@media (max-width: 62em) {
  .hero__links {
    justify-content: start;
    bottom: 0;
    margin-bottom: 0;
  }
  .hero__link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    width: 9rem;
  }
  .rideHeroArea {
    height: 18rem;
  }
}
/* 576px */
@media (max-width: 36em) {
  .rideHeroArea {
    height: 14rem;
  }
  .hero__link {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    width: 7.5rem;
    border-bottom-right-radius: 0rem;
    font-size: 0.8rem;
  }
  .hero__links {
    border-radius: 0;
    padding: 1rem;
  }
  .heroArea__video {
    width: 170%;
  }
}


@media (min-width: 130em)  {
  .rideHeroArea {
    height: 27rem;
  }
}
/* css don't support height and width at a time without specifying the min-max value on both */
@media (min-height: 67.5rem)  {
  .rideHeroArea {
    height: 27rem;
  }
}
