.singleVideoCard {
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
  border-radius: 5px;
  background-color: var(--View-bg);
  padding: 3% 1%;
  min-height: 9rem;
}
.followerContainer {
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (max-width: 500px) {
  /* mobile  */
  .singleVideoCard {
    min-height: 7rem;
  }
  .followerContainer {
    padding-left: unset;
    padding-right: unset;
  }
  .followerContainer .col-sm-6 {
    padding-left: 0;
    padding-right: 0;
  }
}
