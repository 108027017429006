.popup__message-main {
  background-color: var(--bg-secondary);
  width: 22.5rem;
  padding: 0.4rem;
  border-radius: 0.8rem;
  position: fixed;
  bottom: 0;
  right: 4rem;
  z-index: 1111;
}
.popup__chatList-main {
  background-color: var(--bg-secondary);
  width: 15rem;
  padding: 0.4rem;
  border-radius: 0.8rem;
  position: fixed;
  bottom: 0;
  right: 4rem;
  z-index: 1111;
}

.popup__chat__content-body {
  height: 20rem;
  overflow-y: scroll;
}
.popup__chatList-main .popup__chat__content-body {
  height: 26rem;
  overflow-y: scroll;
}
.popup__chatList-main .popup__chat__content-body::-webkit-scrollbar {
  width: 0 !important;
}
.popup__chat__content-body::-webkit-scrollbar {
  width: 0.675rem !important;
}

 