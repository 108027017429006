.category__slider__item-container{
    max-width: 20.925rem;

}
.category__slider__item-container a h6:hover{
color: var(--text-success);
}
.categories__slider-item{
    width: 100%;
    height: 20.925rem;
    box-sizing: border-box;
    background: var(--product-bg);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    place-content: center;
    place-items: center;
    gap: 1rem;
    padding: 1rem;
}

.SuggestedProduct .swiper-slide{
    /* width: fit-content !important; */
}
.single-category{   
    width: 9rem;
    height: 9rem;
    background: var(--clr-white);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    color: var(--clr-dark);
    font-weight: 600;
    transition: 0.3s;
    
}

.single-category-img{
    max-width: 7.5rem;
    max-height: 7rem;
    margin: 0 auto ;
   
}

.single-category:hover{
    color: var(--text-success) ; 
}

.swiper-slide-shadow-left, .swiper-slide-shadow-right{
        background-image: none !important;
}

@media (max-width:768px) {
    .category__slider__item-container{
        max-width: 16.5rem;
    }
    .categories__slider-item{
        height: 16.5rem;
        padding: 0.5rem;
        gap: 0.5rem;
    }
    .single-category{
       width: 7.5rem;
       height: 7.5rem;
    }
    .single-category-img{
        max-width: 6rem;
        max-height: 5.5rem;
        margin: 0 auto ;
       
    }
}
