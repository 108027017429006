.imgFolder{
    border-radius: 8px;
}
.showVideos .videoBox {
    padding-bottom: 2%;
    text-align: center;
    }
.showVideos .videoBox img{
    height: 8rem;
}
