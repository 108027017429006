.infoBody {
  min-width: 50%;
}
.shopName {
  padding-left: 50px;
}
.cartPropductTitle {
  font-size: 0.7rem;
}
.myCartPage .cartPropductTitle {
  font-size: 0.9rem;
}
.myCartPage .favorite-card {
  padding-top: 2%;
  padding-bottom: 2%;
}
.cartModal .favorite-card {
  padding: 0;
}
