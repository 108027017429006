.file-and-text-input-group{
    border: 1px solid gray;
    border-radius: 4px;
}


.file-and-text-input-field{
    background-color: var(--bg-secondary);
    border: none;
    font-size: 0.85rem;
    color: var(--text-primary);
    height: 2.5rem;
}

.file-and-text-input-field:focus{
    background-color: var(--bg-secondary);
    border: none;
    color: var(--text-primary);
    box-shadow:none;
    outline: none;
}


#file-label{
    padding: .45rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    cursor: pointer;
}

#file-label .file-text-btn{
    font-size: .75rem;
    color: var(--text-success);
    cursor: pointer;
    padding: 0rem .7rem;
}

.file-and-text-input-field:disabled{

    cursor: not-allowed;
    background-color: var(--bg-secondary);
}


 /* #file-label:disabled svg{ 
    cursor: not-allowed;
} */