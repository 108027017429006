.food-step-wizard .progress-count:before {
  border-left-color: var(--clr-white);
  border-bottom-color: var(--clr-white);
}
.food-step-wizard .current-item .progress-count:after {
  background-color: var(--bg-secondary);
  border: 0.125rem solid var(--border-success);
}
.food-step-wizard .current-item .progress-count {
  color: var(--text-secondary);
}

.food-step-wizard .progress-label img {
  height: 3rem;
  width: 3rem;
}

@media (max-width: 1200px) {
  .responsive-step.step-wizard {
    height: 40rem;
  }
  .responsive-step .step-wizard-list {
    flex-direction: column;
    width: 20rem;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
  }

  .responsive-step .step-wizard-item {
    flex-direction: row;
    justify-content: center;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
  }
  .responsive-step .step-wizard-item + .step-wizard-item:after {
    width: 0.125rem;
    height: 100%;
    transform: translateY(-50%);
    top: 0rem;
    left: 2.42rem;
  }

  .responsive-step .progress-label {
    margin-top: 0;
    width: 10rem;
  }
}
