.jobCategories,
.jobRecommended {
  background-color: var(--bg-secondary);
  border-radius: 2px;
}

.categoriesLink {
  font-size: var(--font-sm);
  padding: 0.5rem 0;
  color: var(--text-primary);
  display: inline-block;
  cursor: pointer;
  transition: 0.3s ease;
}

.categoriesLink:hover {
  color: var(--text-success);
}

.categoriesLinkActive {
  color: var(--text-success);
}

#categoriesDropdownBtn {
  background: var(--bg-primary);
  border: 1px solid var(--border-primary);
  width: 100%;
  text-align: start;
}

.categories-dropdown .jobItemDropdownBody {
  width: 100%;
  text-align: start;
}
.categories-dropdown .jobDropdownItem {
  width: 100%;
  text-align: start;
}

#categoriesDropdownBtn:focus {
  outline: none;
  box-shadow: none;
}

.categoriesLink > span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job__categories-container {
  max-height: calc(100vh - 5.5rem) !important;
  overflow-y: scroll;
}

.job-right-column::-webkit-scrollbar-thumb, .job__categories-container::-webkit-scrollbar-thumb{
  background-color: transparent !important;
  border-radius: 2px !important;
  transition: 200ms ;
}


.job__categories-container:hover::-webkit-scrollbar-thumb, .job-right-column:hover::-webkit-scrollbar-thumb{
  background-color: #888 !important;
 
}

.job-right-column::-webkit-scrollbar, .job__categories-container::-webkit-scrollbar{
  width: 8px !important;
}

.job__category-sidebar .accordion-header button {
  background-color: transparent;
  border: none;
  font-size: var(--font-sm);
  padding-left: 0;
  padding-top: 0.65rem;
  padding-bottom: 0.65rem;

}
.job__category-sidebar .accordion-header button:focus {
  box-shadow: none

}
.job__category-sidebar .accordion-header button:hover {
  color: var(--text-success)

}
.job__category-sidebar .accordion-header button:not(.collapsed){
  color: var(--text-success);
}

.job__category-sidebar .accordion-body{
  padding-top: 0;
  padding-bottom: 0;
}
.job__category-sidebar .accordion-header button::after{
  background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f9a31a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.job__category-sidebar .accordion-item{
  border: none;
}

.job__category-sidebar li:hover a{
  color:var(--text-success);
  /* transform: translateX(-0.5rem); */

}

.job__category-sidebar a:hover{
  color: var(--text-success);
}


html[theme=dark] .accordion-button{
color: white !important;
}
