.deliveryForm {
  background-color: var(--nav-bg-opacity);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.deliveryForm input,
.deliveryForm textarea {
  border: 1px solid var(--border-secondary);
  background-color: var(--nav-bg);
  box-shadow: unset;
}
.deliveryForm input:is(:focus, :active),
.deliveryForm textarea:is(:focus, :active) {
  background-color: var(--nav-bg);
}
[theme="dark"]
  :is(.deliveryForm input, .deliveryForm textarea,
  .deliveryForm input:is(:focus, :active),
  .deliveryForm textarea:is(:focus, :active)) {
  color: #fff;
}
.deliveryForm .input-group > span + input {
  border-left: 0;
}
.deliveryForm .input-group > span {
  background-color: var(--nav-bg);
  border-top: 1px solid;
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--border-secondary);
}
.deliveryForm .input-group > span > img {
  width: 1.2rem;
}
