.workPackage {
  border: 1px solid #adbacb;
  border-radius: 0.3rem;
  padding: 1rem;
}

.workPackage__top {
  display: flex;
  gap: 1.5rem;
}
.workPackage__top img {
  border-radius: 0.3rem;
}
.workPackage__bottom {
  display: flex;
  gap: 1.5rem;
}

.workPackage__list {
  flex-grow: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
}

/* 1200px */
@media (max-width: 75em) {
  .workPackage__bottom {
    flex-direction: column;
  }
}

/* 768px */
@media (max-width: 48em) {
  .workPackage__top {
    flex-direction: column;
  }
}

/* 576px */
@media (max-width: 36em) {
  .workPackage__top {
    gap: 0.7rem;
  }
}

/* 450px */
@media (max-width: 28.5em) {
  .workPackage__list {
  }
}
