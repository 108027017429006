.uviomRoundIicons .carousel-inner {
  border-radius: 1.375rem;
}
.uviomRoundIicons {
  min-height: calc(100vh - 24rem);
}

.carousel-item img {
  height: 20rem;
}
.navbar-light .navbar-toggler {
  color: none !important;
  box-shadow: none !important;
  border-color: none !important;
}
.navbar-toggler:focus {
  box-shadow: none !important;
  color: none !important;
}

/* -- ----------------- */
.dashbordDiv {
  width: 23.125rem;
  height: 23.125rem;
  position: relative !important;

  margin: 1.875rem auto;
}
.dashbordLogoRounded {
  background-color: var(--bg-primary);
  border-radius: 50%;
  width: 4.6875rem;
  height: 4.6875rem;
  padding: 0.5rem;
  cursor: pointer;
  border: 3px solid var(--Logo-title);
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoRounded {
  background-color: var(--bg-primary);
  border-radius: 50%;
  padding: 1.2%;
  border: 3px solid var(--border-secondary);
}
.dashbordMainLogoRounded {
  background-color: var(--bg-primary);
  border-radius: 50%;
  width: 6.25rem;
  height: 6.25rem;
  padding: 0.375rem;
  border: 0.375rem solid var(--border-secondary);
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.corporate {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.car {
  position: absolute;
  /* top: 44%; */
  right: 0%;
  top: 50%;
  transform: translateY(-50%);
}
.life {
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.care {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.foodDelevary {
  position: absolute;
  top: 10%;
  right: 10%;
}
.express {
  position: absolute;
  bottom: 10%;
  right: 10%;
}
.citify {
  position: absolute;
  left: 10%;
  bottom: 10%;
}
.creative {
  position: absolute;
  left: 10%;
  top: 10%;
}
@media only screen and (min-width: 500px) and (max-width: 768px) {
  /* tablet  */
  .dashbordLogoRounded {
    width: 3.75rem;
    height: 3.75rem;
  }
  .dashbordDiv {
    width: 18.75rem;
    height: 18.75rem;
  }
}
/* extra small  */
@media only screen and (max-width: 500px) {
  .dashbordDiv {
    width: 14.375rem;
    height: 14.375rem;
  }
  .dashbordLogoRounded {
    width: 3.125rem;
    height: 3.125rem;
  }
  .dashbordLogoRounded {
    padding: 4px;
  }
  .carousel-item img {
    height: 8rem;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1440px) {
  /* laptop  */
  .carousel-item img {
    height: 12rem;
  }
}
@media only screen and (max-width: 768px) {
  /* tablet  + mobile */
  .carousel-item img {
    height: 9rem;
  }
}


.dashboard {
  padding-block: 4.375rem;
  overflow: hidden;
}
.dashboard__list {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  justify-content: center;
  gap: 0.625rem;
  padding-top: 3.125rem;
  margin-left: 3.125rem;
}
.dashboard__list-wrapper {
  height: 6.5625rem;
  width: 6.5625rem;
  position: relative;
  margin: auto auto;
  cursor: pointer;
  padding-left: 0.8rem;
}

.dashboard__list-wrapper:nth-child(2) {
  margin-top: -2.1875rem;
}
.dashboard__list-wrapper:nth-child(4) {
  margin-left: -2.1875rem;
}
.dashboard__list-wrapper:nth-child(5) {
  cursor: default;
}
.dashboard__list-wrapper:nth-child(5) img {
  height: 100%;
  width: 100%;
}
.dashboard__list-wrapper:nth-child(6) {
  margin-left: 2.1875rem;
}
.dashboard__list-wrapper:nth-child(8) {
  margin-top: 2.1875rem;
}
.dashboard__list-wrapper:nth-child(8) .dashboard__icon {
  margin-top: 1rem;
}

.dashboard__list-wrapper:hover .dashboard__icon {
  background-color: var(--bg-light-gray);
  color: var(--text-primary);
  z-index: 2;
}
.dashboard__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem;
  border-radius: 18.5rem;
  overflow: hidden;
  border: 2px solid var(--Logo-title);
  transition: all 0.3s;
  position: absolute;
 
}

.dashboard__list-wrapper .dashboard__icon span {
  text-align: center;
}

.dashboard__list-wrapper:nth-child(1) .dashboard__icon {
  right: 1.25rem;
}

.dashboard__list-wrapper:nth-child(4) .dashboard__icon {
  right: 1.25rem;
}

.dashboard__list-wrapper:nth-child(7) .dashboard__icon {
  right: 1.25rem;
}

.dashboard__icon img {
  height: 3.75rem;
}

.dashboard__icon span {
  font-weight: 500;
  display: none;
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  font-size: 1rem;
  white-space: nowrap;
}
.dashboard__list-wrapper.db-dashboard .dashboard__icon{
  border: 4px solid var(--Logo-title);
  padding: 0.25rem;
  width: 5rem;
  height: 5rem;
  padding: 0.625rem;
}


.dashboard__list-wrapper.db-dashboard .dashboard__icon:hover{
  background-color: transparent;
}

@media (min-width: 768px) {
  .dashboard__icon:hover span {
    display: inline-block;
    width: 5rem;
  }
  
}


@media (max-width: 576px) {
  .dashboard__list-wrapper.db-dashboard .dashboard__icon{
    left: 0;
    
  }
  .dashboard__list {
    gap: 0;
  }
  .dashboard__icon span {
    display: none;
  }
  .dashboard__icon {
    padding: 0.3125rem;
  }
  .dashboard__list-wrapper {
    height: 5.3125rem;
    width: 5.3125rem;
  }
}

/* 319px */
@media (max-width: 19.9375em) {
  .dashboard__list{
    margin-left: 1.875rem;
  }
  .dashboard__icon svg{
    height: 40px ;
    width: 40px;
  }
  .dashboard__list-wrapper:nth-child(4) .dashboard__icon,.dashboard__list-wrapper:nth-child(7) .dashboard__icon{
    padding:.75rem !important;
  }
  .dashboard__list-wrapper:nth-child(4) .dashboard__icon svg , .dashboard__list-wrapper:nth-child(7) .dashboard__icon svg{
   width: 30px;
   height: 30px;
  }

   .dashboard__list-wrapper:nth-child(2) {
    margin-top: -1.3rem;
  }
  .dashboard__list-wrapper:nth-child(8){
    margin-top: 1.3rem;
  }
   .dashboard__list-wrapper:nth-child(3) , .dashboard__list-wrapper:nth-child(9) {
   padding:0;
  }
  .dashboard__list-wrapper:nth-child(6) {
    margin-left: 1.5rem;
}
.dashboard__list-wrapper:nth-child(4) {
  margin-left: -1.4rem;
}

}
