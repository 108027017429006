.food-nav-main {
  background-color: var(--nav-bg-opacity);
}

.food-nav-item {
  color: var(--text-secondary);
  font-weight: 500;
  margin: 0 0.8rem;
  transition: 200ms ease-in-out;
  border-bottom: 2px solid transparent;
}

.food-nav-item:hover {
  color: var(--text-secondary);
  border-color: var(--border-success);
}

/* .food-nav-item-active{
  border-color: var(--border-success);

} */

.food-cart-icon {
  fill: var(--text-secondary);
}
.food-search-btn:hover{
  color: var(--text-primary);
}

.food-search-box.nav-search {
  width: fit-content;
  overflow: hidden;
  border-color: transparent;
}

.food-search-box {
  display: flex;
  border: 1px solid transparent;
}

.nav-logo-text-box {
  border-left: 0.15rem solid var(--border-secondary);
  color: var(--text-secondary);
  padding-left: 0.625rem;
}

.nav-logo-text-box h5 {
  font-weight: bold;
  font-size: 1.5rem;
}

.food-nav-uviom-text {
  color: var(--text-info);
}

.food-profile-sidebar {
  background-color: var(--sidebar-bg);
  width: 16rem;
  padding: 0;
}

.food-profile-sidebar .sidebar-body {
  padding: 0;
  overflow: hidden;
}

.sidebar-main .btn-close {
  color: var(--text-secondary) !important;
}

.food-navigation-sidebar .food-nav-item {
  display: block;
  margin: 1rem 1rem;
}
