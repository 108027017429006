.creative__slider {
  margin-top: 2rem;
  margin-bottom: 2.5rem;
}

.creative__slider__item {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.creative__slider__text,
.creative__slider__img {
  flex-basis: 100%;
}
.creative__slider__text {
  margin-left: 7rem;
}
.creative__slider__img {
  text-align: center;
  margin-right: 7rem;
}

.fake__pagination {
  margin-bottom: 2rem;
  display: inline-flex;
  gap: 2rem;
  max-width: 100%;
  overflow-x: scroll;
  align-items: flex-end;

  background-color: var(--light-dark-to-grey);
  padding-top: 2rem;
  padding-bottom: 1rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-top-right-radius: 3rem;
  border-top-left-radius: 3rem;
}
.fake__pagination::-webkit-scrollbar {
  display: none;
}
.fake__pagination__item {
  text-align: center;
}
.fake__pagination__icon {
  max-height: 5rem;
}
.fake__pagination__title {
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

@media only screen and (max-width: 62em) {
  /* 992P */
  .fake__pagination {
    gap: 1.5rem;
    padding-top: 1rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0.5rem;
  }

  .creative__slider__text {
    margin-left: 0rem;
  }
  .creative__slider__img {
    margin-right: 0rem;
  }

  .fake__pagination__icon {
    max-height: 4rem;
  }
}

@media only screen and (max-width: 48em) {
  /* 768P */
  .creative__slider {
    margin-top: 0.5rem;
  }
  .creative__slider__item {
    flex-direction: column-reverse;
  }

  .creative__slider__img img {
    max-height: 15rem;
  }
}

@media only screen and (max-width: 36em) {
  .fake__pagination {
    border-top-right-radius: 1.5rem;
    border-top-left-radius: 1.5rem;
  }
  .fake__pagination__icon {
    max-height: 3rem;
  }
}
