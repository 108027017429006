.companyBannerProfileIcon {
  position: absolute;
  bottom: 0.675rem;
  right: 0.675rem;
}

.jobDeatailsCover {
  position: relative;
  width: 100%;
  height: 20rem;
  
  overflow: hidden;
}

.jobDeatailsCover img {
  width: 100%;
  height: 100%;
}

.companyBannerCoverIcon,
.companyBannerProfileIcon {
  background: var(--bg-info);
  padding: 0.3125rem;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.companyBannerProfileIcon {
  right: 0.62rem;
  bottom: 0.62rem;
}

.companyBannerCoverIcon {
  position: absolute;
  top: 0.675rem;
  right: 0.675rem;
  cursor: pointer;
}

.jobDetailsProfile {
  background-color: var(--bg-secondary);
  position: absolute;
  display: inline-block;
  border-radius: 100%;
  border: 2px solid var(--border-primary);
  bottom: 0;
  right: 0;
  overflow: hidden;
  width: 3.8125rem;
  height: 3.8125rem;
}

.jobDetailsProfile img {
  width: 100%;
  height: 100%;
}

@media (min-width: 576px) and (max-width: 767px) {
  .jobDetailsProfile {
    height: 4.325rem;
    width: 4.325rem;
  }
}





@media (max-width: 1200px) and (min-width: 769px) {
  .jobDeatailsCover {
    height: 16rem;
  }

  .jobDetailsProfile{
    height: 5rem;
    width: 5rem;
  }
}
@media (max-width: 768px) {
  .jobDeatailsCover {
  height: 12rem;
  }
}

@media (max-width: 992px) and (min-width: 577px) {
  .companyBannerProfileIcon {
    bottom: 0.7rem;
    right: 0.5rem;
  }
}
@media (max-width: 576px) {
  .companyBannerProfileIcon {
    bottom: 5px;
    right: 5px;
  }
}
@media (min-width: 1200px) {
  .jobDetailsProfile{
    height: 6rem;
    width: 6rem;
  }
}
