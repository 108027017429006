.product-status-text {
  padding: 0.11rem 0.5rem;
  border-radius: 0.25rem;
}

.manage-order-btn:hover {
  color: var(--text-primary);
}

.food-tab .nav-link {
  font-size: var(--font-sm);
  color: var(--text-secondary);
  font-weight: 500;
}

.food-tab .nav-link.active {
  color: var(--text-success);
}

.food-tab ~ .tab-content .order-history {
  padding: 0 !important ;
}
