.findFreelancer {
}

.findFreelancer__content {
  position: relative;
}
.findFreelancer__overlay {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.findFreelancer__container {
  display: flex;
  gap: 1.5rem;
}
.findFreelancer__sidebar {
  background-color: #adbacb;
  padding: 1rem 0;
}
.findFreelancer__sidebar__link {
  display: block;
  padding: 0.5rem 1.5rem;
  white-space: nowrap;
  color: var(--text-secondary);

  position: relative;
  isolation: isolate;
}
.findFreelancer__sidebar__link::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  width: 0%;
  background-color: var(--bg-success);
  transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.findFreelancer__sidebar__link:hover::before {
  width: 100%;
}
.findFreelancer__content {
  flex-basis: 100%;
}
.findFreelancer__search {
  display: flex;
  gap: 1rem;
}
.findFreelancer__search > input[type="text"] {
  flex-basis: 30rem;
  border: 1px solid var(--border-success);
  padding: 0.5rem;
  border-radius: 0.3rem;
  background-color: transparent;
}
[theme="dark"] .findFreelancer__search > input[type="text"] {
  color: #fff;
}
.searched__input::placeholder {
  color: rgb(102, 102, 102);
}
.findFreelancer__search > input[type="submit"] {
  padding: 0.5rem 1.5rem;
  border-radius: 0.3rem;
  border: 0;
  color: #fff;
  background-color: var(--bg-success);
  transition: 0.3s all;
}
.findFreelancer__search > input[type="submit"]:hover {
  filter: brightness(115%);
}

.findFreelancer__title {
  font-size: 2.5rem;
}
.findFreelancer__title > span {
  font-weight: 600;
}

.findFreelancer__sub {
  max-width: 55ch;
}

.findFreelancer__skills {
  display: flex;
  gap: 1.2rem;
  margin-top: 1.5rem;
}

.findFreelancer__skills__link {
  display: inline-block;
  border: 1px solid #adbacb;
  padding: 0.5rem 1.5rem;
  border-radius: 0.2rem;
  white-space: nowrap;
  position: relative;
}
.findFreelancer__skills__link::before {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  height: 100%;
  width: 0%;
  background-color: var(--bg-success);
  transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.findFreelancer__skills__link:hover{
  border-color: var(--bg-success);;
}

.findFreelancer__skills__link:hover::before {
  width: 100%;
}

.findFreelancer__sidebar::-webkit-scrollbar,
.findFreelancer__skills::-webkit-scrollbar {
  display: none;
}

/* 992P */
@media only screen and (max-width: 62em) {
  .findFreelancer__container {
    flex-direction: column;
  }
  .findFreelancer__sidebar {
    display: flex;
    padding: 0.5rem 0;
    background-color: transparent;
    justify-content: space-between;
  }
  .findFreelancer__search > input[type="text"] {
    flex-grow: 1;
  }
  .findFreelancer__sidebar__link {
    padding: 0.3rem 1rem;
    border-radius: 0.3rem;
    overflow: hidden;
  }
  .findFreelancer__overlay {
    display: none;
  }
}

/* 768P */
@media only screen and (max-width: 48em) {
  .findFreelancer__title {
    font-size: 1.8rem;
  }
  .findFreelancer__skills__link {
    padding: 0.3rem 0.5rem;
  }
  .findFreelancer__sidebar {
    padding: 0.5rem 0.5rem;
  }
  .findFreelancer__sidebar__link {
    padding: 0.2rem 0.4rem;
  }
}

/* 576P */
@media only screen and (max-width: 36em) {
  .findFreelancer__container {
    gap: 0.5rem;
  }
  .findFreelancer__skills {
    overflow-x: scroll;
    margin-top: 1rem;
  }
  .findFreelancer__sidebar {
    overflow-x: scroll;
    gap: 0.5rem;
  }
  .findFreelancer__sidebar__link {
    overflow: unset;
  }
}

/* 400P */
@media only screen and (max-width: 25em) {
  .findFreelancer__search {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .findFreelancer__search > input[type="submit"] {
    flex-grow: 1;
  }
  .findFreelancer__title {
    font-size: 1.4rem;
  }
}
