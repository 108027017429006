.express__payment {
  padding: 1.5rem;
  background-color: var(--nav-bg-opacity);
  border-radius: 0.5rem;
}

.express__payment__box {
  display: grid;
  grid-template-columns: 1fr 2px 1fr;
  gap: 1.5rem;
}

.express__payment__box__divider {
  height: 100%;
  width: 0.15rem;
  border-radius: 1rem;
  background-color: #898785;
}

.express__payment__box__left {
  background-color: var(--white-to-grey);
  display: grid;
  grid-template-columns: 1fr max-content;
  align-content: center;
  border-radius: 0.5rem;
  padding: 1rem;
  font-weight: 500;
}
.express__payment__box__left > p:nth-child(even){
  text-align: right;
}
/* 768P */

@media (max-width: 48em) {
  .express__payment__box {
    grid-template-columns: 1fr;
  }
  .express__payment__box__divider {
    height: 0.15rem;
    width: 100%;
  }
}
