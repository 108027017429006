
/* -------Post------ */
.infinite-scroll-component {
    overflow: unset !important;
  }
  .mainContentWithRoundedBg{
    background-color: var(--bg-secondary);
    border-radius: 3px;
  }
  .coverPic{
    position: relative;
  }
  .coverPic{
    position: relative;
  }
  .coverPic .faIcon{
    position: absolute;
    top: 2%;
    right: 2%;
    background-color: var(--bg-info);
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
  .display-center{
    display: flex;
    align-items: center;
    justify-content: center;
    /* place-items: center; */
  }
  .coverProPic{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 15%;
    text-align: right;
  
  }
  .coverProPic img{
    border: 3px solid var(--border-secondary);
  
  
  }
  .coverProPic .faIcon{
    position: absolute;
    bottom: 6%;
    right: 6%;
    background-color: var(--bg-info);
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
  /* about ---------------------------- */
  .lifePageViewAbout{
    box-shadow: 1px 1px 4px 1px var(--shadow-clr);
    border :2px solid var(--border-secondary);
    padding: 3% !important;
  }
  @media only screen and (max-width: 768px) {
  /* tablet  + mobile */
  
    .coverPic .faIcon{
        font-size: .6rem;
  
    }
    .coverProPic .faIcon{
        width: 23px;
        height: 23px;
        font-size: .6rem;
    }
  }
  @media only screen and (min-width: 500px) and (max-width: 768px) {
    /* tablet  */
  
  }
  
  .lifePageView .navbar-brand,.lifePageView .nav-link{
    color: white !important;
  }

  .content_delete_spinner{
    position: absolute;
    inset: 9px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0000003f;
  }
  .content_delete_spinner span{
    letter-spacing: 2px;
    font-size: 14px;
    color: white !important;
  }

