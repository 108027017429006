.ApplicantDetailsBtn{
    border: none;
    background-color:var(--bg-primary);
    padding: 0.375rem 0.75rem;
    margin: 0.625rem;
    color: var(--text-primary);
    border-radius: 5px;
    transition: 0.3s;
    font-size: var(--font-sm);
}

.ApplicantDetailsBtn:hover{
    color: var(--text-success);
}