.cart-checkout-btn {
  width: 60%;
  line-height: 2.5rem;
  height: 2.5rem;
  background-color: var(--bg-success);
}

.cart-coupon-btn {
  width: 60%;
  line-height: 2.5rem;
  height: 2.5rem;
  transition: 300ms ease-in-out;
  border: 1px solid var(--border-primary);
}

.cart-coupon-btn:hover {
  background-color: var(--bg-success);
  border-color: var(--border-success);
}

.payment-method-input {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  font-size: var(--font-sm);
  border-color: var(--border-secondary);
  max-width: 22rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23F9A31A' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.payment-method-input:focus {
  box-shadow: var(--hover-box-shadow);

  border-color: var(--border-success);
}

.payment-method-input option {
  border: 1px solid var(--border-primary);
}

.delivery-charge {
  max-width: 100%;
}

.coupon-code-input {
  background-color: var(--bg-secondary);
  border-color: var(--border-secondary);
}

.coupon-code-input:focus {
  background-color: var(--bg-secondary);
  border-color: var(--border-success);
  box-shadow: var(--hover-box-shadow);
}

.coupon-apply-btn {
  width: 100%;
  border: 1px solid var(--border-success);
  background-color: transparent;
  color: var(--text-success);
  height: 100%;
  border-radius: 0.33rem;
  transition: box-shadow 200ms ease-in-out;
}

.coupon-apply-btn:hover,
.send-code-btn:hover {
  box-shadow: var(--hover-box-shadow);
}

.send-code-btn {
  border: 1px solid var(--border-success);
  background-color: transparent;
  color: var(--text-success);
  padding: 0.425rem 1rem;
  border-radius: 0.33rem;
  transition: box-shadow 200ms ease-in-out;
}
.send-code-btn:disabled {
  cursor: not-allowed;
}

.conformation .form-check-input {
  cursor: pointer;
}

.conformation .form-check-input:checked {
  background-color: var(--bg-success);
  border-color: var(--border-success);
}

.conformation .form-check-input:focus {
  box-shadow: var(--hover-box-shadow);
}

.custom-styles {
  --ReactInputVerificationCode-itemWidth: 3.5rem;
  --ReactInputVerificationCode-itemHeight: 2.5rem;
}

@media (max-width: 576px) {
  .custom-styles {
    --ReactInputVerificationCode-itemWidth: 2.5rem;
    --ReactInputVerificationCode-itemHeight: 2.5rem;
    --ReactInputVerificationCode-itemSpacing: 0.725rem;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .custom-styles {
    --ReactInputVerificationCode-itemWidth: 3rem;
    --ReactInputVerificationCode-itemHeight: 2.5rem;
    --ReactInputVerificationCode-itemSpacing: 0.725rem;
  }
}

.custom-styles .ReactInputVerificationCode__item {
  position: relative;
  color: var(--text-primary);
  font-weight: 500;
  border-color: var(--border-success);
}

.custom-styles .ReactInputVerificationCode__item:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 2px;
  background-color: var(--bg-info);
  transition: background-color 0.2s ease-out;
}

.custom-styles .ReactInputVerificationCode__item.is-active:after {
  background-color: var(--bg-success);
}

.ReactInputVerificationCode__container {
  margin: 0 auto;
}

.cart-confirm-btn {
  padding: 0.425rem 1.5rem;
  background-color: var(--bg-success);
  color: var(--text-primary);
  font-weight: 600;
}

.cart-confirm-btn:disabled {
  cursor: not-allowed;
  background: var(--bg-success);
}

.checkout-box .form-check-input:checked {
  background-color: var(--bg-success);
  border-color: var(--border-success);
}

.checkout-box .form-check-input:focus {
  box-shadow: var(--hover-box-shadow);
  border-color: var(--border-success);
}

.checkout-box .form-check label {
  font-size: 0.85rem;
}

.checkout-collapse-btn {
  border: 1px solid var(--border-secondary);
  background-color: transparent;
  color: var(--text-primary);
  padding: 0.4rem 0.5rem;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 0.22rem;
  justify-content: space-between;
  align-items: center;
  margin: 0 0;
  z-index: 11;
}

.checkout-collapse-btn span {
  font-size: var(--font-sm);
  z-index: -1;
}

.checkout-collapse-btn svg {
  z-index: -1;
}
