.single-message {
  border: 1px solid #adbacb;
  border-radius: 0.2rem;

  height: 30rem;
  display: flex;
  flex-direction: column;
}
.single-message__header {
  background-color: var(--text-info);
  display: grid;
  grid-template-columns: min-content 1fr max-content;
  padding: 0.5rem 1rem;
  gap: 1rem;
  align-items: center;
  color: #fff;
}
.single-message__img {
  --size: 2.5rem;
  height: var(--size);
  width: var(--size);
  object-fit: cover;
  border-radius: 50%;
}

.single-message__body {
  padding: 1rem;
  flex-grow: 1;
  overflow-y: scroll;

  scrollbar-width: thin;
}
.single-message__chunk {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}
.single-message__chunk:not(:last-child) {
  margin-block: 1rem;
}

.single-message__chunk.me {
  flex-direction: row-reverse;
}
.single__message__text {
  padding: 0.2rem 0.4rem;
  border: 1px solid #adbacb;
  border-radius: 0.5rem;
  max-width: 30ch;
  margin-bottom: 0;
}

/* 576px */
@media (max-width: 36em) {
  .single-message__header .btn__creative {
    padding: 0.5rem;
    font-size: 90%;
  }
  .single-message__header {
    gap: 0.5rem;
  }
}
