/* .placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite;
  }
  
  @keyframes placeholder-glow {
    50% {
      opacity: 0.2;
    }
  }
  .placeholder-wave {
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite;
  }
  
  @keyframes placeholder-wave {
    100% {
      mask-position: -200% 0%;
    }
  } */

.placeholder-slider-box {
  width: 100%;
  height: 100%;
  cursor: wait;
  background: currentColor;
  opacity: 0.5;
}

.placeholder-store-box {
  width: 300;
  height: 300;
  cursor: wait;
  background: lightgray;
  opacity: 0.5;
  animation: gradient 1.5s ease infinite;
}

.shop-skeleton {
  height: 100%;
  min-height: 8rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--bg-secondary);
  border-radius: 5px;
}

.shop-skeleton-content {
  flex: 1;
  display: flex;
  gap: 0.5rem;
}

.placeholder-avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  /* background: rgb(75, 75, 75); */

  --bs-bg-opacity: 0.6;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.shop-skeleton-content-text {
  flex: 1;
}

.category-skeleton-img {
  width: 80%;
  height: 80%;
  --bs-bg-opacity: 0.6;
  background-color: rgba(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity)
  ) !important;
}

.product-skeleton-img {
  background: rgb(75, 75, 75);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 50% 0%;
  }
}
