.SingleFriedsDiv {
  background-color: var(--bg-light-gray);
  padding: 0.5rem 0px;
  min-height: 4.5rem;
  border-radius: 10px;
}
.fndImg {
  border-radius: 50%;
}
.fndInfo {
  padding: 0.125rem 0.1875rem;
  border-radius: 15px;
  display: block;
  margin-bottom: 0.3125rem;
  font-size: var(--font-sm);
}
.hover_underline:hover{
  color: var(--text-success);
  text-decoration: underline;
}

.threedot {
  border-radius: 5px;
}
.acTiveBar {
  border: 2px solid var(--border-secondary);
}

.myCustomDropdown .dropdown-toggle {
  background: unset;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
}

.myCustomDropdown .dropdown-toggle::after {
  display: none;
}

