.InputWithLabelInput {
  border: none;
  background-color: var(--bg-secondary);
  transition: 0.03s ease;
  border: 1px solid transparent;
  color: var(--text-primary) !important;
  font-size: var(--font-sm);
}
.InputWithLabelInput:focus {
  background-color: var(--bg-secondary);
  border-color: var(--border-primary);
  outline: none;
  /* box-shadow: none; */
  box-shadow: var(--hover-box-shadow);
}

.InputWithLabelLabel {
  background-color: var(--bg-secondary);
  border-radius: 3px;
  text-align: center;
  font-size: var(--font-sm);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkout-input {
  border: 1px solid var(--border-primary);
  transition: 150ms ease;
}

.checkout-input:focus {
  border-color: var(--border-success);
  box-shadow: var(--hover-box-shadow);
}

.PhoneInputInput:focus {
  border-color: var(--border-success);
  border: none;
  outline: none;
}

.PhoneInputInput:disabled {
  cursor: no-drop;
}

.PhoneInput {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
  height: 2.33rem;
  font-size: var(--font-sm);
  border: 1px solid var(--border-primary) !important ;
  transition: 150ms ease;
  padding-left: 0.675rem;
  border-radius: 3px;
}

.PhoneInputInput {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary) !important;
  border: none;
  height: 100%;
}

.PhoneInputCountrySelect {
  background-color: var(--bg-secondary) !important;
  color: var(--text-primary);
}
