.roundEditBtn {
  background: var(--bg-info);
  height: 2.1875rem;
  width: 2.1875rem;
  border-radius: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileEditBtn {
  right: 2.5rem;
  bottom: 3.125rem;
}

.coverEditBtn {
  top: 1.25rem;
  right: 1.25rem;
}

.mainProfkeMiddleColTitle {
  text-align: center;
  padding: 1rem 0;
  border-radius: 5px;
  background-color: var(--bg-secondary);
  box-shadow: 1px 1px 4px 1px var(--shadow-clr);
}

.mainProfileLeftBtnActive > .LookMeTypeButton {
  background: var(--bg-primary) !important;
}

.mainProfileEditSection {
  background-color: var(--bg-secondary);
  border-radius: 5px;
}

.file-placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 50%;
  color: var(--placeholder-color);
}

.mainProfileInput {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-primary);
  color: var(--text-primary) !important;
  transition: 0.4s ease;
}
[theme="dark"] .mainProfileInput::placeholder{
  color: var(--text-primary) !important;
  opacity: .4;
}
.mainProfileInput:focus {
  background-color: var(--bg-primary);
  outline: none;
  border-color: var(--border-success);
  box-shadow: none;
}

.mainProfileSelect select option {
  background-color: var(--bg-secondary) !important;
}

.mainProfileSelect select option:hover {
  background: var(--bg-success) !important;
}

.mainProfileInput.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23F9A31A' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.mainProfileSelect select::-ms-expand {
  display: none;
}

.basicInfoSelectCol {
  position: relative;
}
.selectDownArrow {
  position: absolute;
  top: 0.5rem;
  z-index: -1;
  right: 30px;
}

.selectPlaceholder {
  color: var(--placeholder-color) !important;
}

.file-input {
  display: none;
}

label#file-input-label:disabled {
  border: 1px solid var(--border-primary) !important;
}

#file-input-label {
  width: 100%;
  padding: 0.4375rem 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
  border-radius: 5px;
}

#file-input-label .file-placeholder {
  font-size: var(--font-sm);
}
#file-input-label .file-text-btn {
  font-size: var(--font-xs);
  color: var(--text-success);
}

.file-label {
  font-size: var(--font-sm);
}

.mainProfileBtnDiv {
  display: flex;
  justify-content: flex-end;
}

.mainProfileBtn {
  border: 1px solid var(--border-primary);
  background: transparent;
  color: var(--text-primary);
  padding: 0.5rem 0.625rem;
  border-radius: 4px;
  transition: all 0.4s ease;
  font-size: var(--font-sm);
}

.mainProfileBtn:hover {
  color: var(--text-success);
}

.mainProfilePenEditBtn {
  cursor: pointer;
  transition: 0.4s ease;
  font-size: var(--font-sm);
}

.mainProfilePenEditBtn:hover {
  color: var(--text-success);
}

.mainProfileInput:disabled {
  background-color: var(--bg-secondary);
  cursor: not-allowed;
}

@media only screen and (max-width: 500px) {
  .profileEditBtn {
    right: 1.375rem;
    bottom: 1.5625rem;
  }

  .coverEditBtn {
    top: 0.625rem;
    right: 0.625rem;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  /* large  with tablet*/

  .profileEditBtn {
    right: 1.375rem;
    bottom: 1.5625rem;
  }

  .coverEditBtn {
    top: 0.625rem;
    right: 0.625rem;
  }
}
