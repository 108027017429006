.store-banner-profile {
  background: var(--bg-primary);
  position: absolute;
  overflow: hidden;
  display: inline-block;
  bottom: 0;
  right: 0;
  max-width: 5rem;
  aspect-ratio: "1/1";
  border-radius: 100%;
}

.cover-profile-banner {
  min-height: 12rem;
  max-height: 20rem;
  position: relative;
  overflow: hidden;
}

.cover-profile-banner>img{
 height: 100%;
  width: 100%;
}

.store-banner-profile img {
  border-radius: 100%;
}

@media   (max-width: 576px) {
  .store-banner-profile {
    
    max-width: 4.5rem;
  }
}

@media  (min-width: 768px) {
  .store-banner-profile {
    max-width: 7.5rem;
  }
}
@media  (max-width: 768px) {
  .cover-profile-banner{
    max-height: 10rem;
    min-height: 7rem;
  }

  .cover-profile-banner .companyBannerProfileIcon{
    right: 0.4rem;
    bottom: 0.4rem;
    width: 1.5rem;
    height: 1.5rem;
    }
}

@media (max-width:992px) and (min-width:769px){
  .cover-profile-banner .companyBannerProfileIcon{
    right: 1rem;
    bottom: 1rem;
  }
}