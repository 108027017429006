.manage-order p{
    font-size: var(--font-sm);
    margin-bottom: 0.25rem;
}

.step-wizard {

    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.step-wizard-list{
    width: 100%;
    color: var(--text-primary);
    list-style-type: none;
    border-radius: 0.675rem;
    display: flex;
    padding: 1.25rem 0.675rem;
    position: relative;
    z-index: 10;
}

.step-wizard-item{
    padding: 0 1.25rem;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive:1;
    flex-grow: 1;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-width: 10.65rem;
    position: relative;
}
.step-wizard-item + .step-wizard-item:after{
    content: "";
    position: absolute;
    left: 0;
    top: 1.1875rem;
    background: var(--bg-success);
    width: 100%;
    height: 0.125rem;
    transform: translateX(-50%);
    z-index: -10;
}
.progress-count{
    height: 2.5rem;
    width:2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    margin: 0 auto;
    position: relative;
    z-index:10;
    color: transparent;
}
.progress-count:after{
    content: "";
    height: 2.5rem;
    width: 2.5rem;
    background:var(--bg-success);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    z-index: -10;
}
.progress-count:before{
    content: "";
    height: .675rem;
    width: 1.25rem;
    border-left: 0.190rem solid var(--border-secondary);
    border-bottom: 0.190rem solid var(--border-secondary);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%) rotate(-45deg);
    transform-origin: center center;
}
.progress-label{
    font-size: var(--font-sm);
    font-weight: 600;
    margin-top: 0.675rem;
}
.current-item .progress-count:before,
.current-item ~ .step-wizard-item .progress-count:before{
    display: none;
}
.current-item ~ .step-wizard-item .progress-count:after{
    height:.975rem;
    width:.975rem;
}

.current-item  .progress-count:before{
    content: "";
    height:.875rem;
    width:.875rem;
    background-color: var(--bg-success);
    border-radius: 100%;
    position: absolute;
    top: calc(50% + 0.125rem);
    left: 50%;
    border-left: none;
    border-bottom: none;
    display: inline;

}
.current-item ~ .step-wizard-item .progress-label{
    opacity: 0.7;
}
.current-item .progress-count:after{
    background-color: var(--bg-secondary);
    border: 0.125rem solid var(--border-success);
}
.current-item .progress-count{
    color: var(--text-success);
   
}


@media (max-width:992px) {
    .step-wizard-item{
        min-width: 8rem;
    }
    
}
@media (max-width:576px) {
    .step-wizard-item{
        min-width: 6.5rem;
    }
    
}