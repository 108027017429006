.dashboardNav.navbar {
  background-color: var(--nav-bg);
  box-shadow: 1px 1px 8px 2px var(--shadow-clr);
  color: var(--text-primary);
}

.dashboardNav .nav-link {
  color: var(--text-primary) !important;
  font-size: var(--font-md);
  margin-left: 1.25rem;
}

.dashboardNav .Typewriter__wrapper {
  font-size: 80%;
}
.navPadding {
  padding-left: 2%;
  padding-right: 2%;
}
logo img {
  width: 3.75rem;
}
.navbar {
  background-color: var(--sidebar-bg);
  box-shadow: 1px 1px 8px 2px var(--shadow-clr);
}

.navIcon {
  color: var(--text-primary);
  size: 1.5625rem;
  transition: all 0.6s;
}
.navIcon:hover {
  color: var(--text-primary);
  transform: scale(1.2);
}

.cousotomNavIcon {
  transition: all 0.6s; cursor: pointer;
}
.cousotomNavIcon img {
  width: 2.1875rem;
}
.SideNavMenu {
  background-color: var(--bg-primary);
  width: 100%;
}

.type-writer-text-box{
  border-left: 0.1rem solid #6984A5;
  color: #6984A5;
  padding-left: 0.35rem;
  
}
.type-writer-text span{
  /* font-size: var(--font-sm); */
}
 
  .type-writer-text-box h5{
      font-weight: bolder;
      
  }


@media only screen and (min-width: 992px) {
  .cousotomNavIcon {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .ExpandNav {
    display: none;
  }
}
