.store__banner-main {
  min-height: 20rem;
  max-height: 18rem;
  background-color: rgba(0, 0, 0, 0.397);
  background-size: cover;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-blend-mode: overlay;
  border-radius: 0.675rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .store__banner-main {
    min-height: 15rem;
  }
}
