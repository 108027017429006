.jobPost {
  padding: 1rem;
  border: 1px solid #adbacb;
  border-bottom: 0;
}
.jobPost:last-child {
  border-bottom: 1px solid #adbacb;
}
.jobPost__badge__line {
  display: flex;
  gap: 1.5rem;
}
.jobPost__badge__line svg {
  color: var(--text-success);
  margin-right: 0.3rem;
}
.jobPost__top {
  display: flex;
  align-items: flex-start;
  gap: 2rem;
}
.jobPost__title {
  margin-bottom: 0.2rem;
  font-size: 1.5rem;
}
.jobPost__sub {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.jobPost__details {
  opacity: 0.9;
  margin-top: 1rem;
}
.jobPost__buttons {
  white-space: nowrap;
  text-align: right;
}

/* 768px */
@media (max-width: 48em) {
  .jobPost__title {
    font-size: 1.2rem;
  }
  .jobPost__sub {
    font-size: 1rem;
  }
  .jobPost__details {
    margin-top: 0.5rem;
  }
  .jobPost__top {
    flex-direction: column;
    gap: 0.5rem;
  }
  .jobPost__buttons {
    text-align: left;
  }
}
