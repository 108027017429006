.job__category__slider-item > .job__category-slider-imgBox {
  width: 100%;
  aspect-ratio: 16/9;
  background-color: var(--eco-product-bg);
  color: var(--text-primary);
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.job__category__slider-item > .job__category-slider-imgBox > img {
  width: 100%;
  height: 100%;
}

.job__category__slider-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-primary);
}
.job__category__slider-item p {
  transition: all 300ms ease-in-out;
}

.job__category__slider-item:hover p {
  color: var(--text-success);
  text-decoration: underline;
}
.parentSliderSwiper,
.parentSlider {
  height: inherit !important;
}

.category-slider .swiper-button-next:after,
.category-slider .swiper-button-prev:after {
  --swiper-navigation-size: 0.98rem !important;
}
.category-slider .swiper-button-next,
.category-slider .swiper-button-prev {
  height: 2.18rem !important;
  font-size: 0.95rem !important;

  width: 2.18rem !important;
  color: var(--bg-success) !important;
  border-radius: 4px;
  font-weight: 600;
  border: 1px solid var(--bg-success);
  /* background-color: var(--bg-success); */
}

.job__company-card .jobPostTitleText {
  max-width: calc(100% - 4.375rem);
}

.job__home__banner {
  overflow-x: hidden !important;
}
.job__home__banner > div > img {
  width: 100%;
  aspect-ratio: 16/4;
}

.job__company-card {
  background-color: var(--bg-primary);
  border-radius: 0.3rem;
}
.job__dashboard-container {
  display: flex;
  gap: 1rem;
  justify-content: space-evenly;
}

.job__dashboard-item {
  width: 10rem;
  aspect-ratio: 1/1;
  background-color: var(--bg-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  text-align: center;
}
.job__dashboard-icon {
  width: 3.5rem;
  height: 3.5rem;
  display: grid;
  place-items: center;
  border-radius: 100%;
  background-color: #e6eff0;
  color: var(--stock-color);
  margin-bottom: 1rem;
  padding: 0.5rem 0.3rem;
}

.job__dashboard-item p {
  margin: 0;
  font-weight: bold;
}
.job__dashboard-item h6 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--text-success);
}

.job__home__banner-item1 {
  clip-path: polygon(100% 13%, 100% 50%, 100% 89%, 0% 100%, 0 50%, 0% 0%);
  width: 100%;
  overflow: hidden;
}
.job__home__banner-item2 {
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0 89%, 0 50%, 0 13%);
  width: 100%;
  overflow: hidden;
}
.job_cart-hover h6 {
  transition: 0.2s ease-in-out;
  position: relative;
}

.job_cart-hover:hover h6 {
  color: var(--text-success);
  text-decoration: underline;
}

.send-code-btn:hover {
  box-shadow: var(--hover-box-shadow);
}

.send-code-btn {
  border: 1px solid var(--border-success);
  background-color: transparent;
  color: var(--text-success);
  padding: 0.425rem 1rem;
  border-radius: 0.33rem;
  transition: box-shadow 200ms ease-in-out;
}
.send-code-btn:disabled {
  cursor: not-allowed;
}

@media (min-width: 992px) and (max-width: 1250px) {
  .job__dashboard-icon {
    height: 2.5rem;
    width: 2.5rem;
    margin-bottom: 0.5rem;
  }
  .job__dashboard-text p {
    font-size: var(--font-sm);
  }
  .job__dashboard-text h6 {
    font-size: 1.2rem;
  }
}

/* ≥768px */
@media (max-width: 48em) {
  .category-slider .swiper-button-next,
  .category-slider .swiper-button-prev {
    display: none;
  }
}

@media (max-width: 576px) {
  .job__home__banner > div > img {
    width: 100%;
    aspect-ratio: 16/8;
  }
  .job__dashboard-icon {
    height: 2.5rem;
    width: 2.5rem;
    margin-bottom: 0.5rem;
  }
  .job__dashboard-container {
    gap: 0.5rem;
  }
  .job__dashboard-item p {
    font-weight: 500;
    font-size: var(--font-sm);
  }
  .job__dashboard-item h6 {
    font-size: 1rem;
  }
}
/* 440px */
@media (max-width: 27.5em) {
  .job__dashboard-container.cards{
     display: grid;
     grid-template-columns: repeat(2,1fr);
  }
  .job__dashboard-container.cards .job__dashboard-item{
    width: 100%;
    padding:.8rem;
    aspect-ratio: 3/2;
  }
  
}


@media (max-width: 420px) {
  .job__category-home-item {
    width: 100% !important;
  }
  .job__dashboard-item {
    width: 8rem;
  }
}
