.intersectionWithRider {
  max-width: 50rem;
  background-color: var(--white-to-grey);
  margin-inline: auto;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(0 0 0 / 0.1);
}

.intersectionWithRider__location--container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.intersectionWithRider__location {
  display: flex;
  gap: 1rem;
}

.intersectionWithRider__location--icon {
  background-color: var(--View-bg);
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.intersectionWithRider__details {
  margin-top: 1.5rem;
}
.intersectionWithRider__cost {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.intersectionWithRider__rider {
  display: grid;
  grid-template-columns: min-content 13rem 1fr;
  align-items: center;
  gap: 1rem;
}
.intersectionWithRider__rider--img > img {
  border: 3px solid var(--bg-success);
  border-radius: 50%;
}
.intersectionWithRider__rider--name {
  margin-bottom: 0.2rem;
  font-size: 1.1rem;
}
.intersectionWithRider__rider--rating {
  margin: 0;
  font-size: 0.8rem;
  color: var(--view-yellow);
}

.intersectionWithRider__rider--contact {
  cursor: pointer;
  border-radius: 50%;
  color: var(--clr-white);
  background-color: var(--bg-success);
  box-sizing: content-box;
  padding: 0.7rem;
}
.intersectionWithRider__rider--contact:not(:last-child) {
  margin-right: 0.5rem;
}

.intersectionWithRider__rider--carModel {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 36em) {
  .intersectionWithRider__location {
    flex-direction: column;
    gap: 0;
  }
  .intersectionWithRider__rider--name {
    font-size: 0.9rem;
  }

  .intersectionWithRider__rider--img > img {
    width: 10vmax;
  }
  .intersectionWithRider__rider--contact {
    padding: 0.5rem;
  }
  .intersectionWithRider__rider {
    grid-template-columns: min-content 1fr max-content;
  }
}
