.live__video__textarea{
    border: 1px solid var(--border-secondary);
    padding: 0.3125rem;
    margin: 0.625rem 0;
}

.go__live-btn{
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-secondary);
    color: var(--text-primary);
    display: block;
    width: 100%;
    padding: 0.625rem ;
    border-radius: 5px;
}