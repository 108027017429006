.addbalance {
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid #adbacb;
}

.addbalance__title {
  background-color: var(--text-info);
  color: #fff;
  padding: 0.5rem;
  font-size: 1.1rem;
  text-align: center;
}

.addbalance__inputs {
  padding: 1.5rem;
}
