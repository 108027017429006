.messages__container {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 1.5rem;
}

.messages {
  background-color: var(--light-dark-to-grey);
  border-radius: 0.3rem;
  overflow: hidden;
}

.messages__title {
  margin-bottom: 0;
  font-size: 1.1rem;
  text-align: center;
  background-color: #a3b1c1;
  padding: 0.5rem;
}
.messages__title > svg {
  margin-right: 0.5rem;
}
.messages__profile {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1rem;
  padding: 0.5rem 1rem;
}
.messages__profile:first-child{
  margin-top: .5rem;
}
.messages__profile:not(:last-child) {
  border-bottom: 1px solid #adbacb;
}
.messages__profile__img {
  --size: 4rem;
  height: var(--size);
  width: var(--size);
  border-radius: 50%;
  object-fit: cover;
}

/* 992px */
@media (max-width: 62em) {
  .messages__container {
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
}

/* 768px */
@media (max-width: 48em) {
  .messages__container {
    grid-template-columns: 1fr;
  }
  .messages__title {
    cursor: pointer;
  }
}
