.previousWorkPreview {
  border-radius: 0.3rem;
  border: 1px solid #adbacb;
  background-color: var(--white-to-grey);
}
.previousWorkPreview__content {
  padding: 1rem;
}
.previousWorkPreview__img {
  max-width: 100%;
}
