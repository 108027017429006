.pdfViewerMainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 111;
    position: relative;
    overflow-x: scroll;
}

.pdfViewerCloseBtn{
    position: fixed;
    top: 0.625rem;
    right: 0.625rem;
    border: none;
    background: var(--bg-info);
    color: white;
    padding: 0.31rem 0.625rem;
    border-radius: 4px;
    transition: 0.3s;
}

.pdfViewerCloseBtn:hover{
    color: var(--text-success);
}


.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFPage {
    box-shadow: 0 0 8px var(--shadow-clr);
}

.PDFPageOne {
    margin-bottom:1.5625rem;
}

.PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
}