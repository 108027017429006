.profileSingleVideoCard {
  background: var(--bg-primary);
  padding: 0.4375rem;
}

.profileSingleVideoText {
  font-size: var(--font-sm);
  height: 2.5rem;
  overflow: hidden;
}

.profileSingleVideoInfoText {
  display: flex;
}

.profileSingleVideoInfoText p {
  color: var(--text-success);
  font-size: var(--font-xs);
}

.profileSingleVideoInfoText p:first-child {
  border-right: 1px solid var(--border-primary);
}
