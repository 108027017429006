.bestTimeCardText {
  position: absolute;
  bottom: 0.2rem;
  width: 100%;
  font-size: var(--font-sm);
}

.bestTime__card-threeDot-box {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 222;
  cursor: pointer !important;
}

.bestTime__card-threeDot-option {
  position: absolute;
  background-color: var(--bg-secondary);
  padding: 0.5rem 0;
  border-radius: 0.2rem;
  top: 2.2rem;
  right: 0.5rem;
  z-index: 500;
}

.bestTime__card-threeDot-option button {
  display: block;
  color: var(--text-primary);
  font-size: 0.9rem;
  padding: 0.3rem 0.5rem;
  width: 100%;
  text-align: start;
  cursor: pointer;
  transition: 100ms ease-in-out;
}
.bestTime__card-threeDot-option button:hover {
  background-color: var(--bg-info);
}
