a {
  text-decoration: inherit;
  color: inherit;
}
a:hover {
  color: inherit;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#wrapper {
  flex: 1;
  padding-top: 4rem;
}

/* typography */

.heading-1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: var(--text-secondary);
}

.heading-3 {
  font-size: 1rem;
  font-weight: 500;
}

.rideMap {
  height: 60vmin;
}

@media (min-width: 130em)  {
  .rideMap {
    height: 50rem;
  }
}
/* css don't support height and width at a time without specifying the min-max value on both */
@media (min-height: 67.5rem)  {
  .rideMap {
    height: 50rem;
  }
}





/* Utils */
.py-7 {
  padding-block: 4.375rem;
}
.fw-400 {
  font-weight: 400;
}
.link-unstyled,
.link-unstyled:link,
.link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}
.bottom-10 {
  bottom: 10%;
}

.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.bg-img.contain {
  background-size: contain;
}
.bg-bottom {
  background-position: bottom;
}
.btn__ride {
  background-color: var(--view-yellow);
  border-color: var(--view-yellow);
  color: var(--dark-to-white);
}
.btn__ride:is(:hover, :active, :focus) {
  color: var(--text-primary);
  background-color: var(--border-success);
  border-color: var(--border-success);
}

@media only screen and (max-width: 62em) {
  .heading-1 {
    font-size: 1.6rem;
    margin-bottom: 0.2rem;
  }
}
@media only screen and (max-width: 48em) {
  .heading-1 {
    font-size: 1.3rem;
  }
}
@media only screen and (max-width: 36em) {
  ul.top-50 {
    top: 65% !important;
  }
  .bg-img.contain {
    background-position: top;
  }
}
