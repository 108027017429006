.job-banner-main {
  min-height: 15rem;
  position: relative;
  /* border: 1px solid #000; */
}

.job-banner-overlay {
  position: absolute;
  inset: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.742734593837535) 0%,
    rgba(255, 255, 255, 0.5942752100840336) 20%,
    rgba(255, 255, 255, 0.3449754901960784) 38%,
    rgba(0, 0, 0, 0) 58%,
    rgba(0, 0, 0, 0) 77%
  );
  color: var(--text-primary);
  z-index: 1;
}

.bd {
  border: 1px solid var(--border-primary);
}

.job-search-box {
  width: 25rem;
  /* border: 1px solid var(--border-success); */
  background-color: var(--clr-white);
  border-radius: 0.2rem;
}

.job-location-search{
  width: 12rem;
}

.job-search-box:focus-within {
  box-shadow: var(--hover-box-shadow);
  border-color: var(--border-success);
}

.job-search-box span {
  background-color: transparent;
  border: none;
}

.job-search-box input {
  background-color: transparent;
  border: none;
}

.job-search-box input:focus {
  box-shadow: none;
  background-color: var(--clr-white);
}

.banner-find-btn {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  color: var(--text-primary);
}
.job__slider-item {
  height: 100%;
}

.job__search-select:focus{
  box-shadow: none;
  border-color: transparent;
}

.job__search-select{
  font-size: var(--font-sm);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;

}

.job__select__search-box span{
  display: none;
}
@media (max-width: 576px) {
  .job-search-box {
    width: 13rem;
  }
  .job-banner-main {
    min-height: 10rem;
  }
  .job-banner-overlay .display-6{
    margin-bottom: 0 !important;
  }
  .job-banner-overlay .display-6 ,
  .job-banner-overlay .display-5 {
    font-size: 130%;
  }
  .job__slider-item {
    height: 100%;
  }

.job-search-box,.banner-find-btn{
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }
  .job-search-box input{
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}


@media (max-width:450px) {
  .job__select__search-box span{
    display: inline;
  } 
  .job-search-box{
    width: 10rem;
  }
}

@media (max-width:992px) and (min-width:577px) {
  .job-search-box {
    width: 16rem;
  }
   .job-banner-main {
    max-height: 18rem;
  }
}

@media (max-width: 25em) {
  .job-banner-main {
    min-height: 7rem;
  }
  
}