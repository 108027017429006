.singleJobPostContainer {
  background: var(--bg-primary);
  padding: 0.675rem;
  justify-content: space-between;
  border-radius: 0.25rem;
}


.jobPostImgContainer img {
  width: 100%;
  height: 100%;
}

.jobPostImgDiv {
  background-color: var(--bg-secondary);
  border-radius: 0.11rem;
  overflow: hidden;
  width: 4.375rem;
  height: 4.375rem;
}

.jobPostIconContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.job-save-btn {
  z-index: 112;
  transition: all 200ms ease-in-out;
}

.job-save-btn:hover {
  color: var(--text-success);
}

.job-save-btn:disabled {
  color: var(--text-success);
  cursor: not-allowed;
}

.isApplied-tag {
  color: var(--clr-dark);
  background: var(--bg-success);
  font-weight: bold;
  text-align: center;
  font-size: 0.8rem;
  border-radius: 0.15rem;
  margin: 0.3rem 0;
  padding: 0 0.3rem;
}
