.productPage{
    min-height: 65vh;
}
.loadButtonGray{
  background: var(--bg-gray);
  color: var(--text-primary);

}
.btn-unset{
  all: unset ;
  cursor: pointer;
}
.quantity{
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
}
.quantity .btn{
  background: var(--text-info);
}