.coverAndProfileSection {
  height: 25rem;
  width: 80%;
  margin-inline: auto;
  box-shadow: var(--box-shadow-2);
  position: relative;
}
.life__cover__gradient {
  /* background-image: linear-gradient(
    190deg,
    rgba(249, 163, 26, 0.36) 2.32%,
    rgba(105, 132, 165, 0.3) 98.44%
  ); */
  /* background-image: linear-gradient(90deg, rgba(0,103,71,0.7) 0%, rgba(218,41,28,0.7) 100%);; */

  background: rgb(0, 103, 71);
  background: linear-gradient(
    10deg,
    rgba(0, 103, 71, 0.9) 0%,
    rgba(218, 41, 28, 0.9) 44%,
    rgba(0, 103, 71, 0.9) 100%
  );

  background: rgb(0, 103, 71);
  background: radial-gradient(
    circle,
    rgba(0, 103, 71, 0.9) 0%,
    rgba(218, 41, 28, 0.9) 44%,
    rgba(0, 103, 71, 0.9) 100%
  );
}
.coverPic .cover {
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-fit: cover;
}
.coverSmallText {
  text-align: right;
}
.width-inherit {
  width: inherit;
}
.align-items-self-end {
  align-items: self-end;
}
.ProfileSection {
  --size: 8rem;
  height: var(--size);
  width: var(--size);
  display: flex;
  align-items: center;
  border: 3px solid var(--border-secondary);
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: 0px;
  z-index: 0;
  /* bottom: -20px; */
}
.ProfileSection img {
  border-radius: 50%;
}
/* For small shape  */
.coverAndProfileSectionSmall {
  position: relative;
  background-color: var(--light-gray);
  cursor: pointer;
  /* box-shadow: 1px 1px 4px 1px var(--view-shadow); */
  border-radius: 0.2rem;
  overflow: hidden;
}
.coverAndProfileSectionSmall__cover {
  height: 6rem;
  border-top-left-radius: 0.2rem;
  border-top-right-radius: 0.2rem;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  transition: 0.3s;
}
.coverAndProfileSectionSmall:hover .coverAndProfileSectionSmall__cover {
  transform: scale(1.1);
}
.coverAndProfileSectionSmall__profile {
  height: 3.5rem;
  border-radius: 50%;
  transform: translateY(-85%);
}
.coverAndProfileSectionSmall__profile__container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.leftCol .SmallCoverText {
  margin-top: inherit;
}
.ProfileSectionSmall {
  height: 3.125rem;
  width: 3.125rem;
  border: 3px solid var(--border-secondary);
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: -5px;
}
.coverEditIcon {
  position: absolute;
  top: 3%;
  right: 2%;
  z-index: 100;
  cursor: pointer;
}

.roundBackground {
  background-color: var(--bg-info);
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  place-items: center;
  border-radius: 50%;
}
.profileIcon {
  background: var(--bg-info);
  position: absolute;
  bottom: 1.4rem;
  right: 1.4rem;
  transform: translate(55%, 55%);
  cursor: pointer;
  z-index: 2000;
}

/* 1200px */
@media (max-width: 75em) {
  .coverAndProfileSection {
    width: 90%;
  }
}
/* 992px */
@media (max-width: 62em) {
  .coverAndProfileSection {
    width: 100%;
    height: 20rem;
  }
  .life__cover__gradient {
    background-image: none;
    margin-inline: -1.2rem;
  }
  .ProfileSection {
    right: 0.5rem;
  }
}
/* 768px */
@media (max-width: 48em) {
  .coverAndProfileSection {
    height: 15rem;
  }
}
/* 576px */
@media (max-width: 36em) {
  .coverAndProfileSection {
    height: 10rem;
  }
}

/* from 992px to 800px */
@media (min-width: 50rem) and (max-width: 62em) {
  .coverAndProfileSectionSmall__cover {
    height: 13rem;
  }
}

/* from 800px to 500px */
@media (min-width: 31.5rem) and (max-width: 50rem) {
  .coverAndProfileSectionSmall__cover {
    height: 10rem;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .ProfileSection {
    height: 6.25rem;
    width: 6.25rem;
  }
  .profileIcon {
    transform: translate(80%, 70%);
  }
}

@media only screen and (max-width: 500px) {
  .ProfileSection {
    height: 4.5rem;
    width: 4.5rem;
    /* bottom: -0.5rem; */
  }
  .profileIcon {
    transform: translate(90%, 85%);
  }
}
