.receiptBox {
  padding: 1.5rem;
  background-color: var(--light-dark-to-grey);
  border-radius: 4px;
  margin-top: 1rem;
  padding-bottom: 5rem;
  color: var(--text-secondary);
}

.receiptBox__user--info {
  margin-bottom: 1.5rem;
}

.receiptBox__user--location {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 3rem;
  margin-bottom: 1.5rem;
}
.receiptBox__user--details {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 1rem;
}
.receiptBox__userProvidedRating {
  margin-top: 1rem;
}
.receiptBox__payment {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  align-items: center;
}
.receiptBox__payment--paid,
.receiptBox__payment--method {
  padding: 0.7rem 1rem;
  border-radius: 4px;
  border: 1px solid var(--dark-to-white);
  box-shadow: var(--box-shadow-2);
  display: flex;
  gap: 1rem;
}

.receiptBox__rideInfo {
  margin-block: 1rem;
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid var(--dark-to-white);
  box-shadow: var(--box-shadow-2);
}
.receiptBox__rider {
  display: grid;
  grid-template-columns: min-content 15rem 1fr;
  align-items: center;
  gap: 1rem;
}

.receiptBox__rider--img > img {
  border: 3px solid var(--bg-success);
  border-radius: 50%;
}
.receiptBox__rider--name {
  margin-bottom: 0.2rem;
  font-size: 1.1rem;
}
.receiptBox__rider--rating {
  margin: 0;
  font-size: 0.8rem;
  color: var(--view-yellow);
}
.receiptBox__rider--car {
  margin: 0;
  font-size: 0.9rem;
}
.receiptBox__rider--carModel {
  margin: 0;
  font-size: 1.1rem;
}

.receiptBox__details {
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 5rem;
  row-gap: 0.5rem;
}
.receiptBox__greeting {
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--text-secondary);
}
.receiptBox__buttons {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 5rem;
  justify-content: flex-end;
}

@media only screen and (max-width: 48em) {
  .receiptBox {
    padding-bottom: 2rem;
  }
  .receiptBox__rider {
    grid-template-columns: min-content 1fr 1fr;
  }
  .receiptBox__details {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .receiptBox__buttons {
    margin-bottom: 3rem;
  }
  .receiptBox__payment--paid,
  .receiptBox__payment--method {
    font-size: 0.9rem;
    gap: 0.2rem;
  }
}

@media only screen and (max-width: 36em) {
  .receiptBox {
    padding: 1rem;
  }
  .receiptBox__user--info {
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
  .receiptBox__user--location {
    column-gap: 1.5rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
  }
  .receiptBox__user--details {
    font-size: 0.9rem;
  }
  .receiptBox__userProvidedRating {
    font-size: 0.9rem;
  }
  .receiptBox__rider--name {
    margin-bottom: 0;
  }
  .receiptBox__details {
    column-gap: 0;
    grid-template-columns: 1fr 0.3fr;
    align-items: center;
    font-size: 0.9rem;
  }
  .receiptBox__details > *:nth-child(2n) {
    text-align: right;
  }
  .receiptBox__greeting {
    font-size: 0.8rem;
  }
  .receiptBox__rider {
    grid-template-columns: max-content;
    gap: 0.5rem;
  }
  .receiptBox__payment {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
  .receiptBox__payment--paid,
  .receiptBox__payment--method {
    gap: 0.5rem;
    font-size: 0.8rem;
  }
}
