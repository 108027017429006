.registration__container {
  background-color: var(--light-dark-to-grey);
  max-width: 50rem;
  margin-inline: auto;
  padding: 2rem 1.5rem;
  border-radius: 0.3rem;
}

.registration__timeline {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  width: 75%;
  margin-inline: auto;
  margin-bottom: 1rem;
}
.registration__timeline--item {
  flex-basis: 100%;
  display: flex;
}

.registration__timeline--item:last-child {
  width: 0;
  flex-basis: 0;
}
.registration__timeline--item:last-child,
.registration__timeline--item:last-child:before {
  width: 0;
}
.registration__timeline--item::before {
  content: "";
  height: 0.5rem;
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid var(--text-primary);
}

.registration__timeline span {
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 50%;
  background-color: var(--View-bg);
  border: 1px solid var(--text-primary);
  position: absolute;
  transform: translateY(2px);
}

.registration__timeline .active span {
  background-color: var(--view-yellow);
  border-color: var(--view-yellow);
  outline: 1px solid var(--view-yellow);
  outline-offset: 0.2rem;
}
.registration__timeline--item.active::before {
  border-color: var(--view-yellow);
}

.registration__timeline__text {
  display: flex;
  gap: 1.5rem;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin-top: 1.5rem;
}

.registration__timeline__text--item {
  color: var(--dark-to-white);
  width: 10rem;
  text-align: center;
  background-color: var(--food-nav-bg);
  padding: 0.4rem 1rem;
  border-radius: 0.3rem;
  box-shadow: 0 4px 10px rgba(0 0 0 / 0.05);
}
.registration__timeline__text--item.active {
  background-color: var(--view-yellow);
}

@media only screen and (max-width: 48em) {
  .registration__container {
    display: none;
  }
}
