.imgFolder{
    border-radius: 8px;
}
.photoVideoDiv{
    background-color: none;
}
.imageParent{
    height: 8rem;
}
.imageParent img{
    width: 100%;
    height: inherit;
}
