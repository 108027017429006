.chat__content-main {
  background-color: var(--bg-secondary);
  overflow-x: hidden;
}

.chat__content__header-btn {
  padding: 0.1875rem 0.625rem;
  background: transparent;
  color: var(--text-primary);
  border-radius: 10px;
  font-size: var(--font-sm);
  display: flex;
  align-items: center;
}

.chat__content__header-btn:hover {
  color: var(--text-success);
}

.Chat__header__btn-icon {
  color: var(--text-success);
  fill: var(--text-success);
}

.chat__btn-active .Chat__header__btn-icon {
  color: var(--text-primary);
  fill: var(--text-primary);
}

.chat__btn-active {
  border-color: transparent;
  background: var(--bg-success);
}
.chat__btn-active:hover {
  color: var(--text-primary);
}
.single__message-item {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 0.98rem;
  transition: all 0.3s ease;
  transform-origin: right;
  flex-flow: wrap;
}

.single__message-item.me {
  justify-content: flex-end;
  transform-origin: left;
}

.single__message-item.me .single__message-text {
  /* background: var(--bg-info); */
  color: var(--text-primary);
  /* overflow: hidden; */
}
.single__message-time {
  text-align: start;
  font-size: 0.625rem;
}
.single__message-item.me .single__message-time {
  text-align: start;
}

.single__message-text {
  /* padding: 0.5rem 0.7rem; */
  background: var(--bg-primary);
  color: var(--text-success);
  border-radius: 0.4rem;
  box-shadow: var(--box-shadow);
  max-width: 100%;
  border: 1px solid rgba(130, 151, 172, 0.438);
  box-shadow: 0px 0px 8px 3px rgba(103, 137, 168, 0.2);
}
.single__message-text .msg-padding {
  padding: 0.6rem 0.8rem;
}

.single__message-content {
  max-width: 75%;
  min-width: 13.4375rem;
  height: fit-content;
  transform-origin: left;
  display: flex;
  flex-direction: column;
}

.single__message-item.me .single__message-content {
  align-items: flex-end;
}

.single__message__checkMark {
  position: absolute;
  right: 0.125rem;
  bottom: -1.25rem;
}

.single__message__checkMark .seen {
  stroke: var(--text-success);
}
.single__message__checkMark .unseen {
  stroke: var(--text-primary);
}

.chat__content-body {
  height: calc(100vh - 12rem);
  overflow-y: scroll;
  position: relative;
  padding-right: 5px;
}

.chat__content-body::-webkit-scrollbar {
  width: 0.675rem !important;
}

.chat__content-btn {
  border: none;
  height: 2.375rem;
  width: 2.5rem;
  line-height: 1.875rem;
  background: var(--bg-primary);
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.chat__content__footer-emoji {
  background: var(--bg-primary);
  border-color: transparent;
  color: var(--text-success);
}

.message__dropdown-body {
  background: transparent;
  border: none;
}

#message__dropdown-btn::after {
  content: "";
  display: none;
}

#message__dropdown-btn:hover {
  background: var(--bg-primary);
}

.message__dropdown-item label {
  background: transparent !important;
}
.message__dropdown-item:hover {
  background: transparent !important;
}

.csc__lookMe-popup {
  background-color: var(--bg-secondary);
  padding: 0.9375rem;
  border-radius: 5px;
}

.csc__lookMe__popup-btn button {
  border: none;
  background: var(--bg-primary);
  padding: 0.3125rem 0.625rem;
  border-radius: 4px;
  font-size: var(--font-sm);
  color: var(--text-primary);
  margin: 0 0.625rem;
}

.csc-look.look-active .csc-look-me {
  fill: var(--text-primary);
}

.csc-video.video-active {
  color: var(--text-primary);
}

.chat_parson {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  max-width: 100%;
  gap: 0.5rem;
  overflow: hidden;
}

.single__message__with-url {
  width: 16.4375rem;
  overflow: hidden;
}

.p-10px {
  padding: 0.625rem;
}

.message__loader-spinner {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--bg-secondary);
  z-index: 1;
  padding-bottom: 5px;
}

.bdr {
  border: 1px solid red;
}

.message_full_date {
  border-top: 1px solid var(--bg-info);
  position: relative;
  margin-top: 15px;
  z-index: 100;
}

.message_full_date span {
  border: 1px solid var(--bg-info);
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 0.8rem;
  position: relative;
  top: -13px;
  z-index: 1;
  background: var(--bg-secondary);
}

.popover-body {
  padding: 0.3rem 0.5rem;
  border: 0;
  font-size: 0.8em;
  /* background-color: var(--bg-info); */
  /* color: white; */
}
.message-time {
  font-size: 0.7em;
  display: block;
}

.single_emoji{
  font-size: 2.5rem;
}

.emoji__picker_message aside.EmojiPickerReact.epr-main{
  position: absolute ;
  z-index: 221;
  right: 10px;
  bottom: 40px;
}

@media (max-width: 400px) {
  aside.EmojiPickerReact.epr-main {
    width: 300px !important;
  }
}
