.VideoPlay{
  color: var(--text-primary)
}
.chennelProfileImg {
  margin-right: 8px;
  width: 2.5rem;
  height: 2.5rem;
}
.VideoPlayTextarea {
  min-height: calc(1.5em + 0.75rem + 6px) !important;
}
.firstCommentBtn {
  /* btn-lg  py-1 bg-light text-dark  w-100 fw-bold */
  font-size: 1.6rem;
  color: black;
  background-color: white;
  width: 100%;
}
@media only screen and (max-width: 500px) {
  .Description {
    padding-left: 2%;
    padding-right: 2%;
  }
}
