.product-card {
    /* height: 15.6rem;
    min-width: 13.5rem;
    max-width: 16rem; */
    background: var(--product-bg);
   border-radius: 0.2rem;

}

@media (max-width: 576px) {
  /* .product-card {
    min-width: 10rem;

    max-width: 14rem;
  } */
}

@media (max-width: 768px) and (min-width: 577px) {
  /* .product-card {
    min-width: 11rem;
    max-width: 14rem;
  } */
}

.product-card:hover .product__card__img-box img {
  transform: scale(1.1);
}

.product-card:hover .product__card__icons-box {
  bottom: 0;
}

.product__card__icons-box {
  position: absolute;
  bottom: -3.5rem;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  height: 3.5rem;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;
  color: var(--text-success);
}

.product__card__img-box{

    position: relative;
    height: 10rem;
    overflow: hidden;
}

.product__card__img-box img{
 max-height:10rem;
 /* width: 100%; */
 transition: all 0.3s;

 
}

.product__card__text-box {
  font-size: var(--font-sm);
  /* color: var(--text-primary); */
  font-weight: 500;
  /* background-color: var(--bg-info); */
}
.product_nameLine{
    max-height: 2.6rem;
    overflow: hidden;
}


.product__card-star {
  color: var(--text-success);
  font-size: 1.2rem;
}

.product__info-text {
  font-size: var(--font-sm);
}

.product-card {
  cursor: pointer;
  transition: all 0.3s;
  margin: 2%;
  color: var(--productCard-text);
}
.cartIconColor{
  fill: cadetblue;
}
/* .product-card:hover {
  box-shadow: var(--box-shadow);
} */
.product-card .product__card-star,.product-card .productStar{
  font-size: .8rem;
}
.product-card .card-footer{
  padding: .25rem!important;

}