.freelanceClient {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1.5px solid #adbacb;

  display: flex;
  align-items: center;
}

.freelanceClient__logo {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 1.5rem;
  display: flex;
  gap: 1rem;
}
[theme="dark"] .freelanceClient__logo img {
  filter: invert(1);
}

/* 992P */
@media only screen and (max-width: 62em) {
  .freelanceClient__logo img {
    height: 2rem;
  }
}
/* 768P */
@media only screen and (max-width: 48em) {
  .freelanceClient {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  .freelanceClient__logo {
    margin-left: 0;
  }
}
/* 576P */
@media only screen and (max-width: 36em) {
  .freelanceClient {
    display: none;
  }
}
