.findRide {
  margin-block: 5rem;
}

.findRide__container {
  max-width: 50rem;
  margin-inline: auto;
  border-radius: 4px;
  padding: 1.5rem;
  text-align: center;
  background-color: var(--white-to-grey);
  box-shadow: 0 4px 10px rgba(0 0 0 / 0.1);
}
.findRide__row {
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
}

.findRide__button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: var(--View-bg);
  padding: 0.5rem;
  border-radius: 4px;
  width: 12.5rem;
  cursor: pointer;
}

.findRide__button.active {
  background-color: var(--view-yellow);
}
.findRide__icon {
  background-color: var(--food-nav-bg);
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.findRide__text {
  font-size: 0.875rem;
  border: 0;
  outline: 0;
  width: 1%;
  flex-grow: 1;
  background-color: var(--View-bg);
}
.findRide__text::placeholder {
  color: var(--clr-dark);
  opacity: 0.8;
}
span.findRide__text {
  color: var(--clr-dark);
  flex-grow: 0;
  width: auto;
  background-color: transparent;
}

.findRide__timeline {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  width: 75%;
  margin-inline: auto;
  margin-bottom: 1rem;
}
.findRide__timeline--item {
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}
.findRide__timeline--item:first-child {
  justify-content: flex-start;
}
.findRide__timeline--item:last-child {
  justify-content: flex-end;
}
.findRide__timeline--item::before {
  content: "";
  height: 0.5rem;
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid var(--text-primary);
}

.findRide__timeline span {
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 50%;
  background-color: var(--View-bg);
  border: 1px solid var(--text-primary);
  position: absolute;
  transform: translateY(2px);
}

.findRide__timeline .active span {
  background-color: var(--text-primary);
  outline: 1px solid var(--text-primary);
  outline-offset: 0.2rem;
}

@media only screen and (max-width: 62em) {
  .findRide__button {
    width: 12rem;
  }
}

@media only screen and (max-width: 48em) {
  .findRide {
    margin-block: 3rem;
  }
  .findRide__row {
    flex-direction: column;
    align-items: center;
  }
  .findRide__row > div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  .findRide__row > div > p {
    margin: 0;
  }
  .findRide__timeline {
    margin-bottom: 2rem;
    width: 80%;
  }
  .findRide__button {
    width: 13rem;
  }
}
