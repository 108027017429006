.topCategory {
  background-color: var(--View-bg);
  border-radius: 5px;
  height: 10rem;
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
}
.searchInput{
    border: none;
    font-size: 14px;
}
.searchInputGroup {
    border: 1px solid white;
    padding: 2%;
    border-radius: 4px;
}

.view .profileVideoSearchInput, .profileVideoSearchLabel{
    background-color: var(--bg-primary);
}
.categoryList div{
    place-content:center;
    align-items: center;
}
.followerList{
    color: #283340;
    align-items: center;

}
.followerSingleRow {
    cursor: pointer;
    background-color: var(--View-bg);
    border-radius: 5px;
    box-shadow: 1px 1px 4px 1px var(--view-shadow);
}
.followerSingleRow img{
    border-radius: 50%;
}

.activityButtons{
    justify-content: space-around;
}
.activityButtons button{
    box-shadow: 1px 1px 4px 1px var(--view-shadow);

}