.lifeVideoOtherProfile {
  background-color: var(--bg-secondary);
}

.videoUploadBtn {
  background-color: var(--bg-secondary);
  border: none;
  font-size: var(--font-sm);
  color: var(--text-primary);
  width: 100%;
  height: 2.5rem;
  border-radius: 4px;
  transition: 0.3s;
}

.videoUploadBtn:hover {
  color: var(--text-success);
}
.videoUploadBtn svg path {
  transition: 0.3s;
}
.videoUploadBtn:hover svg path {
  fill: var(--text-success);
}

.profileVideoSearchInput:focus {
  background: var(--bg-primary);
  border: none;
  color: var(--text-primary);
  box-shadow: none;
  outline: none;
}

