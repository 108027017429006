@media only screen and (max-width: 768px) {
    /* tablet  + mobile */
    .boxButton{
        min-height: 2rem;
        text-align: center;
        align-items: center;
    }
  }
  @media only screen and (max-width: 500px) {
    /* mobile  */
    .boxButton{
        min-height: 3rem;
        text-align: center;
        align-items: center;
    }
  }


  .right__col__scroll-div{
    height: 100vh;
  }

  .right__col__scroll-div::-webkit-scrollbar {
    width: 5px !important;
  }
