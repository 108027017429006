.profile-nav-item {
  display: block;
  margin: 1rem 0;
  transition: 200ms ease-in-out;
  font-size: var(--font-sm);
}

.profile-nav-item:hover {
  color: var(--text-success);
}

.profile-nav-item-active {
  color: var(--text-success);
}
#profile-myOrder-Dropdown {
  background-color: transparent;
}

#profile-myOrder-Dropdown .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  width: 2rem;
}

#profile-myOrder-Dropdown .accordion-button {
  background-color: transparent;
  color: var(--text-primary);
  border: none;
  padding: 0;
  max-width: 10rem;
  margin: 0;
  font-size: var(--font-sm);
}

#profile-myOrder-Dropdown .accordion-body {
  border: none;
  border-bottom: 1px solid #c2c2c23a;
  border-radius: 0;
  margin-top: 0rem;
  margin-left: 1rem;
  padding: 0 0.3rem;
  background: transparent;
}

#profile-myOrder-Dropdown .accordion-button:hover {
  color: var(--text-success);
}

#profile-myOrder-Dropdown .accordion-item,
#profile-myOrder-Dropdown .accordion-collapse {
  background-color: transparent;
}
