.vehicleOptions {
  margin-top: 2rem;
  max-width: 50rem;
  margin-inline: auto;
  border-radius: 4px;
  padding: 1.5rem;
  background-color: var(--View-bg);
  box-shadow: 0 4px 10px rgba(0 0 0 / 0.1);
}
.vehicleOptions > p {
  color: var(--Body-dark);
}
.vehicle__cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
  gap: 1rem;
}

.vehicle__card {
  background-color: var(--white-to-grey);
  border-radius: 0.2rem;
  padding: 1rem;
  box-shadow: var(--box-shadow-2);
  text-align: center;
}
.vehicle__card > img {
  margin-bottom: 0.5rem;
}
.vehicle__card > p {
  margin: 0;
  font-size: 0.8rem;
  white-space: nowrap;
}
