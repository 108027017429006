.bodydarkRoundedBg {
  background-color: var(--bg-primary);
  border-radius: 3px;
}
.place-content-center {
  place-content: center;
}
.MainPart {
  margin: 0.9375rem;
  margin-top: 0;
  margin-bottom: 0;
  /* padding-left: 4%; */
  /* padding-right: 4%; */
}
.display100vh {
  height: 100vh;
}
.customContainerFluidPadding {
  padding-inline: 0%;
}
@media only screen and (max-width: 768px) {
  /* tablet  + mobile */
  .customContainerFluidPadding {
    padding-inline: 0;
  }
}

.MainMenuBar {
  width: 100%;
  z-index: 5;
  background-color: var(--nav-bg-opacity);
  display: flex;
  justify-content: space-around;
  padding: 0.5rem 0.5rem;
  border-radius: 0.3rem;
  box-shadow: 1px 1px 4px 1px var(--shadow-clr);
  gap: 0.625rem;
}

.topNavItem {
  background: transparent;
  height: 2.5rem;
  width: 100%;
  border-radius: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.topNavItem.active{
  color: var(--text-success);
  fill: var(--text-success);
}
.topNavItem.active .topNavIcon {
  color: var(--text-success);
  fill: var(--text-success);
}
.topNavItem:hover {
  background: rgba(153, 153, 153, 0.075);
}

.topNavIcon {
  fill: var(--text-info);
  color: var(--text-info);
}

.navbarActiveItem .topNavIcon {
  fill: var(--text-success) !important;
  color: var(--text-success) !important;
}
.navbarHomeActiveItem .topNavIcon {
  fill: var(--text-success);
}

.NotificationTypeButton {
  background-color: var(--bg-secondary);
  display: flex;
  justify-content: center;
  padding: 5px;
  border-radius: 0.625rem;
  box-shadow: 1px 1px 4px 1px var(--shadow-clr);
  margin-top: 1.375rem;
  border: 2px solid var(--border-secondary);
  transition: all 0.6s;
}

.NotificationTypeButton:hover {
  background-color: transparent;
  transform: scale(1.03);
  cursor: pointer;
}
.NotificationTypeIcon {
  width: 1.875rem;
  height: 1.5625rem;
  border-radius: 50%;
  transition: all 0.6s;
  display: flex;
  /* align-items: center; */
}
a {
  text-decoration: none;
  color: var(--text-primary);
}

@media only screen and (max-width: 500px) {
  /* mobile  */
  .MainPart {
    margin: 0.625rem 0;
    margin-top: 0;
    margin-bottom: 0;
    /* padding-left: 0.5%; */
    /* padding-right: 0.5%; */
  }
  .px-md-sm-1 {
    padding-left: 1%;
    padding-right: 1%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 768px) {
  /* tablet  */
  .px-md-sm-1 {
    padding-left: 1%;
    padding-right: 1%;
  }
}
@media only screen and (min-width: 500px) and (max-width: 1023px) {
  .MainPart {
    margin: 0.625rem 0;
    margin-top: 0;
    margin-bottom: 0;
    /* padding-left: 0.5%; */
    /* padding-right: 0.5%; */
  }
}

.global__search-box {
  position: sticky;
  top: 57px;
  background-color: var(--bg-secondary);
}

.middleStickyNavBar {
  position: sticky;
  top: 1px;
}
.my-mt {
  margin-top: 1.375rem;
}

/* middle scroll  */
.middleContainer {
  /* height: 48vh; */
  height: 100vh;
}

/* storepost */
.storePostMain {
  background-color: var(--bg-secondary);
  border-radius: 0.625rem;
  box-shadow: 1px 1px 4px 1px var(--shadow-clr);
  cursor: pointer;
}
.storePostMain .MessageInput {
  border-radius: 0.625rem;
}
.storePostIcon {
  width: 1.5625rem;
}

.borderLeft {
  border-left: 1px solid var(--border-primary);
}
@media (max-width: 20em) {
  .borderLeft {
    border-left: 0;
  }
  .postIconText{
    display: none;
  }
}
/* post Modal  */
#PostModalPopup .modal-content {
  background-color: var(--bg-secondary);
  min-height: 17rem;
}

.trans-bg .modal-content{
  background-color: var( --nav-transparent-bg) !important;
}
#PostModalPopup .spinner-border {
  border-color: var(--border-primary);
}
#PostModalPopup .form-check-input {
  background-color: var(--bg-gray);
}
#PostModalPopup .form-check-input:active, #PostModalPopup .form-check-input:focus {
  box-shadow: var(--hover-box-shadow);
  border-color: var(--text-success);
}
#PostModalPopup .form-check-input:checked {
  background-color: var(--text-success);
  border-color: var(--text-success);
}
#PostModalPopup {
  color: var(--text-primary);
  border-color: rgb(68, 61, 61);
}
#PostModalPopup .modal-title {
  margin-left: auto;
}
#PostModalPopup .modal-body {
  min-height: 11rem;
}

.endBody {
  background: #373b3e;
  padding: 0.6875rem;
  border-radius: 11px;
}
.postFileInput {
  opacity: 0;
  z-index: 2000;
  cursor: pointer;
}
.fileButtonContainer {
  position: relative;
}
.fileButtonContainer img {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
/* popup for post  */
.popupFiles {
  width: -webkit-fill-available;
  max-height: 10rem;
  height: 100%;
  /* padding-bottom: 6%;
padding-right: 5%; */
}
.fileContainerMain {
  background-color: black;
  padding: 3% 1%;
  border-radius: 2%;
}
.customCross {
  position: absolute;
  top: 2%;
  right: 2%;
}
/* //----------- */
.LookMeTypeButton {
  /* background-color: var(--bg-secondary); */
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
  transition: all 0.3s;
  backface-visibility: hidden;
}
.leftCol .LookMeTypeButton,
.MiddleLeftCol .LookMeTypeButton {
  text-align: -webkit-center;
}
.rightCol .LookMeTypeButton h6 {
  text-align-last: center;
}
.LookMeTypeButton:hover {
  background-color: transparent;
  transform: scale(1.02);
  cursor: pointer;
  color: var(--text-success);
}
.LookMeTypeButton:hover svg {
  transform: scale(1);
  color: var(--text-info);
}
.LookMeButtonTypeIcon {
  width: 3.125rem;

  border-radius: 50%;
  transition: all 0.6s;
  display: flex;
  /* align-items: center; */
}

.customCross{
  background-color: #fff;
  cursor: pointer;
}

.customCross:hover{
  background-color: var(--text-success) !important;
  
}

html[theme=dark] .customCross{
  background-color: #000000cc;
}