

.placeholder-square{
    width: 4rem;
    background: transparent;
    height: 4rem;
    margin-top: 5px;
}

.job-skeleton-content{
    display: flex;
    gap: 10px;

}

.job-skeleton-content-text{
    flex: 1;
}

.job-skeleton{
    background-color: var(--bg-primary);
    padding: 14px;
    border-radius: 2px;
}