.searchedFreelancer__switches {
  display: flex;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.searchedFreelancer__list {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}

/* 992P */
@media only screen and (max-width: 62em) {
  .searchedFreelancer__list {
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  }
}

/* 768px */
@media (max-width: 48em) {
  .searchedFreelancer__switches {
    gap: 0.5rem;
    display: grid;
    grid-template-columns: max-content max-content max-content max-content;
  }
}

/* 576px */
@media (max-width: 36em) {
  .searchedFreelancer__switches > .searchedFreelancer__count {
    grid-column: 1 / -1;
  }
}

/* 400P */
@media only screen and (max-width: 25em) {
  .searchedFreelancer__list {
    margin-top: 1rem;
  }
}
