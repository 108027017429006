.bestTimeBanner {
  background-color: var(--bg-secondary);
  padding: 1.25rem;
}


.bestTimeFollowBtn,
.bestTimeBellBtn {
  padding: .2rem .5rem;
  /* width: 5.625rem; */
  /* height: 1.875; */
  border-radius: 4px;
  color: var(--text-primary);
  font-size: var(--font-sm);
  font-family: "Poppins", sans-serif;
  transition: 0.3s;
}

.bestTimeBellBtn {
  background-color: transparent;
  border: 1px solid var(--border-primary);
}

.bestTimeFollowBtn {
  border: none;
  background-color: var(--bg-primary);
}

.bestTimeFollowBtn:hover,
.bestTimeBellBtn:hover {
  color: var(--text-success);
}

.bestTimeProfileInfoDivOne {
  border-right: 1px solid var(--border-primary);
  padding-right: 0.625rem;
}

.bestTimeProfileInfoDivTwo {
  padding-left: 0.625rem;
}

.bestTimeProfileInfoItem {
  margin: 0px;
  font-size: var(--font-xs);
}

.bestTimeProfileInfoItem span:first-child {
  margin-right: 0.625rem;
}
.bestTimeProfileInfoItem span:nth-child(2) {
  color: var(--text-success);
}

.bestTimeProfileVideo {
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bestTimeProfileName {
  transition: 0.3s;
}

.bestTimeProfileName:hover {
  color: var(--text-success);
}
