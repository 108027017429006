.bestTimeView {
  background-color: var(--bg-secondary);
  max-width: 25rem;
  margin-inline: auto;
}

.bestVideoControlInfo {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
  width: 3.75rem;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  align-items: flex-end;
}

.bestTimeViewInfo {
  display: block;
  font-size: var(--font-xs);
  text-align: center;
}
.bestTimeViewIcon:active {
  transform: scale(1.1);
}

.bestTimeViewIcon {
  display: block;
  font-size: 1.375rem;
  margin-bottom: 0.3125rem;
  cursor: pointer;
  transition: 0.2s;
}

.bestTimeInfoShare,
.bestTimeInfoComment,
.bestTimeInfoLike {
  transition: 0.3s;
}
.bestTimeInfoShare:hover,
.bestTimeInfoComment:hover,
.bestTimeInfoLike:hover {
  color: var(--text-success);
}

.bestTimeInfoThreeDots {
  cursor: pointer;
}

.bestTimeControlLeft,
.bestTimeControlRight {
  background: var(--bg-primary);
  border: none;
  color: var(--text-primary);
  transition: 0.3s;
}

.bestTimeControlLeft:hover,
.bestTimeControlRight:hover {
  color: var(--text-success);
}

.best-time-view-box {
  /* height: calc(100vh - 9.375rem); */
}


@media screen and (max-width: 568px) {
  .bestVideoControlInfo {
    right: 0px;
  }
}
