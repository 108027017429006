.creative__modal__bankInfo {
  display: inline-grid;
  grid-template-columns: max-content 1fr;
  column-gap: 1rem;
  row-gap: 0.5rem;
}

.otp__input {
  display: flex;
  justify-content: center;
  padding-inline: 0.5rem;
  gap: 1rem;
  flex-wrap: wrap;
}
.otp__input > input {
  --size: 2.3rem;
  height: var(--size);
  width: var(--size);
  text-align: center;
  border: 1px solid #adbacb;
  border-radius: 0.3rem;
}

/* 400PX */
@media (max-width: 25em) {
  .otp__input {
    gap: 0.5rem;
  }
  .otp__input > input {
    --size: 2rem;
  }
}

/* 300PX */
@media (max-width: 18.75em) {
  .creative__modal__bankInfo {
    grid-template-columns: 1fr;
    row-gap: 0;
  }
}
