.companyProfileBtn {
  border: none;
  padding: 0.625rem 0.9375rem;
  background-color: var(--bg-success);
  margin: 0.625rem;
  border-radius: 5px;
  transition: 0.4s;
  color: var(--clr-dark);
  font-weight: 500;
}

.companyProfileBtn:hover {
 box-shadow: var(--hover-box-shadow);
}
