.postedJobTitle {
  background-color: var(--bg-secondary);
  padding: 0.875rem 0.625rem;
  border-radius: 5px;
}

.posteditemContainer {
  background: var(--bg-primary);
  width: 100%;
  margin: 1rem 0;
  padding: 0.675rem;
}

.postedJobsingleItem {
  display: flex;
  gap: 0.9375rem;
  align-items: center;
}

.postedJobsingleItemImg img {
  height: 5rem;
  width: 5rem;
}

.postdjobsLinkBtn {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: flex-end;
}

.postdjobsLinkBtn button,
.postdjobsLinkBtn a {
  border: none;
  background-color: var(--bg-secondary);
  padding: 0.375rem 0.75rem;
  color: var(--text-primary);
  border-radius: 5px;
  transition: 0.3s;
  font-size: var(--font-sm);
}
.postdjobsLinkBtn button:hover,
.postdjobsLinkBtn a:hover {
  color: var(--text-success);
}

.postedJobsingleItemText span {
  display: block;
}

.postedJobsingleItemText a {
  color: inherit;
  transition: 0.3s;
}

.postedJobsingleItemText a:hover {
  color: var(--text-success);
  text-decoration: underline;
}
