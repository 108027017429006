.wallet {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  gap: 1rem;
}

.wallet-buttons {
  background-color: var(--light-dark-to-grey);
  padding-bottom: 2rem;
  border-radius: 0.3rem;
  overflow: hidden;
}
.wallet-buttons__title {
  margin-bottom: 2rem;
  font-size: 1.1rem;
  text-align: center;
  background-color: #a3b1c1;
  padding: 0.5rem;
  color: var(--text-secondary);
}
.wallet-button {
  display: block;
  padding: 0.2rem 1rem;
  border-bottom: 1px solid #a3b1c1;
}
.wallet-button.active {
  color: var(--text-success);
}

/* 992px */
@media (max-width: 62em) {
  .wallet {
    grid-template-columns: repeat(auto-fit,minmax(14rem,1fr));
  }
}

/* 768px */
@media (max-width: 48em) {
  .wallet {
    grid-template-columns: 1fr;
  }
}
