.updateProfilePage{
    max-width: 37.5rem;
    margin: 0px auto;
    padding: 0.625rem;
    position: relative;
}
.updateProfileTitle{
    color: var(--text-primary);
}
#calendar{
  margin: 0.3125rem auto;
}
.updateProfilePage small{
    color:var(--text-info)
}