.previousWork__list {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 1fr));
}

/* 576px */
@media (max-width: 36em) {
  .previousWork__list {
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  }
}
