.profileCloseBtn{
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border-radius: 4px;
    border: none;
}

