.input-password-input{
    border: 1px solid var(--border-primary);
    border-radius: 0.25rem;
    transition: 200ms ease-in-out;
    z-index: 11;
}

.input-password-text{
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border: none;
    cursor: pointer;
}


.input-password-input input{
    border: none;
}
.input-password-input input:focus{
    border: none;
    box-shadow: none;
}

 .input-password-input:focus-within{
    border-color: var(--border-success) !important;
    box-shadow: var(--hover-box-shadow);
}