.creative__notifications {
  border: 1px solid #adbacb;
  border-radius: 0.3rem;
  padding: 0.7rem;
}

.creative__notifications__item {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  gap: 2rem;
}
.creative__notifications__item:not(:last-child) {
  margin-bottom: 1.5rem;
}
.creative__notifications__item__img {
  --size: 4.5rem;
  height: var(--size);
  width: var(--size);
  object-fit: cover;
  border-radius: 50%;
}

/* 576px */
@media (max-width: 36em) {
  .creative__notifications__item {
    gap: 0.7rem;
  }
  .creative__notifications__item__img {
    --size: 3rem;
  }
}
/* 300px */
@media (max-width: 18.75em) {
  .creative__notifications__item__img {
    width: 0;
  }
}
