.jobCategoriesImgDiv img {
  width: 3rem;
  height: 3rem;
}
.categoriesItemDiv {
  background-color: var(--bg-secondary);
  width: 9.375rem;
  padding: 0.675rem;
  border-radius: 0.3125rem;
}
.categoriesItemDiv .jobPostTitleText {
  margin-top: 0.675rem;
}
.jobCategoriesImgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.singleCategoriesContainer {
  background: var(--bg-primary);
  padding: 0.675rem;
  border-radius: 0.25rem;
  box-shadow: var(--box-shadow);
}

.jobCategoriesItemBtn {
  display: flex;
  justify-content: center;
}

.jobCategoriesItemBtn a,
.jobCategoriesItemBtn button {
  font-size: 0.875rem;
  margin: 0 1rem;
  padding: 0.375rem 1rem;
  background-color: var(--bg-secondary);
  border-radius: 0.375rem;
  transition: all 0.3s ease;
  width: 22%;
  text-align: center;
}

.jobDetailsBtn a,
.jobDetailsBtn button {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  border: none;
}

.jobDetailsBtn button:disabled {
  cursor: not-allowed;
  color: var(--text-success);
}

.jobCategoriesItemBtn a:hover,
.jobCategoriesItemBtn button:hover {
  color: var(--text-success);
}



.jobDetailsProfile img {
  width: 100%;
  height: 100%;
}

.job-details .jobDetailsCover {
  position: relative;
  max-height: 13rem;
  overflow: hidden;
  border-radius: 0.45rem;
}

.jobInformation {
  background-color: var(--bg-secondary);
  padding: 0.75rem;
}

.jobInformation span {
  margin-bottom: 0.75rem;
  display: inline-block;
  color: var(--text-primary);
}

.singleCategoriesMain {
  width: 9.375rem;
}

.job-information p {
  font-size: 0.95rem;
  color: var(--text-primary);
  margin: 0;
}

@media (min-width: 576px) and (max-width: 767px) {
  .jobDetailsProfile {
    height: 4.325rem;
    width: 4.325rem;
  }

  
}

@media (max-width:576px) {
    .jobCategoriesItemBtn a,
  .jobCategoriesItemBtn button {
    margin: 0 0.5rem;
    padding: 0.375rem 0.5rem;
  }
    
}


@media print {
  .noprint {
    display: none;
  }
}
