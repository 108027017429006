.transferHistory {
}
.transferHistory__card {
  position: relative;
  border: 1px solid #adbacb;
  border-radius: 0.3rem;
  padding: 0.5rem;
}
.transferHistory__card:not(:last-child) {
  margin-bottom: 1rem;
}
.transferHistory__card__badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--text-info);
  color: #fff;
  padding: 0.3rem 0.5rem;
  border-bottom-left-radius: 0.3rem;
}
.transferHistory__card__profile {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 1.5rem;
  align-items: center;
}
.transferHistory__card__img {
  --size: 4rem;
  height: var(--size);
  width: var(--size);
  object-fit: cover;
  border-radius: 50%;
}

.transferHistory__card__table {
  width: 100%;
  margin-top: 1rem;
  border: 1px solid #adbacb;
  border-radius: 0.3rem;
}
.transferHistory__card__table td {
  padding: 0.2rem;
  border-bottom: 1px solid #adbacb;
  border-right: 1px solid #adbacb;
}

/* 400 */
@media (max-width: 25em) {
  .transferHistory__card__badge {
    display: none;
  }
}
