.receivedDelivery {
  padding: 1.5rem;
  background-color: var(--nav-bg-opacity);
  border-radius: 0.5rem;

  display: grid;
  grid-template-columns: 1fr 2px 1.5fr;
  gap: 1rem;
}
.receivedDelivery__divider {
  height: 100%;
  width: 0.15rem;
  border-radius: 1rem;
  background-color: #898785;
}
.receivedDelivery__confirmation {
  text-align: center;
}
.receivedDelivery__qrcode {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 0.3rem;

  display: flex;
  gap: 3rem;
  justify-content: center;
}

.receivedDelivery__qrmanual {
  display: flex;
  margin-top: 1rem;
}
.receivedDelivery__qrmanual__title {
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  margin: 0;
  white-space: nowrap;
}
.receivedDelivery__qrmanual__code {
  padding: 0.5rem 1rem;
  flex-grow: 1;
  background-color: rgba(255, 255, 255, 0.507);
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  margin: 0;
}

/* 768P */
@media (max-width: 48em) {
  .receivedDelivery {
    grid-template-columns: 1fr;
  }
}

/* 400P */
@media (max-width: 25em) {
  .receivedDelivery{
    padding: .5rem;
  }
  .receivedDelivery__qrcode {
    flex-direction: column;
    gap: 1.5rem;
  }
  .receivedDelivery__qrmanual {
    flex-direction: column;
    gap: .5rem;
  }
  .receivedDelivery__qrmanual__title {
    border-radius: 0.2rem;
    padding: .5rem;
    text-align: center;
  }
  .receivedDelivery__qrmanual__code {
    border-radius: 0.2rem;
    padding: .5rem;
    text-align: center;
  }
}
