.viewTopBar {
  width: 100%;
  z-index: 5;
  background-color: var(--View-bg);
  box-shadow: var(--box-shadow);
  display: flex;
  justify-content: space-around;
  padding: 8.5px 8px;
  border-radius: 10px;
  gap: 10px;
  
}

.cardsContainer {
  padding-left: 2%;
  padding-right: 2%;
}
.chennelImg {
  margin-right: 8px;
  width: 2.7rem;
  height: 2.7rem;
}

.viewCount {
  margin-right: 6%;
}
/* /////////////////////
 */
/* notification.jsx */
.notiImg {
  max-height: 5.5rem;
}
@media only screen and (max-width: 500px) {
  /* mobile  */
  .chennelImg {
    width: 2.5rem;
    height: 2.5rem;
  }
}

a:hover{
  color: var(--text-primary);
}