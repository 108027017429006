
.nav-profile-dropdown .dropdown-toggle::after{
content: none;
display: none;
}

.nav-profile-dropdown .dropdown-toggle{
    background: transparent;
    border: none;
    padding-bottom: 0;
    padding-top: 0.2rem;
}


.nav-profile-dropdown .dropdown-menu{
    background-color: var(--sidebar-bg);
    color: var(--text-primary);
    border-color: var(--sidebar-bg);
}

.nav-profile-dropdown .dropdown-menu .dropdown-item{
    color: var(--text-primary);
    font-size: var(--font-sm);
}

.nav-profile-dropdown .dropdown-item {
    margin-bottom: 0.5rem;
}

.nav-profile-dropdown .dropdown-item a{
transition: color 300ms ease-in-out ;
}
.nav-profile-dropdown .dropdown-item:hover a{
color: var(--text-success);
}
.nav-profile-dropdown .dropdown-item:hover a .icon-clr{
color: var(--text-success);
}

.jobTopBar{
    width: 100%;
    z-index: 5;
    background-color: var(--View-bg);
    box-shadow: var(--box-shadow);
    display: flex;
    justify-content: space-around;
    padding: 8.5px 8px;
    border-radius: 10px;
    gap: 10px;
}