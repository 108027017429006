@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;400;500;600;700&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,400;1,500&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap");

:root {
  --Body-dark: #1d232b; /*done*/
  --Logo-yellow: #f9a31a;
  --title-text: #f2f2f2;
  --stock-color: #415b71;
  --header-footer: #283340;
  --Logo-title: #6984a5;
  --eco-main-bg: #354455;
  --eco-product-bg: #e5e5e5;
  --text-clr: #ffffff;

  --food-nav-bg: #d9d9d9;
  --View-bg: #f0f0f0;
  --view-yellow: #f1a350;
  --view-shadow: #c9bfbf;
  --FoodLogo-icon: #6a83a3;
  --Viewbox-shadow: 1px 1px 4px 1px var(--view-shadow);

  --font-md: 1rem;
  --font-sm: 0.875rem;
  --font-xs: 0.75rem;

  --border-primary: gray; /* dark =  */

  --bg-success: #f9a31a; /* --Logo-yellow*/
  --bg-blue: #6984a5;
  --bg-danger: #e53e1a;
  --bg-gray: gray;
  --product-bg: #e5e5e5;

  --text-info: #6984a5; /* --Logo-title ---  */
  --text-success: #f9a31a; /* --Logo-yellow */
  --text-danger: #e53e1a;

  --border-secondary: #415b71; /* --stock-color */
  --border-success: #f9a31a;
  --light-gray: #efefef;

  --hover-box-shadow: 0 0 0 0.2rem rgb(249 163 26 / 25%);
  --box-shadow: 3px 4px 10px var(--shadow-clr);
  --box-shadow-2: 0px 2px 8px #15141626;
  --placeholder-color: silver;
  --clr-white: white;
  --clr-dark: black;
  --swiper-navigation-size: 2rem;
  --UbizzIconColor: "transparent";

  /* dynamic variable == variables gonna change on dark mode */
  --bg-primary: #fff; /* dark = #1d232b  */
  --bg-secondary: #f0f0f0; /* dark = #283340 */
  --bg-light-gray: #f0f0f0af; /* dark = #283340af */
  --bg-info: #e5e5e5; /*  dark = #415b71 */
  --text-primary: #1d232b; /* dark = #d7d7d7 */
  --productCard-text: #3b4858; /* dark = #d7d7d7 */
  --text-secondary: #283340; /* dark = #f2f2f2  */
  --shadow-clr: #f0f0f0; /* dark = #415b71 */
  --nav-bg: #d9d9d9; /* dark = #283340  */
  --nav-bg-opacity: #d9d9d9c9; /* dark = #283340d7  */
  --sidebar-bg: #fff; /* dark = #354455e6 */
  --icon-clr: #6984a5; /* dark = #fff */
  --nav-transparent-bg: #d9d9d9e6; /* dark = #283340e6  */
  --white-to-grey: #fff; /* dark = #3f4246 */
  --dark-to-white: #1d232b; /* dark = #fff */
  --light-dark-to-grey: #e5e9ed; /* dark = #283340 */
  --light-grey: #F3F3F3 ;/* #334251 */
  --bg-secondary-to-success:#6984a5;  /* #f9a31a */
  /* end of dynamic variable  */ 

}

/* dark theme variable ===
notes. add variable name only if color gonna change on dark theme. otherwise no need to add  */

[theme="dark"]:root {
  --UbizzIconColor: rgb(62, 71, 86);
  --bg-primary: #1d232b; /* light = #fff */
  --bg-secondary: #283340; /* light = #f0f0f0 */
  --bg-light-gray: #283340af; /* light = #f0f0f0af */
  --bg-info: #415b71; /* light = #e5e5e5 */
  --text-primary: #d7d7d7; /* light = #1d232b */
  --productCard-text: #d7d7d7; /* light = #3b4858 */
  --text-secondary: #f2f2f2; /* light = #283340 */
  --shadow-clr: #415b71; /* light = #f0f0f0 */
  --nav-bg: #283340; /* light = #d9d9d9 */
  --nav-bg-opacity: #283340d7; /* light = #d9d9d9c9 */
  --sidebar-bg: #354455e6; /* light = #fff */
  --icon-clr: white; /* light = #6984a5 */
  --nav-transparent-bg: #283340e6; /* light = #d9d9d9e6 */
  --white-to-grey: #3f4246; /* light = #fff */
  --dark-to-white: #fff; /* light = #1d232b */
  --light-dark-to-grey: #283340; /* light = #e5e9ed */
  --light-grey: #334251 ; /* #F3F3F3 */
  --bg-secondary-to-success: #f9a31a; /* #6984a5; */
}
