.ataGlance {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  margin-bottom: 1.5rem;
}
.ataGlance__card {
  background-color: var(--light-dark-to-grey);
  border-radius: 0.3rem;
  padding: 1.5rem 0.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ataGlance__card__title {
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  white-space: nowrap;
}
.ataGlance__card__sub {
  font-weight: 500;
  opacity: 0.9;
  margin-bottom: 0;
}

/* 768px */
@media (max-width: 48em) {
  .ataGlance {
    display: none;
  }
}
