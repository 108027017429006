.smallVideoPlayerOverlay{
    background:  rgba(0, 0, 0, 0);
    position: absolute;
    inset: 0;
    transition: 0.4s;
    display: block;
    overflow: hidden;
}

.smallVideoCardIcon{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 222;
    top: 0;
    cursor: pointer;
}

