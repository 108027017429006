.projectCandidate {
  border-radius: 0.3rem;
  border: 1px solid #adbacb;
  padding: 1rem;
  margin-bottom: 1.5rem;
}
.projectCandidate:last-child {
  margin-bottom: 0;
}
.projectCandidate__top {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  gap: 1.5rem;
}
.projectCandidate__user__img {
  --size: 4.5rem;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  object-fit: cover;
}
.projectCandidate__user {
  margin-bottom: 1rem;
}
.projectCandidate__user__name {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 1.1rem;
}
.projectCandidate__user__title {
  margin-bottom: 0;
}
.projectCandidate__user__rating {
  font-weight: 500;
  margin-bottom: 0;
}
.projectCandidate__user__hourly {
  margin-bottom: 0;
}
.projectCandidate__user__rating svg {
  margin-right: 0.5rem;
  color: var(--text-success);
}
.projectCandidate__user__rating span {
  opacity: 0.85;
  font-size: 0.95rem;
}

/* 576px */
@media (max-width: 36rem) {
  .projectCandidate__top {
    grid-template-columns: max-content 1fr;
    row-gap: 0;
  }
  .projectCandidate__top > div:nth-child(3) {
    grid-column: 1 / -1;
  }
}
