.QR-code-box {
  width: 18rem;
  height: 18rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.QR-code-box .corner {
  width: 2rem;
  height: 2rem;
  display: block;
  border: 2px solid var(--border-success);
}

.QR-code-box .corner1 {
  position: absolute;
  border-left-color: transparent;
  border-bottom-color: transparent;
  top: 0;
  right: 0;
}
.QR-code-box .corner2 {
  position: absolute;
  border-top-color: transparent;
  border-right-color: transparent;
  bottom: 0;
  left: 0;
}
.QR-code-box .corner3 {
  position: absolute;
  border-top-color: transparent;
  border-left-color: transparent;
  bottom: 0;
  right: 0;
}
.QR-code-box .corner4 {
  border-right-color: transparent;
  border-bottom-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.QR-code-img-box {
  width: 14rem;
  height: 14rem;
}

.QR-code-img-box img {
  width: 100%;
  height: 100%;
}

.QR-code-box .QR-code-text {
  position: absolute;
  margin-bottom: 0;
  left: 29%;
  bottom: -0.5rem;
  letter-spacing: 2px;
}

.QR-code-otp {
  color: var(--clr-dark);
  background-color: var(--food-nav-bg);
  border-radius: 0.2rem;
  margin: 0.5rem;
  padding: 0.3rem 0.8rem;
}
