.create-store-nav{
    display: flex;
    gap: 0.75rem;
}

.create-store-nav a{
    font-size: var(--font-sm);
    color: var(--text-primary);
    transition: 200ms ease-in-out;
    font-weight: 600;
    padding: 0.22rem 0.45rem;
    border-radius: 0.22rem;
}

.create-store-nav a:hover{
    color:var(--text-success)
}

.create-store-nav-item-active{
    background: var(--bg-success);
    color: var(--clr-dark) !important;
}

.seller-reg-input-label{
    border: 1px solid var(--border-secondary);
    text-align: center !important;
   
}

.seller-reg-input-label.col-form-label{
    padding-bottom: 0;
}



.store-reg-phone-input:focus-within{
    border-color: var(--text-success)  !important;
    box-shadow: var(--hover-box-shadow);
}

.store-reg-phone-label{
    border: 1px solid var(--border-secondary);
    text-align: center !important;
}

.create-store-main label{
    font-size:var(--font-sm);
}