.bestTime__update-modal .modal-content {
  background-color: var(--bg-secondary);
  color: var(sssmary);
}

.bestTime__update-modal .modal-header {
  border: none;
}

.bestTime__update-modal .modal-footer {
  border: none;
}

.bestTime__update-modal .modal-content textarea {
  box-shadow: none;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-secondary);
}

.bestTime__update-modal .modal-content textarea:focus {
  box-shadow: var(--hover-box-shadow);
  border-color: var(--border-success);
}

.story__status-form input:checked {
  background-color: var(--bg-success);
  border-color: var(--border-success);
}

.story__status-form input:focus {
  border-color: var(--border-success);
  box-shadow: var(--hover-box-shadow);
}
.story__status-form label {
  font-size: 0.9rem;
}

.story__status-form .form-check {
  margin-top: 0.5rem;
}
