.store__item-main {
  min-height: 15rem;
  background-color: rgba(0, 0, 0, 0.397);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat !important;
  background-blend-mode: overlay;
  border-radius: 10px;
  cursor: pointer;
  color: var(--clr-white);
}

.store__item-logo {
  max-width: 7.5rem;
  aspect-ratio: "1/1";
  border-radius: 100%;
  min-height: 7.5rem;
}
.store__item__text-box {
  padding: 0.675rem;
}
.store__item__text-box p,
.store__item__text-box h2 {
  margin: 0.33rem 0;
}

.store__item-box:hover {
  color: var(--text-primary);
}
.limitedShop .store__item-main{
  min-height: 8rem;
}
.limitedShop .store__item-logo {
  max-width: 5rem;
  min-height: 5rem;
}
.limitedShop .store__item__text-box {
  padding: 0.3rem;
}
.limitedShop .store__item__text-box p {
  margin: 0;
}

@media (max-width:576px){
 .store__item-logo{
  width:5rem;
  height:5rem;
  min-height: 5rem;
  aspect-ratio: 1/1;
 }
 .store__item__text-box{
  width: calc(100% - 5rem);
 }
 .store__item__text-box p,
.store__item__text-box h2 {
  margin: 0.05rem 0;
}
.store__item-main{
  min-height: 12rem;
}
}
@media (max-width:768px) and (min-width:577px){
 .store__item-logo{
  width:6.5rem;
  height:6.5rem;
  min-height: 6.5rem;
  aspect-ratio: 1/1;
 }
}