.dropdown-right-align {
  left: inherit !important;
  right: 0;
}
.header {
  background-color: var(--nav-bg-opacity);
  color: var(--text-primary);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 15;
  /* box-shadow: var(--box-shadow-2); */
}
a:hover{
  color: var(--text-primary);
}

.header__logo--container {
  display: flex;
  align-items: center;
}
.header__logo--text {
  margin-left: 0.5rem;
  padding-left: 0.5rem;
  border-left: 1px solid;
}
[theme="dark"] .header__logo--text {
  border-color: #fff;
}
.logo-uviom-ride {
  font-weight: 600;
  color: var(--Logo-title);
  margin: 0;
  line-height: 1;
}

.logo-uviom-ride > span {
  color: var(--Logo-yellow);
}

.header > .navbar {
  padding: 0;
  background-color: inherit;
  box-shadow: none;
  background-color: transparent;
}
.header .navbar-brand {
  padding: 0;
}

.header__nav {
  font-weight: 500;
  gap: 1rem;
  margin-left: 5rem;
  align-items: center;
  margin-right: 5rem;
}

.header__nav--link {
  padding-block: 1.25rem;
  color: var(--text-primary) !important;
}

.header__nav--link:hover {
  background-color: var(--view-yellow);
}
.header__icon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.header__icon img {
  height: 1.25rem;
  margin: 0.4rem;
  cursor: pointer;
  /* filter: invert(100%); */
}
[theme="dark"] .header__icon img {
  filter: invert(100%);
}

.ride__offcanvas {
  opacity: 0.85;
  padding: 1rem 1.25rem;
}

.ride__offcanvas.offcanvas-end {
  width: 15rem;
  background-color: var(--sidebar-bg);
}
[theme="dark"] .ride__offcanvas .offcanvas-header .btn-close {
  filter: invert(1);
}
.nav__burger {
  border: 0;
  background-color: transparent;
  display: none;
  height: 2.5rem;
  width: 2.5rem;
  font-size: 1.25rem;
}

[theme="dark"] .nav__burger {
  filter: invert(1);
}

.burger-icon {
  display: inline-block;
  height: 2px;
  width: 1.7rem;
  background-color: #333;
  position: relative;
  margin-top: 1.18rem;
}

.burger-icon::before,
.burger-icon::after {
  content: "";
  display: inline-block;
  height: 2px;
  width: 1.7rem;
  background-color: #333;
  position: absolute;
  left: 0;
  transition: all 0.2s;
}

.burger-icon::before {
  top: -0.5rem;
}
.burger-icon::after {
  top: 0.5rem;
}

.nav__burger.active .burger-icon {
  background-color: transparent;
}
.nav__burger.active .burger-icon::before {
  top: 0;
  transform: rotate(135deg);
}
.nav__burger.active .burger-icon::after {
  top: 0;
  transform: rotate(-135deg);
}

.ride__offcanvas--menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ride__offcanvas--menu > li > * {
  text-decoration: none;
  border: 0;
  color: inherit;
  padding: 0.3rem 0.5rem;
  width: 0;
  display: block;
  transition: 0.3s linear;
}
.ride__offcanvas--menu > li > *:hover {
  width: 100%;
  color: var(--bg-primary);
  background-color: var(--bg-success);
}

@media only screen and (max-width: 62em) {
  .header__nav {
    margin-left: 1rem;
    gap: 0.5rem;
  }
}
@media only screen and (max-width: 48em) {
  .header {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
  .nav__burger {
    display: inline-block;
  }
}
@media only screen and (max-width: 36em) {
  .navbar-brand img {
    height: 2rem;
  }
  .header__icon > img {
    height: 1rem;
  }

  .burger-icon,
  .burger-icon::before,
  .burger-icon::after {
    width: 1.4rem;
  }
}
@media only screen and (max-width: 25em) {
  .logo-slogan {
    display: none;
  }
  .header__icon {
    gap: 0;
  }
}




/* 350p */
@media only screen and (max-width: 21.875em) {
  .logo-uviom-ride > bdo {
    display: none;
  }
}
