.jobApplyTitle {
  background-color: var(--bg-secondary);
  padding: 0.75rem;
  border-radius: 4px;
  margin-bottom: 1.25rem;
  transform: translateX(-5px);
}

.jobApplyInput {
  border: none;
  background-color: var(--bg-secondary);
  transition: 0.03s ease;
  border: 1px solid transparent;
  color: var(--text-primary) !important;
  font-size: var(--font-sm);
}
.jobApplyInput:focus {
  background-color: var(--bg-secondary);
  border-color: var(--border-primary);
  outline: none;
  box-shadow: none;
}

.jobApplyInputLabel {
  background-color: var(--bg-secondary);
  border-radius: 3px;
  text-align: center;
  font-size: var(--font-sm);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#jobApplyFileInput {
  width: 100%;
  padding: 0.4375rem 0.625rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background-color: var(--bg-secondary);
}

#jobApplyFileInput .file-placeholder {
  font-size: var(--font-sm);
}
#jobApplyFileInput .file-text-btn {
  font-size: var(--font-xs);
  color: var(--text-success);
}

.jobApplySubmitBtn {
  border: none;
  background-color: var(--bg-success);
  transition: 0.03s ease;
  color: var(--text-secondary);
  font-weight: bold;
  font-size: var(--font-sm);
  width: 5rem;
  height: 2.2rem;
  text-align: center;
  border-radius: 4px;
}

.jobApplySubmitBtn:hover {
  box-shadow: var(--hover-box-shadow);
}
