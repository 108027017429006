.foods__category__slider-item > div {
  width: 4.5rem;
  height: 4.5rem;
  background-color: var(--eco-product-bg);
  color: var(--text-primary);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.foods__category__slider-item > div > img {
  max-width: 4rem;
  max-height: 4rem;
}

.foods-nav-item {
  color: var(--text-secondary);
  font-weight: 500;
  transition: 200ms ease-in-out;
}

.foods-nav-item-active {
  color: var(--text-secondary);
  background-color: var(--bg-success);
  padding: 0.1rem 0.6rem;
  border-radius: 0.3rem;
}
.foods-nav-item:hover {
  color: var(--text-success);
}

.foods-nav-item.foods-nav-item-active:hover {
  color: var(--text-secondary);
}
