.contactForm .form-control {
  border: 0;
  border-radius: 0;
  padding-left: 0.5rem;
  padding-right: 0;
  border-bottom: 1px solid var(--border-primary);
  font-size: var(--font-sm);
}

.contactForm .form-control:focus {
  outline: 0;
  box-shadow: none;
}

.contactForm .form-label {
  margin-bottom: 0;
  font-size: var(--font-sm);
}
.contactForm .contactTextarea {
  border: 1px solid var(--border-primary);
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 0.5rem;
  box-shadow: none;
}
.contactForm .contactSubmit {
  border-radius: 0;
  font-size: var(--font-lg);
  border-color:var(--border-primary);
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.contact-social-icon {
  display: flex;
  color: var(--text-secondary);
}
.contact-social-icon .social-icon {
  color: var(--text-secondary);
  font-size: 1.2rem;
}
