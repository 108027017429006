

#shipping-and-billing-accordion>.accordion-item>.accordion-header> .accordion-button{
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    padding: 0rem 0 ;
    max-width: 100%;
    border: none;
    
}

#shipping-and-billing-accordion>.accordion-item>.accordion-header> .accordion-button:disabled{
    cursor: not-allowed !important;
}

#shipping-and-billing-accordion>.accordion-item>.accordion-header{
    margin: 0rem 0;
}