.food-my-profile {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  font-weight: 500;
}

.food-my-profile .profile-nav-item {
  color: var(--text-secondary);
}

.profile-nav-item:hover {
  color: var(--text-success);
}

.profile-nav-item-active {
  color: var(--text-success) !important;
}
#profile-myOrder-Dropdown {
  background-color: transparent;
}

.food-my-profile #profile-myOrder-Dropdown .accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  width: 2rem;
}
.food-my-profile #profile-myOrder-Dropdown .accordion-button:hover {
  color: var(--text-success);
}

.food-my-profile #profile-myOrder-Dropdown .accordion-button {
  transition: color 200ms ease-in-out;
  color: var(--text-secondary);
  font-size: var(--font-sm);

  font-weight: 500;
}

.food-my-profile #profile-myOrder-Dropdown .accordion-body {
  border: none;
}
