.express__billing__table__container {
  overflow-x: scroll;
}
.express__billing__table__container::-webkit-scrollbar {
  height: 3px;
}
.express__billing__table {
  width: 100%;
  text-align: center;
}

.express__billing__table thead tr {
  background-color: var(--light-dark-to-grey);
  filter: brightness(80%);
}

.express__billing__table tbody tr:nth-child(odd) {
  background-color: var(--light-dark-to-grey);
}

.express__billing__table td {
  padding: 0.5rem;
  white-space: nowrap;
}

.express__billing__table td > img {
  width: 0.8em;
  margin-left: 0.3em;
  cursor: pointer;
}
