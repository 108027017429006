.FreelanceService {
}

.FreelanceService__title {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.freelanceService__sub {
  font-size: 1.12rem;
  margin-bottom: 0.4rem;
}
.freelanceService__sub > svg {
  font-size: 1rem;
  opacity: 0.7;
  margin-right: 0.2rem;
}
.freelanceService__para {
  max-width: 50ch;
}

.freelanceService__img {
  position: relative;
}
.freelanceService__img::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  transform: translate(-0.7rem, -0.7rem);
  border: 1.5px dashed #adbacb;
}


/* 992P */
@media only screen and (max-width: 62em) {
  .freelanceService__para{
    max-width: none;
  }
  .freelanceService__img {
    display: none;
  }
}

/* 400P */
@media only screen and (max-width: 25em) {
  .FreelanceService__title{
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}