.text-align-justify {
  text-align: justify;
}
.commentavater {
  width: 2.5rem;
  border-radius: 50%;
  text-align: center;
}
.commentBorderTop {
  border-top: 1px solid var(--border-secondary);
}
.NewsFeedPostBg {
  background-color: var(--bg-secondary);
  padding: 3.125rem;
  border-radius: 4px;
  margin: 0.9315rem auto;
  position: relative;
}
.hoverEffect:hover {
  transform: scale(1.1);
}
.namehoverEffect:hover {
  /* transform: scale(1.1); */
  color: var(--text-success) !important;
}
.clickEffect:active {
  transform: translate(1%, 3%);
}

.b-0 {
  bottom: 0;
}
.swal-modal {
  background-color: var(--sidebar-bg) !important;
}
.swal-modal * {
  color: var(--text-primary) !important;
}
.w-fit-content {
  width: fit-content;
}

.swal-button {
  color: var(--clr-dark) !important;
  background-color: var(--bg-success) !important;
  border: 2px solid var(--border-success);
  font-weight: 500;
}
.swal-text {
  color: var(--text-primary) !important;
}
.logoColorText .LikeCommentIcon,
.logoColorText small {
  color: var(--text-success);
  fill: var(--text-success);
}
.NewsPhotoDiv {
  /* max-height: 38rem; */
  overflow-y: hidden;
}
.NewsPhotoDiv .rounded {
  box-shadow: 1px 1px 4px 1px var(--shadow-clr);
}
.oneImageCss {
  width: 100%;
  max-height: 38rem;
}

.oneImageCss img {
  min-height: 500px;
}

.threeImageCss {
  /* height: 50% !important; */
  width: 100% !important;
}
.multiImageCss {
  width: 50%;
  max-height: 19rem;
  height: 4873px;
}
.multiImageCss .img-fluid{
  height: inherit;
}
.MoreThenFourImages {
  position: relative;
}
.MoreThenFourImages img {
  filter: blur(2px);
}
/* .seemorePhotoBtn {
  /* position: absolute; 
} */

.NewsPhotoDiv img {
  width: 100%;
  /* border-radius: 4px; */
  /* overflow-y: hidden; */
  /* height: -webkit-fill-available; */
  /* border: 1px solid var(--border-secondary); */
  cursor: pointer;
  object-fit: cover;
}

.ProfileAndName {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.PostSectionProfile {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
  overflow: hidden;
}

/* listDropdown--------- */
.listDropdown .dropdown-menu {
  background-color: var(--bg-secondary);
  width: max-content;
}
.listDropdown .dropdown-menu a {
  color: var(--text-primary);
}
.listDropdownIcon {
  width: 1.2rem;
}
.dropdownItemContainer {
  background-color: var(--bg-info);
  max-height: 14rem;
  overflow: hidden;
}
.ListDropdownShow2 .ClickThreeDot {
  left: 3%;
  background-color: var(--bg-info);
}

.LikeCommentIcon {
  width: 1.5625rem;
  height: auto;
  padding: 1px;
}
.pointer {
  cursor: pointer;
}
.LikeCommentsharePart {
  display: flex;
  gap: 0.4rem;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-secondary);
  width: fit-content;
  padding: 0.2rem 1.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
  color: var(--text-primary);
  transition: 0.1s;
}
.LikeCommentsharePart:active {
  transform: scale(0.95);
}
.LikeCommentsharePart:active > .LikeCommentIcon {
  /* transform: scale(1.5); */
}
.LikeCommentsharePart:hover {
  background-color: var(--bg-secondary);
}
.LikeCommentShareParent {
  background-color: var(--bg-primary);
  padding: 0.93rem 0.31rem;
  border-bottom-left-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.MoreOption {
  padding: 0.9375rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;

  transition: all 0.6s;
  cursor: pointer;
}

.PostHeaderOption {
  display: flex;
  justify-content: space-between;
}
.ClickShare {
  width: 15.625rem;
  position: absolute;
  right: 0.625rem;
  bottom: 5rem;
  border-radius: 15px;
  border: 1px solid transparent;
  background-color: var(--sidebar-bg);
}
.ClickThreeDot {
  width: 15.625rem;
  position: absolute;
  top: 2px;
  right: 0px;
  border-radius: 15px;
  background-color: var(--sidebar-bg);
}
.dropDown-sm {
  width: 150px;
}

.btnHoverBorder {
  /* border: 1px solid var(--border-primary); */
  background-color: transparent;
  display: flex;
  /* justify-content: center; */
  padding: .2rem 1rem;
  border-radius: 0.625rem;
  box-shadow: 1px 1px 4px 1px var(--shadow-clr);
  margin-top: 0.3125rem;
  transition: all 0.6s;
  color: var(--text-primary);
  z-index: 222;
}
.btnHoverBorder:hover {
  /* border: 1px solid transparent; */
  background-color: var(--bg-secondary);
  cursor: pointer;
  z-index: 22200;
}

.write-comment-input {
  background-color: var(--bg-primary);
  box-shadow: none;
  width: 100%;
  border-radius: 0.2rem;
  padding: 0.3rem 0.5rem;
}
.likedUserList-header {
  color: var(--text-primary);
}

.LikedUserListModal .modal-dialog-scrollable .modal-body {
  overflow-x: inherit;
}
.lifeComModal .modal-content {
  background-color: var(--bg-secondary);
  height: 70vh;
}
.lifeComModal .spinner-border {
  border-color: var(--border-primary);
}
.lifeComModal .form-check-input {
  background-color: gray;
}
.lifeComModal * {
  color: var(--text-primary);
  border-color: rgb(68, 61, 61);
}
.lifeComModal .modal-title {
  margin-left: auto;
}
.lifeComModal .modal-body {
  min-height: 11rem;
}
.minusZIndex {
  z-index: -1;
}
.plusZIndex {
  z-index: 2000;
}
/* like end  */
/* comment  */
.ml-10 {
  margin-left: 10%;
}
.ml-20 {
  margin-left: 20%;
}
@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .NewsFeedPostBg {
    /* padding: 12px 4px; */
    padding: 0.75re;
  }
}
@media only screen and (min-width: 500px) and (max-width: 768px) {
  /* tablet  */
  .d-sm-show {
    display: unset !important;
  }
  .d-sm-hide {
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  /* mobile*/

  .multiImageCss {
    max-height: unset;
    /* height: 50%; */
    height: 12rem;
  }
  .d-xs-show {
    display: unset !important;
  }
  .d-xs-hide {
    display: none;
  }
  .px-xs-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .px-xs-1 {
    padding-left: 1%;
    padding-right: 1%;
  }
  .px-xs-3 {
    padding-left: 3%;
    padding-right: 3%;
  }
  .mx-xs-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .ListDropdownShow2 .ClickThreeDot {
    right: 80%;
    left: unset;
    top: 40%;
    width: fit-content;
    height: fit-content;
  }
  /* .text-truncate :after {
    content: "More...";
  } */
  .ClickThreeDot {
    /* dont commnt that . it was for extra small screen popup */
    /* width: 95%; */
    width: unset;
    white-space: nowrap;
  }
  .NewsFeedPostBg {
    /* padding: 12px 4px; */
    padding: 0.75rem;
  }
  /* .multiImageCss {
    max-height: 10rem;
  } */

  .NewsPhotoDiv {
    /* max-height: 20rem; */
    overflow-y: hidden;
  }
  .smallTextSize-sm {
    font-size: 0.625rem;
  }
  .LikeCommentIcon {
    width: 1.25rem;
  }
  .LikeCommentsharePart {
    /* margin-right: 3%; */
    padding: 0.2rem 0.5rem;
  }
}

/* 576px */
@media (max-width: 36em) {
  .oneImageCss img {
    min-height: 220px;
  }
}



.emoji__picker_comment aside.EmojiPickerReact.epr-main{
  position: absolute ;
  z-index: 221;
  right: 26px;
  top: 58px;
}

.comment_single_emoji{
  font-size: 2rem;
}