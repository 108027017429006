.sava__post__view-btn {
  background-color: var(--bg-secondary);
  padding: 0.25rem 0.4375rem;
  border-radius: 0.25rem;
  color: var(--text-primary);
  transition: 0.3s;
}

.sava__post__view-btn:hover {
  color: var(--text-success);
}

.save__post__three__dot-menu {
  right: 0;
  top: 2.5rem;
  background-color: var(--bg-secondary);
  padding: 0.625rem;
  z-index: 11;
  border-radius: 5px;
}

.save__post__three__dot-menu button {
  padding: 0.25rem;
  border: none;
  display: block;
  width: 100%;
  margin: 0.3125rem 0;
  color: var(--text-primary);
  background: transparent;
  transition: 0.3s;
}

.save__post__three__dot-menu button:hover {
  color: var(--text-success);
}
