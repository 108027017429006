.restaurant-card {
  background-color: var(--product-bg);
  min-width: 25rem;
  position: relative;
  color: var(--text-secondary);
}

.restaurant__card__img-box {
  width: 100%;
  aspect-ratio: 16/7;
}

.restaurant__card__img-box img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1200px) and (min-width: 992px) {
  .restaurant-card {
    min-width: 20rem;
  }
}

@media (max-width: 768px) {
  .restaurant-card {
    min-width: 18rem;
  }
}
