.corporate-contact .contact-social-icon .social-icon{
    color: var(--text-primary);
    font-size: 1.2rem;
  }

  .corporate-contact .contactForm input {
    background-color: var(--bg-secondary);
    border-bottom: 1px solid var(--border-primary);
    color: var(--text-primary);

  }

  .corporate-contact .contactForm textarea{
    border: 1px solid var(--border-primary);
    background-color: var(--bg-secondary);
    color: var(--text-primary);

  }

  .corporate-contact{
    background-color: var(--bg-secondary);
  }