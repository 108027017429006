.freelancerSort {
  padding: 2rem 0;
  display: flex;
  align-items: center;
  gap: 2rem;
  justify-content: space-between;
}
.freelancerSort__left {
}
.freelancerSort__title {
  font-size: 1.7rem;
}
.freelancerSort__sub {
  margin-bottom: 0;
}

.freelancerSort__right {
  display: flex;
  gap: 1.5rem;
}

/* 992px */
@media (max-width: 62em) {
  .freelancerSort {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
/* 768px */
@media (max-width: 48em) {
  .freelancerSort__right {
    gap: 0.5rem;
    flex-wrap: wrap;
  }
  .freelancerSort__right > .form-select {
    flex-basis: 49%;
  }
}
