.sidebar-main{
    background-color: var(--sidebar-bg);
    width: 18.75rem;
    padding: 0;
}

.sidebar-main .btn-close{
    color: var(--text-primary) !important;
}


.sidebar-main ul{
    margin: 0;padding: 0;
}
.sidebar-body{
    padding: 0;
    overflow-y: scroll;
    overflow-x: hidden;
}

.sidebar-main li{
    list-style: none;
    white-space: nowrap;
    cursor: pointer;
    list-style: none;
    padding:0.625rem  0.625rem;
    padding-left: 0.9375rem;
    transition: 100ms ease-in-out;
    width: 100%;
   font-size: 0.9rem;
}


.sidebar-body .accordion-button{
    background: transparent;
    color: var(--text-primary);
    border-bottom: 1px solid rgba(194, 194, 194, 0.226);


}

.sidebar-body .accordion-button::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.categorydetails{
    height: fit-content;
}
.AccordionHeader .accordion-header button{
    padding-left: 0;
}

.sidebar-body .accordion-button:focus{
    box-shadow: none;
    outline: none;
    border-bottom: 1px solid rgba(194, 194, 194, 0.226);

}

.sidebar-body .accordion-collapse{
    background: var(--sidebar-bg);
}

.sidebar-body .accordion-item {
    border: none;
}

.sidebar-body .accordion-body{
    padding-left:1.5rem;
}
.sidebar-body .accordion-button:not(.collapsed){
    color: var(--text-success) ;
}
/* .sidebar-body .accordion-button:not(.collapsed)::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23F9A31A'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
} */

.sidebar-body li:hover > a{
    color: var(--text-success) ;
    text-decoration: underline;
}

.sidebar-body li{
   transition: transform 200ms ease-in-out;
   box-sizing: border-box;
}
.sidebar-body li:hover{
    transform: translateX(5px);
}