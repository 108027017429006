.restaurant__details-banner {
  max-height: 25rem;

  overflow: hidden;
  width: 100%;
}

.restaurant__details-banner img {
  width: 100%;
  height: 100%;
}

.food__nav__sub-item {
  color: var(--text-secondary);
  margin: 0.5rem 1rem;
  padding: 0.2rem 0.7rem;
  display: inline-block;
  font-weight: 500;
  border-radius: 0.4rem;
  cursor: pointer;
}

.food__nav__sub-item.active {
  background-color: var(--bg-success);
}
