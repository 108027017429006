.addPlaylist .inner {
  min-height: 8rem;
}
.playlistdiv,
.addPlaylist {
  cursor: pointer;
}
.playlistdiv .inner {
  min-height: 8rem;
}
/* .albumImg{
        max-height: ;
    } */
.albumImg img {
  height: inherit;
}
.lineOverImg {
  width: 100%;
  text-align: center;
}
