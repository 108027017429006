.deliveryVehicle {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.deliveryVehicle__item {
  flex-basis: 9rem;
  flex-grow: 1;
  position: relative;
  padding: 1rem 1.5rem;
  text-align: center;
  border-radius: 0.5rem;
  background-color: var(--white-to-grey);
}
.deliveryVehicle__input {
  visibility: hidden;
  opacity: 0;
}
.deliveryVehicle__label::after {
  content: "";
  position: absolute;
  width: 0.7rem;
  height: 0.7rem;
  background-color: transparent;
  outline-offset: 0.2rem;
  outline: 1.5px solid;
  border-radius: 50%;
  top: 0.7rem;
  right: 0.7rem;
}

.deliveryVehicle__input:checked + .deliveryVehicle__label::after {
  background-color: var(--bg-success);
  outline-color: var(--bg-success);
}
.deliveryVehicle__label {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.deliveryVehicle__label p {
  font-weight: 500;
  font-size: 1.1rem;
  white-space: nowrap;
}

/* 576P */
@media (max-width: 36em) {
  .deliveryVehicle__label p {
    font-size: 0.9rem;
  }
  .deliveryVehicle__item {
    padding: 1rem;
  }
}
