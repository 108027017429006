.express__history__card {
  padding: 1.5rem;
  background-color: var(--light-dark-to-grey);
  border-radius: 0.5rem;
  margin-block: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.express__history__card__date {
  margin-bottom: 0.3rem;
}
.express__history__card__product {
  margin-bottom: 0.3rem;
}
.express__history__card__location {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 0.3rem;
}
.express__history__card__location img {
  width: 1rem;
  margin-right: 0.5rem;
}
.express__history__card__deliveryType {
  margin: 0;
}

.express__history__card__btns {
  display: flex;
  gap: 1rem;
}

/* 1200P */
@media (max-width: 75em) {
  .express__history__card {
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
  }
}

/* 992px */
@media (max-width: 62em) {
  .express__history__card__location {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
  .express__history__card__location span:nth-child(2) {
    display: none;
  }
}

/* 350px */
@media (max-width: 21.8em) {
  .express__history__card {
    gap: 1rem;
  }
  .express__history__card__btns {
    flex-direction: column-reverse;
    gap: 0.5rem;
  }
}
