.Feature {
  width: 93vw;
  margin: 1.25rem auto;
  padding: 0.625rem;
  display: flex;
  justify-content: center;
  color: var(--text-primary);
  margin-inline: -0.1rem;
}

.InnerFeature {
  width: 80%;
  margin: auto auto;
}

.MockupDisplay {
  background-color: var(--bg-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  background-blend-mode: multiply;
  overflow: hidden;
}
.MockupDisplay img {
  transition: all 0.5s;
}

.FeatureBlock {
  min-height: 35rem;
  width: 100%;
  border-radius: 10px;
  margin: 1.25rem auto;
  /* box-shadow: 1px 1px 8px 2px inset var(--shadow-clr); */
  padding-bottom: 5%;
  background-color: var(--light-grey);
}
.resetPassword .inner {
  background-color: var(--light-grey);
}
.FeatureBlock .button,
.resetPassword .button {
  background-color: var(--bg-blue);
  border: 0;
  border-radius: 0.3rem;
  padding: 0.5rem 3rem;
  color: #fff;
}

.FeatureBlock .button:hover,
.resetPassword .button:hover {
  color: #fff;
  background-color: var(--bg-blue);
  filter: brightness(110%);
}

.FeatureBlock input:-webkit-autofill,
.FeatureBlock input:-internal-autofill-selected,
.FeatureBlock input:-webkit-autofill:focus {
  background-color: var(--light-grey) !important;
}

.FeatureBlock .InputFildDiv,
.resetPassword .InputFildDiv {
  border: 0 !important;
  box-shadow: 0 3px 10px 0 rgba(28, 28, 28, 0.15);
}
[theme="dark"] .FeatureBlock .InputFildDiv,
[theme="dark"] .resetPassword .InputFildDiv {
  background-color: #ffffff17;
}
.FeatureBlock .inputIcon,
.resetPassword .inputIcon {
  color: var(--text-info);
}

.loginBlockLogo {
  display: flex;
  justify-content: center;
}
.loginBlockLogo > img {
  height: 6rem;
  width: auto;
  margin-top: 2.5rem;
}
.howToLogin {
  padding: 1.25rem;
  margin: 1.875rem;
  border-radius: 10px;
  box-shadow: 2px 2px 2px 2px var(--shadow-clr);
}
/* want to remove this button but don't know if they are used in other section name is too generic */
.button {
  padding: 0.4375rem 2.5rem;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  margin: 0.5rem;
  border: 2px solid var(--border-secondary);
  font-weight: 700;
  color: var(--text-success);
  transition: all 0.3s;
  text-align: center;
}

.button:hover {
  background-color: var(--bg-info);
  color: var(--text-primary);
  /* transform: scale(1.1); */
}

.OtherLoginLinkParent {
  display: flex;
  justify-content: space-around;
  width: 65%;
  margin: auto;
  margin-top: 10px;
}
.OtherLoginLink {
  border: 1px solid var(--border-secondary);
  color: var(--text-info);
  display: inline-block;
  height: 1.875rem;
  width: 1.875rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 50%;
  padding: 1rem;
}

.OtherLoginLinkParent a {
  color: var(--text-success);
}
.EmailInput {
  background-color: transparent;
  box-shadow: 1px 2px 2px 1px inset var(--shadow-clr);
  border: none;

  text-underline-offset: 0;
  font-size: 1.25rem;
}
.EmailInputParent {
  border-radius: 15px;
}
.InputFildDiv {
  width: 75%;
  margin: 0.625rem auto;
  border-radius: 3px;
  box-shadow: 1px 1px 2px 2px var(--shadow-clr);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  overflow: inherit;
}

html[theme="light"] .InputFildDiv {
  border: 1px solid #a1a1a1;
}

.InputFildDiv input {
  width: 90%;
  background-color: transparent;
  outline: none;
  font-size: var(--font-md);
  color: var(--text-primary);
  border: none;
}
.errorMessaage {
  margin: 0px auto;
  color: var(--text-success);
  /* text-align: center; */
  font-size: var(--font-md);
  width: 75%;
}
.errorMessaage small {
  margin: 0px;
}
.HalfInputParent {
  width: 75%;
}
.SingUpDropDown button {
  display: inline-block;
  background-color: transparent;
}
.SingUpDropDown button:hover {
  background-color: var(--bg-info);
  color: var(--text-primary);
}

::placeholder {
  color: var(--placeholder-color);
}
.inputIcon {
  font-size: 1.25rem;
  margin: auto 5px;
  padding: auto;
  color: var(--text-success);
}
.ShowPassword {
  transition: all 0.5s;
  color: var(--text-info);
}
.ShowPassword:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.ForgatePassword {
  font-weight: 500;
  color: var(--text-primary);
  cursor: pointer;
}
.DontHaveAcc {
  font-weight: 500;
  text-align: center;
}
.DropDown {
  background-color: transparent;
  width: 100%;
}
.DontHaveAcc a {
  color: var(--bg-secondary-to-success);
}

/* verify page css */
.verifyDivParent {
  width: 75%;
  margin: auto;
  text-align: center;
  height: max-content;
}
.verifyDivParent h2 {
  margin: 0.625rem auto;
}

.otpBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.disabled {
  pointer-events: none;
  opacity: 0.3;
}
@media only screen and (min-width: 500px) {
  .pading0Md {
    padding: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .InnerFeature {
    width: 100%;
    margin: auto auto;
  }
  .Feature {
    background-color: transparent;
    width: calc(100vw - 0.625rem);
  }
  .InputFildDiv {
    width: 90%;
  }
  .errorMessaage {
    width: 90%;
  }
  .HalfInputParent {
    width: 90%;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1023px) {
  .InnerFeature {
    width: 90%;
    margin: auto auto;
  }
  .Feature {
    background-color: transparent;
    width: calc(100vw - 0.625rem);
  }
}
