

.autoPlaySwitchRow{
    padding: 0.625rem;
    margin: 0.625rem 0;
    margin-top: 1.25rem;
    border-radius: 4px;
    background-color: var(--bg-secondary);

}

.autoPlaySwitchRow input{
    cursor: pointer;
}

.videoPostBellBtn{
    border: none;
    background: transparent;
    color: var(--text-primary);
    font-size: 1.25rem;
    transition: 0.3s;
}


.videoPostBellBtn:hover{
    color: var(--text-success);
}
