
  @media only screen and (max-width: 500px) {
    /* mobile  */
    
    .Notificaiton {
      padding-left: unset;
      padding-right: unset;
    }
    /* .Notificaiton .col-sm-6 {
      padding-left: 0;
      padding-right: 0;
    } */
  }