.myStore-navigation-banner-box {
  height: 8rem;
  width: 100%;
  position: relative;
}

.myStore-navigation-banner-box > img {
  height: 8rem;
  width: 100%;
}

.myStore-navigation-banner-box .profile-img-box {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (min-width: 992px) {
  .myStore-navigation {
    position: sticky;
    top: 6rem;
  }
}

.myStore-sidebar .sidebar-body {
  overflow: hidden;
}
