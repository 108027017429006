.pendingBalance__card {
  display: grid;
  grid-template-columns: min-content 1fr max-content;
  gap: 1rem;

  border: 1px solid #adbacb;
  border-radius: 0.3rem;
  padding: 0.7rem;
  font-size: 80%;
}
.pendingBalance__card:not(:last-child) {
  margin-bottom: 1rem;
}
.pendingBalance__card__img {
  --size: 4rem;
  width: var(--size);
  height: var(--size);
  object-fit: cover;
  border-radius: 50%;
}

.pendingBalance__btn__approve {
  padding: 0.3rem 0.4rem;
  display: inline-block;
  background-color: var(--bg-success);
  line-height: 1;
  border-radius: 0.2rem;
}
.pendingBalance__btn__denied {
  padding: 0.3rem 0.4rem;
  display: inline-block;
  border: 1px solid #adbacb;
  line-height: 1;
  border-radius: 0.2rem;
}

/* 400px */
@media (max-width: 25em) {
  .pendingBalance__card {
    grid-template-columns: min-content 1fr;
  }
  .pendingBalance__card__img {
    display: none;
  }
}
