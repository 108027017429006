html {
  scroll-padding-top: 4rem;
}
body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
  max-width: 100vw;
  min-height: 100vh;
  font-family: "Poppins", sans-serif !important;
  align-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background-color: var(--bg-primary);
  background-position: center;
  background-size: cover;
  color: var(--text-primary);
}

.skip-link {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--header-footer);
  color: var(--clr-white);
  z-index: 99999;
  text-align: center;
  padding: 0.3rem 0;
  transform: translateY(-100%);
  transition: transform 200ms;
}
.skip-link:focus {
  transform: translateY(0);
}

textarea {
  height: auto;
  width: 100%;
  background: transparent;
  padding: 2% 3%;
  border: none;
  overflow: auto;
  outline: none;
  resize: none;
}
[theme="dark"] textarea {
  color: var(--placeholder-color);
  border-color: #636363bd;
}

.profileVideoSearchInput,
.profileVideoSearchLabel {
  background: var(--bg-primary);
  border: none;
  font-size: var(--font-sm);
  color: var(--text-primary);
  height: 2.375rem;
}
.profileVideoSearchLabel:first-child {
  color: var(--text-success);
}

.profileVideoSearchLabel:last-child {
  background-color: var(--bg-secondary);
  cursor: pointer;
  transition: 0.3s;
}

.profileVideoSearchLabel:last-child:hover {
  color: var(--text-success);
}
.profileVideoSearchInputGroup {
  border: 1px solid var(--bg-info);
  border-radius: 4px;
}
.global__search__seeAll-btn {
  background: var(--bg-info);
  padding: inherit 1rem;
  transition: 0.3s;
}
.person__card__name p {
  transition: 0.3s;
}

.person__card__name p:hover {
  color: var(--text-success);
}
.person__card__name:hover {
  color: var(--text-primary);
}

.search_suggestion_dropdown {
  position: absolute;
  width: 100%;
  background: var(--bg-secondary);
  left: 0;
  right: 0;
  transition: 200ms;
  border-bottom: 1px solid gray;
}

.suggestion_item p {
  margin-bottom: 0;
}
.suggestion_item {
  cursor: pointer;
}

.suggestion_item:hover {
  background-color: #384555;
}
html[theme="light"] .suggestion_item:hover {
  background-color: #d4d4d4;
}
html[theme="light"] .search_history_remove-btn:hover {
  background-color: #a8a8a8;
}

.search_history_remove-btn:hover {
  background: #2d3846;
}

.search_history_remove-btn {
  border-radius: 100%;
  height: 1.5rem;
  width: 1.5rem;
  display: grid;
  place-items: center;
}

.category-top-slider .swiper-slide {
  width: fit-content;
}

/* -----tooltip--- */
.tooltip > div.tooltip-inner {
  background-color: var(--bg-info);
  color: white;
  font-weight: bold;
  border: 1px solid var(--bg-primary);
  /* // */
  /* height: 55px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooltip.show {
  opacity: 1 !important;
}
.tooltip > div.arrow::before {
  color: black !important;
}
.RoundIconTooltip.tooltip > div.tooltip-inner {
  width: 180px;
  height: 55px;
  z-index: 1000;
  border-radius: 8px;
  background-color: var(--bg-info) !important;
}
.RoundIconTooltip.tooltip {
  margin-left: -3%;
  z-index: -1;
}
/* width */
::-webkit-scrollbar {
  width: 12px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 10px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #002c58 !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bg-header-footer {
  background-color: var(--bg-secondary);
  border-radius: 5px;
}
.eco-container {
  max-width: calc(100%);
}
.bubble-loader-center {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.267);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11220;
}

[theme="dark"] .form-control:disabled,
[theme="dark"] .form-control[readonly] {
  background-color: #686868;
}
.logo-slogan {
  font-size: 0.8rem;
  margin: 0;
  color: var(--dark-to-white);
  width: 15ch;
}

.ToggleDesignClear:hover {
  color: var(--text-primary);
}
.ToggleDesignClear {
  background: unset !important;
  outline: none !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  color: var(--text-primary);
  font-weight: 500;
}
.ToggleDesignClear:focus-visible {
  outline: -webkit-focus-ring-color auto 1px !important;
}
.ToggleDesignClear.dropdown-toggle::after {
  display: none !important;
  color: transparent !important;
}
.ToggleDesignClear.dropdown-menu-dark {
  background: transparent !important;
  transform: unset !important;
}
.ToggleDesignClear.MoreOption {
  transform: none !important;
}
.ToggleDesignClear.btn-primary:focus {
  box-shadow: unset !important;
}
.listDropdown .dropdown-item:focus,
.dropdown-item:hover {
  color: var(--text-success) !important;
  background-color: var(--bg-secondary);
}
.navigationOffCanvas {
  width: 19rem;
  background-color: var(--bg-primary);
}

.div_center {
  display: flex;
  justify-content: center;
  place-items: center;
}
.stickyParts {
  position: sticky;
  top: 0px;
}
.logo-circle {
  border: 2.5px solid var(--bg-blue);
  border-radius: 50%;
}
.lazy-load-image-loaded {
  padding: 1px;
}
.btn-0 {
  border: none;
  background-color: transparent;
}

@media (min-width: 1440px) and (max-width: 3000px) {
  .eco-container {
    width: 1440px;
    margin: 0 auto;
  }
}
@media (min-width: 3001px) and (max-width: 6000px) {
  .eco-container {
    max-width: 75vw;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 500px) {
  body {
    font-size: 0.8rem;
  }
}
