.file-input {
  width: 6.25rem !important;
  height: 6.25rem !important;
  opacity: 0;
}
.file-input:hover {
  width: 6.25rem !important;
  height: 6.25rem !important;
  opacity: 0;
}

.uploaded-img {
  height: 6.25rem !important;
}

.file-uploader-mask {
  position: absolute;
  border-radius: 5px;
  width: 6.25rem !important;
  height: 6.25rem !important;
  border: 2px dashed #ccc;
}

.file-uploader-icon {
  width: 2.1875rem !important;
  height: 2.1875rem !important;
}

.dropzone-uploader-mask {
  width: 95%;
  position: absolute;
  border-radius: 5px;
  height: 12.5rem !important;
  border: 2px dashed #ccc;
}

.dropzone-input {
  width: 100% !important;
  height: 12.5rem !important;
  opacity: 0;
}
@media (max-width: 20em) {
  .show-320{
    display: none;
  }
}
@media (max-width: 21.5em) {
  .show-340{
    display: none;
  }
}
@media (max-width: 20em) {
  .postIcons{
    justify-content: center;
  }
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5715rem + .875rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5715rem + .875rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
}
.custom-file.type-button .custom-file-label {
  background-color: #373b3e;
  color: white;
  text-align: center;
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5715rem + .875rem + 2px);
  padding: .4375rem .875rem;
  overflow: hidden;
  font-weight: 500;
  line-height: 1.5715;
  color: #000;
  background-color: #fafafa;
  border-radius: .25rem;
}

.post-submit-btn{
  background: var(--text-success);
  padding-top: 8px;
  padding-bottom: 8px;
  transition: 0.3s;
}

.post-submit-btn:hover{
  opacity: 0.92;
}

html[theme=dark] #PostModalPopup .modal-header{
  border-bottom:1px solid #63636375 !important;
} 
html[theme=dark] #PostModalPopup .modal-footer{
  border-top:1px solid #63636375 !important;
} 

.postModal-close-btn{
  background-color: #35445575 !important;
  border-radius: 100%;
  height: 35px;
  width: 35px;
}

.Post-cancel-btn {
  position: absolute;
  bottom: 1rem;
  width:100px;
  background-color: var(--text-success);
  padding: 7px ;
  border: none;
  border-radius: 3px;
  font-weight: 500;
  transition:100ms ;
}

.Post-cancel-btn:hover{
 opacity: 0.90;
}

.emoji__picker-btn{

 position: absolute;
 bottom: 10px;
 right: 7px;
 cursor: pointer;
}

.emoji__picker_wrapper aside.EmojiPickerReact.epr-main{
  position: absolute ;
  z-index: 221;
  right: 0;

}