.font-12 {
  font-size: var(--font-xs);
}
.font-14 {
  font-size: var(--font-sm);
}
.font-10 {
  font-size: 0.625rem;
}
.smallTextSize {
  font-size: 10px;
}
.textSmall {
  font-size: 00.6rem;
}
.mediumTextSize {
  font-size: 13px !important;
}
.text-align-right {
  text-align: right;
}

.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.text-clr {
  color: var(--text-clr);
}
.view-yellow {
  color: var(--view-yellow);
}
.text-gray {
  color: var(--text-info);
}
.text-black{
  color: var(--clr-dark)
}
.text-gray {
  color: gray;
}
.text-orange {
  color: var(--text-success);
}
.hover-orange:hover {
  color: var(--text-success);
}
.opacity-85 {
  opacity: 0.85;
}
.z-index-1 {
  z-index: 10;
}
.z-index-2 {
  z-index: 20;
}
.z-index-3 {
  z-index: 30;
}
.z-index-4 {
  z-index: 40;
}
.fill-orange {
  fill: var(--text-success);
}
.hover-orange {
  transition: color 200ms ease-in-out;
}
.hover-orange-bg:hover {
  background-color: var(--text-success);
}
.hover-transparent-bg:hover {
  background-color: transparent;
}
.secondary-bg {
  background-color: var(--bg-secondary);
}
.orange-bg {
  background-color: var(--text-success);
}
.bg-dark {
  background: var(--bg-primary);
}
.bg-gray {
  background-color: var(--bg-secondary);
  border-radius: 5px;
}
.bg-blue{
  background-color: var(--bg-blue);
}
.nav-bg {
  background-color: var(--nav-bg) !important;
}
.nav-bg-opacity {
  background-color: var(--nav-bg-opacity) !important;
}

.product-bg {
  background-color: var(--product-bg);
}

.bg-footer {
  background-color: var(--bg-secondary);
}
.hover-orange-bd:hover,
.active-orange-bd:focus {
  border-color: var(--text-success);
  box-shadow: var(--hover-box-shadow);
}
.trn-bg-modal{
  background-color: var(--nav-transparent-bg) !important;
}
.object-cover{
  object-fit: cover;
}
.object-right{
  object-position: right;
}
[theme="dark"] .dark-invert {
  filter: brightness(0) invert(1);
}
.border.border-dashed {
  border-style: dashed !important;
}
.focus-none:focus {
  box-shadow: none;
}
.min-height-100 {
  min-height: 100vh;
}
.display-100 {
  height: 100vh;
}
.cursor-unset {
  cursor: unset;
}
.align-self-end {
  align-self: self-end;
}

.box-shadow {
  box-shadow: var(--box-shadow);
}
.shadow-1 {
  box-shadow: 1px 1px 4px 1px var(--view-shadow);
}
.shadow-0 {
  box-shadow: none !important;
}
.body-dark-bg {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}
.overme {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mw-\[40rem\]{
  max-width: 40rem;
}
.primary-text {
  color: var(--text-primary);
  fill: var(--text-primary);
}
.secondary-text {
  color: var(--text-secondary);
  fill: var(--text-secondary);
}
.navbarActiveItem {
  color: var(--text-success);
  fill: var(--text-success);
}
.navbarActiveItem .icon-clr {
  color: var(--text-success);
  fill: var(--text-success);
}
.info-text {
  color: var(--text-info);
  fill: var(--text-info);
}

.icon-clr {
  color: var(--icon-clr);
  fill: var(--icon-clr);
}
.primary-border {
  border: 1px solid var(--border-primary);
}
.border-gray {
  border: 1px solid var(--border-primary);
}
.border-orange {
  border: 1px solid var(--border-success);
  border-radius: 0.3rem;
}
.view-shadow-border {
  border-color: 1px solid var(--view-shadow);
}
.positionTop {
  position: absolute;
  top: 0;
  right: 0;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.eco-btn {
  border: none;
  background: transparent;
  color: var(--text-primary);
}
.scroll-y {
  overflow-y: auto;
}
.scroll-x{
  overflow-y: auto;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar  {
  scrollbar-width: none;
}
#bs-tooltip .tooltip-arrow::before {
  border-bottom-color: var(--bg-success);
}
#bs-tooltip.tooltip > div.tooltip-inner {
  background-color: var(--bg-success);
  color: var(--clr-dark);
  border: none;
  font-weight: 500;
}
@media only screen and (max-width: 500px) {
  .mediumTextSize {
    font-size: 10px !important;
  }
  .mediumTextSize-xs {
    font-size: 14px !important;
  }
}


@media (min-width: 130em)  {
  .lg-vh-100{
    height: 100vh;
  }  
  .lg-vh-90{
    height: 90vh;
  }  
  .lg-vh-80{
    height: 80vh;
  }  
}
/* css don't support height and width at a time without specifying the min-max value on both */
@media (min-height: 67.5rem)  {
  .lg-vh-100{
    height: 100vh;
  }
  .lg-vh-90{
    height: 90vh;
  }
  .lg-vh-80{
    height: 80vh;
  }
}

