.price-filter-input-box {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.price-filter-input-box button {
  background: var(--bg-info);
  color: var(--text-primary);
  height: 2rem;
  width: 2.5rem;
  display: inline-block;
  border-radius: 0.2rem;
}

.price-filter-input-box .price-filter-input {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  height: 2rem;
  border-color: var(--border-primary);
}

.price-filter-input-box .price-filter-input:focus {
  box-shadow: var(--hover-box-shadow);
  border-color: var(--border-success);
}
.price-filter-input-box button svg {
  transition: color 200ms ease-in-out;
}

.price-filter-input-box button:hover svg {
  fill: var(--text-success);
  color: var(--text-success);
}

.product-filter-checkbox input:checked {
  background-color: var(--bg-success);
  border-color: var(--border-success);
}

.product-filter-checkbox input:focus {
  border-color: var(--border-success);
  box-shadow: var(--hover-box-shadow);
}
.product-filter-checkbox label {
  font-size: var(--font-sm);
}

.product-filter-checkbox .form-check {
  margin-top: 0.5rem;
}
