.address__link {
  text-decoration: none;
  color: inherit;
}
.address__map {
  width: 100%;
  height: 15.625rem;
  border: none;
  margin-top: 2.5rem;
}
.address__link:hover {
  color: inherit;
  color: #000;
}
.address__link:active,
.address__link:focus,
.address__link:visited {
  color: inherit;
}

@media only screen and (max-width: 62em) {
  .address__map {
    margin-top: 1.25rem;
  }
}
