
.shipping-address .accordion-button{
    background-color: var(--bg-secondary);
    color: var(--text-success);
    border: 1px solid #c2c2c23a;
    padding: .45rem ;
    max-width: max-content;
    margin-left: auto ;
}

.shipping-address .accordion-button::after{
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); */
    background-image: none;
    width: 0rem;
}


.shipping-address .accordion-button:focus{
    box-shadow: none;
    outline: none;
    

}

.shipping-address .accordion-collapse, .shipping-address{
    background-color: var(--bg-secondary) ;
}

.shipping-address .accordion-item {
    border: none;
    background-color: var(--bg-secondary);
}

.shipping-address .accordion-body{
    border: 1px solid #c2c2c23a;
    border-radius: .25rem;
    margin-top: .67rem;
    padding: .75rem .30rem;
}

