.address-card {
  border: 1px solid var(--border-success);
  display: block;
  width: 100%;
  border-radius: 0.33rem;
}

.address-card-item {
  display: grid;
  grid-template-columns: 3fr 7fr;
  gap: 0.8rem;
  width: 100%;
}

.address-card-item p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.3rem;
}

input[name="shipping-address"] ~ label,
input[name="billing-address"] ~ label {
  display: block;
}
