.Privecy {
  padding-bottom: 1.875rem;
  color: var(--text-primary);
  text-align: justify;
}
.Privecy ol li div {
  margin: 1.25rem;
}

.Privecy p {
  text-align: justify;
}
