.footer {
  background-color: var(--nav-bg);
  padding-top: 1.8rem;
  padding-bottom: 1.25rem;
}
.footer__nav {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-direction: column;
}

.footer__nav-title {
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
}
.footer__nav-link {
  text-decoration: none;
  color: var(--dark-to-white);
  font-size: 0.875rem;
  margin-block: 0.3rem;
  display: inline-block;
  border: 0;
}
.footer__nav-link img {
  height: 3.5rem;
}
.footer__bottom {
  font-weight: 500;
  text-align: center;
  margin-top: 1.875rem;
  padding-inline: 1.25rem;
}
.footer__bottom a {
  text-decoration: none;
  color: var(--view-yellow);
}
.footer__bottom a:hover {
  color: inherit;
}

@media only screen and (max-width: 48em) {
  .footer__nav-title {
    font-size: 1rem;
  }

  .footer__bottom {
    font-size: 0.75rem;
  }
}
