.view__cover-video {
  height: 16rem;
  width: 100%;
  object-fit: cover;
}
@media (min-width: 93.75em) {
  .view__cover-video{
    height: calc((100vw - 240px) / 5 - 1px);
  }
}
.view__cover-video__overlay {
  position: relative;
}
.view__cover-video__overlay::before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: linear-gradient(
    80deg,
    rgba(255, 255, 255, .4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}
.view__cover-overlay {
  position: absolute;
  /* top: 2.5rem; */
  top:50%;
  color: var(--clr-white);
  transform: scale(1)  translateY(-50%);
}
.cover {
  /* height: 24rem; */
  position: relative;
}
.cover .job-banner-overlay {
  height: inherit;
  width: 67%;
  padding: 2%;
}


/* 768px */
@media (max-width: 48em) {
  .view__cover-video {
    height: 14rem;
  }
  
}
/* 576px */
@media (max-width: 36em) {
  .view__cover-video{
    height: 10rem;
  }
  .view__cover-overlay .display-6 ,
  .view__cover-overlay .display-5 {
    font-size: 130%;
    margin-bottom: 0 !important;
    margin-left: 1rem;
  }
  .view__cover-video {
    height: 12rem;
  }
  
}

@media (max-width: 25em) {
  .view__cover-video {
    height: 7rem;
  }
  
}