#shipping-and-billing-accordion.food-checkout-item
  > .accordion-item
  > .accordion-header
  > .accordion-button {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  padding: 0rem 0;
  max-width: 100%;
  border: none;
  border-radius: 0%;
  font-weight: 500;
}

#shipping-and-billing-accordion
  > .accordion-item
  > .accordion-header
  > .accordion-button:disabled {
  cursor: not-allowed !important;
}

#shipping-and-billing-accordion > .accordion-item > .accordion-header {
  margin: 0rem 0;
}

#shipping-and-billing-accordion.food-checkout-item > .accordion-item {
  background-color: var(--bg-secondary);
}
#shipping-and-billing-accordion.food-checkout-item
  > .accordion-item
  .accordion-collapse {
  background-color: var(--bg-secondary);
  border-radius: 0%;
}
#shipping-and-billing-accordion.food-checkout-item .checkout-collapse-btn {
  color: var(--text-secondary);
  font-weight: 500;
}

#shipping-and-billing-accordion.food-checkout-item
  .custom-styles
  .ReactInputVerificationCode__item {
  color: var(--text-secondary);
}
