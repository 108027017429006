.contactForm .form-control {
  border: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  border-bottom: 1px solid rgba(61, 61, 61);
  font-size: 0.875rem;
  background-color: transparent;
}

.contactForm .form-control:focus {
  outline: 0;
  box-shadow: none;
  background-color: transparent;
}

.contactForm .form-label {
  margin-bottom: 0;
}
.contactForm .contactTextarea {
  height: 12.5rem;
  border: 1px solid rgba(61, 61, 61);
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
  margin-top: 0.3125rem;
}
.contactForm .contactSubmit {
  border-radius: 0;
  font-size: 0.875rem;
  border-color: var(--bg-success);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  color: var(--bg-success);
  font-weight: 500;
}
.contactForm .contactSubmit:hover {
  background-color: var(--bg-success);
  color: #fff;
}
@media only screen and (max-width: 62em) {
  .contactForm .contactTextarea {
    height: 9.375rem;
  }
}
@media only screen and (max-width: 48em) {
  .contactForm .contactTextarea {
    height: 6.25rem;
  }
}
