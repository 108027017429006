.formTimeline {
  background-color: var(--nav-bg-opacity);
  padding: 1.5rem 2rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.form__timeline {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  width: 90%;
  margin-inline: auto;
  margin-bottom: 1rem;
}
.form__timeline--item {
  flex-basis: 100%;
  display: flex;
}

.form__timeline--item:last-child {
  width: 0;
  flex-basis: 0;
}
.form__timeline--item:last-child,
.form__timeline--item:last-child:before {
  width: 0;
}
.form__timeline--item::before {
  content: "";
  height: 0.5rem;
  width: 100%;
  display: inline-block;
  border-bottom: 1px solid var(--text-primary);
}

.form__timeline span {
  height: 0.6rem;
  width: 0.6rem;
  border-radius: 50%;
  background-color: var(--View-bg);
  border: 1px solid var(--text-primary);
  position: absolute;
  transform: translateY(2px);
}

.form__timeline .active span {
  background-color: var(--dark-to-white);
  border-color: var(--dark-to-white);
  outline: 1px solid var(--dark-to-white);
  outline-offset: 0.2rem;
}
.form__timeline--item.active::before {
  border-color: var(--dark-to-white);
}

.form__timeline__text {
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin-top: 1rem;
  white-space: nowrap;
}

/* 400P */
@media (max-width: 25em) {
  .formTimeline {
    padding: 1.5rem 1rem;
  }
  .form__timeline__text {
    gap: 0.5rem;
  }
}
/* 300P */
@media (max-width: 18.7em) {
  .formTimeline {
    display: none;
  }
}
