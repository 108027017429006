.rideHistoryCard {
  background-color: var(--light-dark-to-grey);
  border-radius: 4px;
  padding: 1.5rem;
  margin-block: 1.5rem;

  display: grid;
  grid-template-columns: min-content 1fr max-content;
  gap: 2rem;
  align-items: center;
}

.rideHistoryCard__img {
  border-radius: 0.5rem;
}
.rideHistoryCard__date {
  font-size: 1rem;
}
.rideHistoryCard__location {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 0.87rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.rideHistoryCard__icon {
  font-size: 0.8rem;
  margin-right: 0.5rem;
}
.rideHistoryCard__details {
  margin-bottom: 0.5rem;
}

.rideHistoryCard__cost {
  display: flex;
  gap: 2rem;
  margin: 0;
}
.rideHistoryCard__button {
  display: flex;
  gap: 1.5rem;
}

@media only screen and (max-width: 62em) {
  .rideHistoryCard__button {
    flex-direction: column;
    gap: 0.8rem;
  }
  .rideHistoryCard__location {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.2rem;
  }
}

@media only screen and (max-width: 48em) {
  .rideHistoryCard {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}
