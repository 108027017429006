.BestTimeSection {
  width: 100%;
  background-color: var(--bg-secondary);
  padding: 0.5rem;
  border-radius: 10px;
  /* box-shadow: 1px 1px 4px 1px var(--shadow-clr); */
  display: flex;
  align-items: center;
  cursor: grab;
}
.BestTimeSection .swiper-button-prev,
.BestTimeSection .swiper-button-next {
  transform: scale(0);
  transition: 0.3s;
}
.BestTimeSection:hover :is(.swiper-button-prev, .swiper-button-next) {
  transform: scale(1);
}
.BestTimePhotoParent {
  display: flex;
  display: block;
  white-space: nowrap;
  display: block;
  /* overflow-x: scroll; */
  overflow: hidden;
}
.category-top-slider .swiper-slide {
  width: fit-content;
}

.BestTitle {
  background-color: var(--bg-info);
  padding: var(--font-sm);
  border-radius: 50%;
  margin-right: 0.625rem;
  overflow: hidden;
  width: 4.375rem;
  height: 4.375rem;
}
.BestPhoto {
  --best-photo-width: 4.375rem;
  --best-photo-height: 6rem;
  width: var(--best-photo-width);
  height: var(--best-photo-height);
  border-radius: 0.5rem;
  /* overflow: hidden; */
  margin-right: 0.675rem;
  display: inline-block;
  transition: all 0.6s;
  position: relative;
}
.BestPhoto span {
  width: var(--best-photo-width);
  height: var(--best-photo-height);
  border-radius: 0.6rem;
  overflow: hidden;
}
.BestPhoto__user {
  --size: 2rem;
  box-sizing: content-box;
  position: absolute;
  height: var(--size);
  width: var(--size);
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  border: 2px solid var(--text-info);
  padding: 1px;
  border-radius: 50%;
  z-index: 5;
}
.BestPhoto > img {
  transition: 0.3s;
}
.BestPhoto:hover > img:not(.BestPhoto__user) {
  transform: scale(1.05);
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--text-success) !important;
  height: 2rem !important;
  width: 2rem;
  overflow: hidden;
  top: 45% !important;
}
