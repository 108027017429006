.leftSidebar {
    background-color: var(--bg-secondary);
    padding: 2.5rem;
    border-radius: 0.3rem;
  }
  
  .leftSidebar__avatar {
    border-radius: 50%;
  }
  
  .leftSidebar__link {
    display: block;
    margin-block: 0.5rem;
  }
  .leftSidebar__link.active {
    font-weight: 600;
  }
  .leftSidebar__link img {
    width: 1.2em;
    margin-right: 0.5em;
  }
  [theme="dark"] .leftSidebar__link img {
    filter: invert(1);
  }
  @media only screen and (max-width: 36em) {
    .leftSidebar {
      padding: 1.5rem;
    }
  }
  
  /* from 768px to all screen */
  @media (min-width: 48em) {
    .leftSidebar {
      height: 100%;
    }
  }
  