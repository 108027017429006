.corporate__banner-main {
  height: 32rem;
  width: 100%;
}

.banner__slider-item {
  height: inherit;
  z-index: -1;
  max-width: 100%;
}

.corporate__store-slider {
  z-index: 2 !important;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /* background: linear-gradient(180deg, rgba(53,68,85,0.001) 5%, rgb(53 68 85 / 31%) 9%, rgb(53 68 85 / 48%) 20%, rgb(53 68 85 / 66%) 35%, rgb(53 68 85 / 74%) 50%, rgb(29 37 47) 93%); */
  max-width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* background-color: #00000047; */
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--text-success) !important;
  height: 2rem !important;
  width: 2rem;
  overflow: hidden;
  top: 45% !important;
}

.store__slider-item {
  max-width: 15rem;
  background: transparent;
}

/* .store__slider-item{
    border: 2px solid var(--border-primary);
}

.swiper-slide-active .store__slider-item{
    border: 2px solid var(--border-success);
} */

@media (min-width: 993px) and (max-width: 1200px) {
  .corporate__banner-main {
    height: 32rem;
  }
  .store__slider-item {
    max-width: 16rem;
  }
}
@media (min-width: 577px) and (max-width: 768px) {
  .corporate__banner-main {
    height: 27rem;
  }
  .store__slider-item {
    max-width: 15rem;
  }
}
@media (min-width: 421px) and (max-width: 576px) {
  .corporate__banner-main {
    height: 22rem;
  }
  .store__slider-item {
    max-width: 13rem;
  }
}


@media (min-width: 769px) and (max-width: 992px) {
  .corporate__banner-main {
    height: 30rem;
  }
  .store__slider-item {
    max-width: 18rem;
  }
}

@media screen and (max-width: 420px) {
  .corporate__banner-main {
    height: 18rem;
  }
  .store__slider-item {
    max-width: 12rem;
  }
}