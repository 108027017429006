.seeMoreText {
  display: inline;
  width: 100%;
  -ms-word-break: break-all;
     word-break: break-all;

     /* Non standard for WebKit */
     word-break: break-word;

-webkit-hyphens: auto;
   -moz-hyphens: auto;
        hyphens: auto;
}

.read-or-hide {
  color: #c0c0c0;
  cursor: pointer;
}
.dropdown-menu-dark {
  background-color: transparent;
  border: unset;
}
.b-unset {
  border: unset !important;
}
.h-1 {
  height: 1rem;
}
/* stickyWork  -------------------*/
.divScroll {
  height: calc(100vh);
  overflow: auto;
}
/* div scroll  */
.divScroll::-webkit-scrollbar {
  width: 8px !important;
  height: 7px !important;
}

/* Track */
.divScroll::-webkit-scrollbar-track {
  background: #1b242f !important;
  border-radius: 10px !important;
}
.divScroll::-webkit-scrollbar-track:hover {
  background: #283340b0 !important;
}
/* Handle */
.divScroll::-webkit-scrollbar-thumb {
  background: #1b242f !important;
  border-radius: 10px !important;
}

/* Handle on hover */
.divScroll::-webkit-scrollbar-thumb:hover {
  background: gray !important;
}

.w-95 {
  width: 95%;
}
.text-align-webkit-right {
  text-align: -webkit-right;
}

.width-inherit {
  width: inherit;
}

.post_url_highlight {
  color: #639efd;
  word-break: break-all;
}

.post_url_highlight:hover {
  text-decoration: underline;
  color: #639efd;
}
