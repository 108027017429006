.receipt__header {
  background-color: var(--nav-bg-opacity);
  padding: 1.5rem 2rem;
  text-align: center;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
}
.receipt__qr {
  display: flex;
  align-items: flex-start;
}
.receipt__qr__img {
}
.receipt__qr__detail {
  margin-left: 1.5rem;
}
.receipt__content {
  padding: 1.5rem;
  background-color: var(--nav-bg-opacity);
  border-radius: 0.5rem;
}
.receipt__text__icon {
  width: 1.4rem;
}
.receipt__left__icons {
  display: inline-flex;
  background-color: var(--white-to-grey);
  margin-top: 1.5rem;
  padding: 0.7rem;
  border-radius: 0.5rem;
}
.receipt__left__icons > *:not(:last-child) {
  border-right: 1px solid;
  padding-right: 0.7rem;
  margin-right: 0.7rem;
}

/* 400P */

@media (max-width: 25em) {
  .receipt__qr {
    flex-direction: column;
  }
  .receipt__qr__img {
    width: 7rem;
  }
  .receipt__qr__detail {
    margin-top: 1rem;
    margin-left: 0rem;
  }
  .receipt__header {
    font-size: 1.2rem;
  }
  .receipt__left__icons {
    margin-top: .5rem;
  }
}

/* 350P */
@media (max-width: 21.8em) {
  .receipt__left__icons > *:last-child {
    display: none;
  }
  .receipt__left__icons > img {
    border: 0 !important;
    padding: 0.2rem;
    margin: 0 !important;
  }
  .receipt__content {
    padding: 1rem;
  }
}
