.applicantTitle {
  background-color: var(--bg-secondary);
  padding: 0.625rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.applicantTitle button,
.applicantTitle a {
  border: none;
  background: var(--bg-primary);
  padding: 0.3125rem 0.75rem;
  color: var(--text-primary);
  border-radius: 4px;
  font-size: var(--font-sm);
  transition: 0.3s ease;
}

.applicantTitle button:hover,
.applicantTitle a:hover {
  color: var(--text-success);
}

.applicantProfile {
  background-color: var(--bg-secondary);
}

.applicantProfileItem {
  display: flex;
  gap: 0.625rem;
  justify-content: space-between;
}

.applicantProfileItem p {
  font-size: var(--font-sm);
  text-align: left;
  margin-bottom: 0;
  font-family: "Poppins";
}
.profielItemName {
  flex: 4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileItemValue {
  flex: 8;
  justify-self: flex-start;
}

@media (min-width:1200px) {
  .profileItemValue {
    flex: 10;
    
  }
  .profielItemName {
    flex: 2;
   
  }
}

@media only screen and (max-width: 567px) {
  .profielItemName {
    flex: 5;
  }

  .profileItemValue {
    flex: 7;
    justify-self: flex-start;
  }
}

.add-education-form .accordion-button {
  display: block;
  width: 100%;
  border: none;
  background-color: var(--bg-primary);
  border-radius: 5px;
  padding: 0.625rem;
  color: var(--text-primary);
  font-size: var(--font-sm);
  text-align: center;
}

.add-education-form .accordion-button::after {
  background-image: none;
  width: 0rem;
}

.add-education-form .accordion-button:focus {
  box-shadow: none;
  outline: none;
}

.add-education-form .accordion-collapse,
.shipping-address {
  background-color: var(--bg-secondary);
}

.add-education-form .accordion-item {
  border: none;
  background-color: var(--bg-secondary);
}

.add-education-form .accordion-body {
  border: 1px solid var(--border-primary);
  border-radius: 0.25rem;
  margin-top: 0.67rem;
  padding: 0.75rem 0.3rem;
}
