.favorite-card {
  display: flex;
   background-color: var(--bg-secondary);
  gap: 0.5rem;
  margin: 0.85rem 0;
  padding: 0.675rem;
  align-items: center;
}

.favorite-card p {
  margin-bottom: 0.1rem;
}

.favorite-add-to-cart-btn {
  border: 1px solid var(--border-success);
  border-radius: 0.22rem;
  padding: 0.15rem 0.5rem;
  transition: 0.2s;
}

.favorite-add-to-cart-btn span {
  font-size: var(--font-sm);
}

.favorite-add-to-cart-btn:hover {
  box-shadow:var(--hover-box-shadow);
}

.favorite-card .cart-item-img-box {
  min-width: 7rem;
  aspect-ratio: 1/1;
}
.favorite-card .cart-item-img-box img {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 576px) {
  .favorite-card {
    grid-template-columns: 1fr 5fr;
  }
  .favorite-card .cart-item-img-box {
    min-width: 5rem;
  }
  .favorite-add-to-cart-btn {
    border: none;
  }
}
