.freelanceTopCategory {
}
.freelanceTopCategory__title {
  font-size: 1.5rem;
}
.freelanceTopCategory__list {
  list-style: none;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  margin-bottom: 1rem;
  margin-top: 1.5rem;
}
.freelanceTopCategory__item.see-all {
  background-color: #adbacb;
  color: #fff;
  text-align: center;
  justify-content: center;
  font-size: 1.3rem;
}
.freelanceTopCategory__item {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  border: 1px solid #adbacb;
  border-radius: 0.3rem;
  padding: 1.2rem;
  transition: 0.3s;
}
.freelanceTopCategory__item:hover {
  background-color: #c8d2df;
}
.freelanceTopCategory__item__img {
  max-height: 2.3rem;
}
.freelanceTopCategory__item__title {
  font-size: 1.1rem;
  font-weight: 500;
  margin: 0;
}
.freelanceTopCategory__item__sub {
  margin-bottom: 0;
  opacity: 0.9;
  font-size: 0.9rem;
}

/* 992P */
@media only screen and (max-width: 62em) {
  .freelanceTopCategory__list {
    grid-template-columns: repeat(auto-fit, minmax(13rem, 1fr));
  }
}

/* 400P */
@media only screen and (max-width: 25em) {
  .freelanceTopCategory__title {
    font-size: 1.3rem;
  }
  .freelanceTopCategory__list{
    margin-top: 1rem;
    margin-bottom: 0;
  }
}