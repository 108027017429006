.add-skills {
  position: relative;
}

.add-skills input {
  width: 6rem;

  font-size: 0.9rem;
  border: 1px solid #adbacb;
  border-radius: 0.2rem;
  padding: 0.2rem 0.4rem;
}
.add-skills button {
  border: 0;
  padding-right: 0;
  background-color: transparent;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
