.OffCnavas {
  background-color: var(--sidebar-bg);
  color: var(--text-primary);
}
.OffCnavas a {
  color: var(--text-primary);
}
.fa-lg .navIcon {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.SideNavMenu {
  background-color: transparent;
  height: 100%;
}
.offcanvasIconsClass {
  display: flex;
  place-content: center;
  margin-top: auto;
  margin-bottom: 6%;
}
.offcanvasIconsClass .nav-link {
  padding: 1% 4%;
}
.NavOffCanvasIconsize {
  font-size: 1rem;
  display: flex;
  text-align: start;
}
.NavOffCanvasIconsize .nav-link {
  /* padding: 0% 6%; */
}
@media only screen and (max-width: 500px) {
  .OffCnavas {
    width: 44%;
  }
}
