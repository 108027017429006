.food-banner-main {
  height: 25rem;
  position: relative;
  /* border: 1px solid #000; */
}

.food-banner-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(68, 68, 68, 0.397);
  color: var(--text-primary);
  z-index: 1;
}

.bd {
  border: 1px solid var(--border-primary);
}

.food-search-box {
  width: 25rem;
  border: 1px solid var(--border-success);
  background-color: var(--clr-white);
  border-radius: 0.2rem;
}

.food-search-box:focus-within {
  box-shadow: var(--hover-box-shadow);
  border-color: var(--border-success);
}

.food-search-box span {
  background-color: transparent;
  border: none;
}

.food-search-box input {
  background-color: transparent;
  border: none;
}

.food-search-box input:focus {
  box-shadow: none;
  background-color: var(--clr-white);
}

.banner-find-btn {
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
  color: var(--text-primary);
}

@media (max-width: 576px) {
  .food-search-box {
    width: 18rem;
  }
  .food-banner-main {
    max-height: 18rem;
  }
  .food__slider-item {
    height: 100%;
  }
}
