.rating-modal {
    color: var(--text-primary);
}

.corporate-rating-modal .modal-content{
    background-color: var(--bg-secondary);
}

.corporate-rating-modal .modal-content textarea{
    box-shadow: none;
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border: 1px solid var(--border-primary);
}

.rating-modal .modal-content textarea:focus{
    box-shadow: var(--hover-box-shadow);
    border-color: var(--border-success);
}

.corporate-rating-modal .star-rating-icon{
    fill: var(--text-primary);
}