.aboutBox{
    background-color: var(--bg-secondary);
    border-radius: 10px;
    box-shadow: 1px 1px 4px 1px var(--shadow-clr);
    padding: 10% 5%;
}
.myIconCircle{
    border-radius: 50%;
    width: 1.875rem;
    height: 1.875rem;
    text-align: center;
}
.aboutDropdown{
    background-color: var(--bg-secondary);
    border: 1px solid var(--border-primary);
    width: 100%;
    text-align: start;
    color: var(--text-primary)
}
.aboutDropdown.btn-primary:hover {
    color: var(--text-primary);
    background-color: var(--bg-primary);
    border-color: var(--border-primary);
}
.aboutDropdown.dropdown-toggle::after {
    float: right !important;
    margin-top: 2%;
}
.aboutDropdown.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
    color: var(--text-primary);
    background-color: var(--bg-secondary);
    border-color: var(--border-primary);
}
.aboutDropdownBody{
    width: -webkit-fill-available;
}
.aboutDropdownBody.dropdown-menu {
background-color: var(--bg-primary) !important;
}
.aboutDropdownBody.dropdown-menu .dropdown-item {
    color: var(--text-primary);
}
.aboutDropdownBody.btn-check:focus+.btn-primary, .btn-primary:focus {
    color: var(--text-primary);
    background-color: unset !important;
    border-color: unset !important;
    box-shadow: unset !important;
}