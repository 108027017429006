.viewModal .modal-content {
  background-color: #ffffffe1;
}
[theme="dark"] .viewModal .modal-content {
  background-color: #1d232be5;
}
.viewModal .save-modal{
  background-color: var(--bg-success) !important;
  border-radius: 2rem;
  padding-inline: 1rem;
}

.viewModal .modal-header {
  background-color: #fffffff5;
  border-bottom: 0;
  color: var(--text-primary)
}
[theme="dark"] .viewModal .modal-header {
  background-color: #1d232bee;
}

.viewModal .progress-count {
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--FoodLogo-icon);
}
.viewModal .step-wizard-list {
  color: var(--FoodLogo-icon);
  width: unset;
}

.viewModal .step-wizard-item + .step-wizard-item:after {
  background: var(--FoodLogo-icon);
  top: 0.75rem;
}

.viewModal .current-item .progress-count:before {
  background: white;
}
.viewModal .current-item .progress-count:after {
  background: var(--FoodLogo-icon);
  border: 0.125rem solid var(--FoodLogo-icon);
}
.viewModal .current-item .progress-count {
  color: var(--FoodLogo-icon);
}
.viewModal .progress-count:before {
  border-left: 0.19rem solid var(--FoodLogo-icon);
  border-bottom: 0.19rem solid var(--FoodLogo-icon);
  height: 0.3rem;
  width: 0.8rem;
}
.viewModal .progress-count:after {
  background-color: white;
  height: 1.2rem;
  width: 1.2rem;
}
.viewModal .current-item .progress-count:before {
  background-color: var(--FoodLogo-icon);
}
@media only screen and (max-width: 768px) {
  /* tablet  + mobile */
  .fileModalButton {
    padding: 0.2rem 0.9rem;
  }
  .fullScreen .addVideo {
    height: 100px !important;
  }
  .fullScreen .thumbnail {
    height: 100px !important;
  }
 
}
@media only screen and (max-width: 500px) {
  .viewModal .step-wizard-item {
    min-width: 0.5rem;
    padding-top: 3%;
  }
  .viewModal .progress-count {
    height: 1.5rem;
    width: 1.5rem;
  }
  .viewModal .step-wizard-item + .step-wizard-item:after {
     
    top: 1.15rem;
  }
  
}
