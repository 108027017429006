.toggle-reset {
  background-color: transparent !important;
  border: 0 !important;
  padding-inline: 0;
}

.input-reset,
.input-reset:is(:disabled, :focus) {
  background-color: transparent;
}
[theme="dark"] .input-reset::placeholder {
  color: rgb(182, 182, 182);
}
[theme="dark"] .input-reset {
  color: #fff;
}

/* Creative Specific Utils */
.mt-n2 {
  margin-top: -1.5rem;
}

.creative__pagination {
  gap: 0.7rem;
}
.creative__pagination .page-link {
  border-radius: 0.3rem;
  border-color: #adbacb;
  color: #333;
}
.creative__pagination .page-link.active,
.creative__pagination .page-link:hover,
.creative__pagination .page-link:active,
.creative__pagination .page-link:focus {
  background-color: #adbacb;
  border-color: #8b9fb8;
}

.btn__creative {
  background-color: var(--bg-success);
  border-color: var(--bg-success);
  color: var(--dark-to-white);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.btn__creative:is(:hover, :active, :focus) {
  color: var(--text-primary);
  background-color: var(--border-success);
  border-color: var(--border-success);
  filter: brightness(95%);
}

.creative__skills__badge {
  font-size: 0.9rem;
  display: inline-block;
  border: 1px solid #adbacb;
  border-radius: 0.2rem;
  padding: 0.2rem 0.4rem;
  text-transform: capitalize;
  white-space: nowrap;
}

/* Modal */
.creative__modal__header {
  background-color: var(--text-info);
  border: 0;
  color: #fff;
}
.creative__modal__body {
  background-color: var(--light-dark-to-grey);
}
.creative__modal__header .btn-close {
  filter: brightness(0) invert(1);
}
.creative__modal__success-icon {
  font-size: 5rem !important;
}
.modal-input-and-file textarea {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.modal-input-and-file > div {
  border-top: 0 !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

/* Creative Specific Footer */
[theme="dark"] .footer__nav.exception .footer__nav-link > img {
  filter: invert(1);
}
