.food-footer{
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
}

.food-footer .footer-cta{
    border-bottom: none;
}

.food__footer__copyright-text small{
color: var(--text-secondary);
font-weight: 500;

}

.food-footer .cta-text p a:hover{
    color: var(--text-success);
}

.food-footer .cta-text p a{
    font-size: var(--font-sm);
    transition: 200ms ease-in-out;
    color: var(--text-secondary);
}

.food-footer .cta-text h6{
    font-weight: bold;
}

.food-footer .dwonloadappLogo{
    background-color: transparent;
}