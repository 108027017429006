.publicSmallPic{
    margin-top: auto;
    padding-right: 0 !important;
    position: absolute;
    bottom: -0.375rem;
    right: 0;
}
.h-inherit{
    height: inherit !important;
}
.h-50{
    height: 50%;
}
.publicLife .coverSmallText{
    text-align: right;
}
/* .coverAndProfileSectionSmall:hover{
   background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, 0.5)) !important;
} */
@media only screen and (min-width: 500px) and (max-width: 768px) {
    /* tablet  */
    .timelinesImageContainer img{
        width: 50%;
    }
  }