.PrintArea {
  padding: 4rem 2rem;

}
@media print {
  body {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96  */
   print-color-adjust: exact !important; 
}

}
.InvoiceLogo{
    background-image: url("src/Projects/Corporate/assets/icons/InvoiceLogo.svg");
    background-size: cover;
    background-repeat: no-repeat;
    /* background-color: red; */
    width: 30rem;
    height: 30rem;
    z-index: -1;
    opacity: 0.1;
   
}
.invoiceHeader {
  display: flex;
  justify-content: space-between;
}
.dFlexBetween {
  display: flex;
  justify-content: space-between;
}
.PrintArea .adress {
  width: 40%;
}
.PrintArea .adress h6 {
  padding-bottom: 3%;

  border-bottom: 2px solid gray;
}
.PrintArea table tr {
  border-bottom: 1px solid gray;
}
.PrintArea thead tr {
  background-color: var(--text-success);
  border-color: gray !important;
}
.PrintArea .total {
  width: 40%;
  margin-left: auto;
}
.PrintArea .bar {
  background-color: var(--text-success);
  border-bottom: 0.5px solid gray;
}
