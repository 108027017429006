#food-main {
  background: var(--bg-primary);
  /* color: #000; */
  color: black;
}

.food-btn {
  border: none;
  background-color: var(--bg-success);
  font-size: var(--font-sm);
  font-weight: 600;
  border-radius: 0.3rem;
  padding: 0.4rem 1rem;
}

.btn-0 {
  border: none;
  background-color: transparent;
}
