.payment-card-box {
  display: grid;
  justify-content: center;
  align-content: center;
  min-height: 100vh;
  width: 100%;
}
.payment{
    background:#E5E5E5;
}
.payment-card {
  width: 27rem;
  min-width: 18rem;
  background-color: white;
  border-radius: 0.2rem;
}

.payment-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f9a31a;
}

.payment-card-body div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6984A5;
  font-size: 0.9rem;
}

.payment-card-body div:nth-child(3) {
  font-weight: bold;
  font-size: 0.95rem;
  margin-top: 1rem;
}

.payment-card-footer button{
    transition: 200ms ease-in-out;
border: none;
padding: 0.5rem 1rem;
border-radius: 0.2rem;
margin: 0 0.5rem;
font-weight: 600;
color: white;

}

.payment-card-footer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.payment-card-footer button:first-child{
    background-color: #f9a31a;
}

.payment-card-footer button:last-child{
    background-color: transparent;
    border: 1px solid #f9a31a;
    box-sizing: border-box;
    color: #f9a31a;
    padding: calc(0.5rem - 2px) 1rem;

}

.payment-card-footer button:hover{
    box-shadow: var(--hover-box-shadow);
}

.payment-card-icon .close-icon{
    font-size: 8rem;
}

.payment.failed .payment-card-header{
    color: #FF4747;
}

.payment.failed .payment-card-body div:last-child{
    background:#E5E5E5;
    padding: 1rem;
    text-align: center;
    font-size: 0.95rem;
}
.payment.failed .payment-card-footer{
    display: block;
}

.payment.failed .payment-card-footer button{
    display: block;
    width: 100%;
    margin: 0.5rem 0;
}

.payment.canceled .payment-card-body div:first-child{
    display: block;
}
.payment.canceled .payment-card-footer button{
    display: block;
    width: 100%;
    background-color: #f9a31a;
    color: white;
}

@media (max-width:576px){
    .payment-card {
        width: 22rem;
      }
}

@media (max-width:380px){
    .payment-card {
        width: 20rem;
      }
}

