ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
  background-color: var(--light-dark-to-grey);
  box-shadow: 1px 1px 8px 2px var(--shadow-clr);
  position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
  /* .zzzz */
}

.copyright-area {
  background-color: var(--nav-bg);
  padding: 1.5625rem 0;
}
.footer-cta {
  border-bottom: 1px solid var(--border-primary);
}
.footer-section .footer-cta a{
  font-size: .9rem;
}

.cta-text {
  padding-left: 0.98rem;
  display: inline-block;
}

.footer-content {
  position: relative;
  z-index: 2;
}



.copyright-text small {
  font-size: var(--font-sm);
  color: var(--primary-text);
  font-weight: 500;
}


.cta-text.FooterBlock div,
.cta-text.FooterBlock span {
  color: var(--text-info);
  font-weight: 500;
  font-size: var(--font-sm);
  letter-spacing: normal;
  text-decoration: none;
  transition: all 0.6s;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  /* tablet  + mobile */
  .cta-text.FooterBlock div,
  .cta-text.FooterBlock span {
    font-size: var(--font-xs);

  }
}
.hoverable {
  transition: all 0.6s;
  cursor: pointer;
}
.dwonloadappLogo {
  background-color: transparent;
  border-radius: 10px;
  color: var(--text-info);
  border: 2px solid var(--border-secondary);
  cursor: pointer;
}
.float-right {
  float: right;
}
.width-fit-content {
  width: fit-content;
}
.hoverable {
  transition: all 0.6s;
  cursor: pointer;
}

.mobile {
  border-radius: 5px;
  background-color: transparent;
  border: 2px solid var(--border-secondary);
  color: var(--text-info);
}
.phone {
  background-color: transparent;
}

.desktop {
  background-color: var(--bg-info);
}

@media only screen and (min-width: 500px) and (max-width: 768px) {
  /* tablet  */
  .d-xm-flex {
    display: flex !important;
  }
  .place-content-center-sm {
    place-content: center;
  }
}
/* extra small  */
@media only screen and (max-width: 500px) {
  .d-xs-flex {
    display: flex !important;
  }
  .mt-xs-8{
    margin-top:8%;
  }
  .d-sm-hide {
    display: none;
  }
  .place-content-center-sm {
    place-content: center;
  }
}

@media (max-width: 21.875em) {
  .footer-section .footer-cta{
    display: block;
  }
  .footer-section .footer-cta .single-cta:first-child{
    margin-bottom: 1rem;
  }
  .footer-section .footer-cta .col-6{
    width: 100%;
  }

  
}
