.partner__document {
  padding: 3rem;
  background-color: var(--light-dark-to-grey);
  border-radius: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.partner__document .form-control {
  background-color: transparent;
}
.partner__document .form-control:focus {
  background-color: transparent;
}

.partner__document label {
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 0.9rem;
  cursor: pointer;
}
.partner__document .addIcon {
  border: 1px solid var(--text-primary);
  border-radius: 50%;
  padding: 0.1rem;
  font-size: 1rem;
  height: 0.8rem;
}

@media only screen and (max-width: 36em) {
  .partner__document {
    padding: 2rem;
    margin-bottom: 3rem;
  }

  .partner__document .input-group-text label {
    font-size: 0.6rem;
    gap: 0.3rem;
  }

  .partner__document .input-group-text {
    padding: 0 0.3rem !important;
  }
}
