.searchedJobs__title {
  font-size: 1.7rem;
  text-transform: capitalize;
  margin-top: 2rem;
}
.searchedJobs__sub {
  text-transform: capitalize;
}

.searchedJobs__filter {
  display: flex;
  gap: 1rem;
}
.searchedJobs__filter input {
  flex-grow: 3;
  flex-basis: 150rem;
}

.searchedJobs__list {
  border-radius: 0.3rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.searchedJobs__total {
  background-color: var(--text-info);
  color: #fff;
  padding: 0.3rem 0.5rem;
  border-radius: 0.2rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0;
}

/* 768px */
@media (max-width: 48em) {
  .searchedJobs__filter {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }
  .searchedJobs__filter select {
    grid-column: span 3;
  }
  .searchedJobs__filter input {
    grid-column: span 5;
  }
  .searchedJobs__title {
    font-size: 1.4rem;
  }
}
