.creative__modal__freelancer {
  display: grid;
  grid-template-columns: min-content 1fr max-content;
  align-items: center;
  gap: 1rem;
}
.creative__modal__freelancer__img {
  --size: 8rem;
  height: var(--size);
  object-fit: cover;
  width: var(--size);
  border-radius: 2rem;
}

.creative__modal__freelancer__pay {
  width: 100%;
  margin-top: 1rem;
  border: 1px solid #adbacb;
  border-radius: 0.3rem;
}
.creative__modal__freelancer__pay td {
  padding: 0.4rem 1rem;
  border-bottom: 1px solid #adbacb;
  border-right: 1px solid #adbacb;
}
.creative__modal__freelancer__pay td:nth-child(even) {
  font-weight: 500;
}
.creative__modal__freelancer__pay input {
  text-align: center;
  max-width: 5rem;
  border: 0;
  border-bottom: 1px solid;
}

/* 992px */
@media (max-width: 62em) {
  .creative__modal__freelancer {
    grid-template-columns: min-content 1fr;
  }
  .creative__modal__freelancer__pay {
    grid-column: 1 / -1;
  }
}

/* 768px */
@media (max-width: 48em) {
  .creative__modal__freelancer__img {
    --size: 6rem;
  }
}
