.creative__profile {
  text-align: center;
  background-color: var(--light-dark-to-grey);
  padding-block: 2rem;
  margin-bottom: 2rem;
  border-radius: 0.5rem;
}
.creative__profile__img {
  height: 7rem;
  border-radius: 50%;
  margin-bottom: 2rem;
}

.creative__profile__name {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.creative__profile__sub {
}

.creative__profile__links {
  list-style: none;
  text-align: left;
  margin-block: 2rem;
}

.creative__profile__link {
  padding: 0.5rem 2rem;
  display: block;
  transition: 0.3s;
  position: relative;
  isolation: isolate;
  margin-bottom: 0.3rem;
}
.creative__profile__link::before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  width: 0%;
  background-color: var(--bg-success);
  transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
}
.creative__profile__link.active::before {
  width: 100%;
}
.creative__profile__link:hover::before {
  width: 100%;
}
.creative__profile__see_more {
  display: none;
}

/* 768px */
@media (max-width: 48em) {
  .creative__profile__inner {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding-inline: 2.5rem;
  }
  .creative__profile__name {
    margin-bottom: 0;
    font-size: 1.1rem;
    text-align: left;
  }
  .creative__profile__see_more {
    display: block;
    text-align: left;
    padding-inline-start: 2rem;
  }
  .creative__profile__links {
    margin-block: 1rem;
  }
  .creative__profile {
    padding-block: 1rem;
  }
  .creative__profile__img {
    margin-bottom: 1.5rem;
  }
  .creative__profile__sub {
    text-align: left;
  }
}
/* 450px */
@media (max-width: 28.1em) {
  .creative__profile__img {
    height: 5rem;
  }
}

/* 365px */
@media (max-width: 22.8em) {
  .creative__profile__inner {
    flex-direction: column;
    gap: 1rem;
  }
  .creative__profile__see_more {
    text-align: center;
    padding-inline-start: 0;
  }
  .creative__profile__sub {
    text-align: center;
  }
  .creative__profile__img {
    margin-bottom: 0;
  }
}
