.paymentBox {
  max-width: 31rem;
  margin: 0 auto;
  border-radius: 4px;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0 0 0 / 0.1);
  background-color: var(--white-to-grey);

  transform: scale(0.9);
}
.payment__map {
  height: initial;
  position: absolute;
  inset: 0;
  height: 100% !important;
}

.paymentBox__location--container,
.paymentBox__location {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.paymentBox__location--icon {
  background-color: var(--View-bg);
  display: inline-block;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 36em) {
  .paymentBox__location--container {
    gap: 1.5rem;
  }
  .paymentBox__location {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}
