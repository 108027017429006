


.singleNotification {
  padding: 0.625rem 0.625rem;
  margin: 0.625rem 0;
  background-color: var(--bg-secondary);
  border-radius: 2px;
  transition: all 0.2s;
}



.singleNotification:hover {
  cursor: pointer;
  opacity: 0.9;
}


.notification_container{
  display: flex;
  gap: 10px;
}

.notification_image{
  width: 3rem;
  height: 3rem;
  overflow: hidden;
}

.notification_content{
  flex: 1;
}

.notification_action{
  width: 2rem;
  height: 100%;
 
}

.notification_content p:hover{
  text-decoration: underline;
  
}