.addsubpoint {
  position: relative;
}
.addsubpoint button {
  position: absolute;
  right: 1rem;
  border: 0;
  padding-right: 0;
  background-color: transparent;
  top: 50%;
  transform: translateY(-50%);
}
