.dashboard-item {
  height: 7rem;
  border-radius: 0.45rem;
  color: var(--text-primary);
  background-color: var(--bg-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.85rem;
}

.dashboard-item h3 {
  font-weight: bolder;
  font-size: 1.2rem;
}
.dashboard-item h4 {
  text-align: center;
  font-size: 1.3rem;
}

.data-table-extensions-filter > .icon {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAB80lEQVQ4T73Uv2tTURQH8O85972kxpqWNG2gOKhb21X8hXYQxCEKLp3cXNw6OIm0zTWWVjo4Cv4BIlIEF4c6FEQQi4uLZNTFYvNDiwWN9713jrxKQZOXBNLiXd89H77n3Hcv4YAXHbCH/wuOWh3kKJhSEDvjVbYtbffqKDFhrqRZ1ui+IrrB4PQfRCIieorAv1Vdoq1OcBsYY4jCV6Q0aAxuS9O8TGUQhRJeUGBZoTmQf65+lzaT0DYwvxA+1AiXBsSc+bxMjb+Lxq1mnITrpFKr3Utf7QnGMxNxdQNzvVr2niUVjFh3ioU2WMITW4uHPrbu+SdhYd6dFqK32vSy9RXaSZ6T0uiCawDmZq3srXYFx+aDs0p447N3eNPSj06Dz881a2Bvtl72nnQFh60O++LqBC5Wy/5aEpizvyaN8AcRmWwspitdwfjjWMk9VqUJn73zbSln1IxMuBcMztTK/nTPQ9kF72hBvGADoC/KOtuw/juANE5GggcMvkyq6xnjFz9ZavZMGG/Il3ScxD0C8RVAvqloRGzyAF6TaqBEFwWydoRT11rRrne5MPfzuLB/UgFPJXofz+yY1YEdcc/jpEloX49DK9qopIpYpSjuri8wLtxDAUwHnDr63dLXfYG7hzGjJjuFoT1s/2DCf9N3y51u0W9RndcVBJTNUgAAAABJRU5ErkJggg==);
}

.data-table-extensions > .data-table-extensions-filter > .filter-text {
  border: 0;
  border-bottom: 1px solid var(--border-success) !important;
  outline: none;
  padding: 0.25rem;
  margin-left: 0.25rem;
  background-color: transparent;
  color: var(--text-primary);
}

.data-table-extensions > .data-table-extensions-action > button.drop,
.data-table-extensions > .data-table-extensions-action > button:hover {
  background-color: var(--bg-success) !important;
}

.data-table-extensions > .data-table-extensions-action {
  z-index: 11;
}
.data-table-extensions > .data-table-extensions-action > .dropdown {
  background-color: var(--bg-secondary) !important;
  border-color: transparent !important;
}

.data-table-extensions > .data-table-extensions-action > .dropdown > button {
  color: var(--text-success) !important;
}

.data-table-extensions > .data-table-extensions-action > button.print {
  background-image: url("data:image/svg+xml,%3Csvg stroke='white' fill='white' stroke-width='0' viewBox='0 0 16 16' height='1.3rem' width='1.3rem' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z'%3E%3C/path%3E%3Cpath d='M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z'%3E%3C/path%3E%3C/svg%3E");
}

.data-table-extensions > .data-table-extensions-action > button.download {
  background-image: url("data:image/svg+xml,%3Csvg stroke='white' fill='white' stroke-width='0' viewBox='0 0 16 16' height='1.3rem' width='1.3rem' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z'%3E%3C/path%3E%3Cpath d='M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z'%3E%3C/path%3E%3C/svg%3E");
}

.data-table-extensions > .data-table-extensions-action > button::after {
  background-color: var(--bg-secondary);
  color: var(--text-success);
}

.data-table-extensions > .data-table-extensions-filter > .icon {
  background-image: url("data:image/svg+xml,%3Csvg stroke='%23f9a31a' fill='%23f9a31a' stroke-width='0' viewBox='0 0 1024 1024' height='1.3rem' width='1.3rem' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z'%3E%3C/path%3E%3C/svg%3E");
}
