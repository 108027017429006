.ProfileEditModal .modal-content{
	min-height: 17rem;

}
.ProfileEditModal .defaultPicSize{
	max-height: 30rem;

}
.containerCropper {
	height: 90%;
	padding: 10px;
}

 .cropper {
	height: 25rem;
	position: relative;
}
.profileSlider {
	/* height: 10%;
	display: flex;
	
	 */
	width: 60%;
	align-items: center;
	margin: auto;
}

.container-buttons {
	border: 1px solid #ececec;
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
}