.trackingPopup {
  text-align: center;
}
.trackingPopup__content .modal-content {
  padding: 2rem;
  background-color: var(--white-to-grey);
}
.trackingPopup__img {
  margin-left: auto;
  margin-right: auto;
  width: 6rem;
  margin-bottom: 1.5rem;
}

.trackingPopup__title {
  text-align: center;
  font-size: 1.25rem;
}

.trackingPopup__input {
  display: flex;
  gap: 1rem;
  width: 95%;
  margin: 2.5rem auto 2rem;
}
.trackingPopup__input > select {
  padding: 0.3rem 0.7rem;
  border-radius: 0.3rem;
  border: 0;
  background-color: #d9d9d9;
  border-right: 1rem solid transparent;
}
.trackingPopup__input > input {
  flex-grow: 1;
  padding: 0.3rem 0.7rem;
  border-radius: 0.3rem;
  border: 1px solid #d9d9d9;
  width: 100%;
}

/* 400p */
@media (max-width: 25em) {
  .trackingPopup__content .modal-content {
    padding: 1rem;
  }
  .trackingPopup__input > select {
    padding: 0.3rem 0.4rem;
    border-right: 0.5rem solid transparent;
  }
  .trackingPopup__input {
    gap: 0.5rem;
    margin: 1.8rem auto 1.5rem;
  }
}
