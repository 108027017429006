.fundTransfer__links {
  display: flex;
  gap: 0.5rem;
}

.fundTransfer__links > a {
  flex-grow: 1;
  text-align: center;
  border: 1px solid #adbacb;
  padding-block: 0.4rem;
  border-radius: 0.3rem;
  color: var(--text-info);
  transition: 0.3s;
}
.fundTransfer__links > a.active {
  background-color: var(--text-info);
  color: #fff;
}
