.convergeArea {
  list-style: none;
  margin-top: 1.5rem;
  padding: 0;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 3rem;
  row-gap: 1rem;
}
.convergeArea__city {
  border-radius: 0.2rem;
  border: 1px solid var(--border-secondary);
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: 0.3s;
}
.convergeArea__city:hover {
  background-color: var(--border-secondary);
  color: var(--clr-white);
}

@media only screen and (max-width: 36em) {
  .convergeArea {
    padding: 0;
  }
}
